import React, {useEffect, useState} from "react";
import http from "../../service/http";
import DatePicker from "react-datepicker";
import LoadingUi from "../../Component/Loading/ui";
import {useDialog, useLoading} from "../../Component";
import numeral from 'numeral'
import ImgBack from '../../assets/img/icons/back.png'


import './transactionHistory.css'
import moment from "moment/moment";
import {Col, Row} from "reactstrap";
const TransationHistory = (props) => {
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [history, sethistory] = useState([])
    const [games, setGames] = useState([])
    const [period, setPeriod] = useState('')
    const [status, setStatus] = useState('')

    const [submitHistory, setSubmitHistory] = useState([])//Lưu các màn hình trươc lần Submit trước đó

    const [loading, withLoading] = useLoading()

    const [DialogError, showDialogErrorWithMessage] = useDialog({
        title: 'ERROR',
        type: 'error'
    })

    const onchangePeriode = (e) => {
        let value = Number(e.target.value)
        if (e.target.value === '' || value > 0) {
            setPeriod(e.target.value)
        }
    }
    const submit = async () => {
        const params = {
            fromDate: moment(startDate).format('YYYY-MM-DD 00:00:00'),
            toDate: moment(endDate).format('YYYY-MM-DD 23:59:59')
        }
        if(period !== '') params.periode = period
        if(status !== '') params.status = status

        try {
            const betrs = await withLoading(() => http.get({path: 'report/bets', params}))

            setSubmitHistory([...submitHistory, betrs])

            sethistory(betrs)

        } catch (error) {
            showDialogErrorWithMessage(error.message)

        }

    }
    const fetchData = async () => {
        try {
            const data = await http.get({ path: 'games' })
            const _games = []
            data.map(d => {
                _games[d.id_game] = d
            })
            setGames(_games)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    };
    useEffect(() => {
        fetchData();
        submit()
    }, [])

    const getColorProfit = (bet) => {
        let color = '#fff'
        if(bet.status !== 'pending') {
            if(bet.status === 'menang') color = '#0df04c'
            else if(bet.status === 'kalah') color = '#d7868e'
        }
        return color
    }

    return (
        <div className="transactionHistory-wrapper">
           {loading && <LoadingUi/>}
            <div className="transactionHistory-container">


                <div style={{display: "flex", justifyContent: "start", width: "100%"}}>
                    <img style={{cursor: "pointer"}} className="bettingRe-IconBack" src={ImgBack}
                         onClick={props.history.goBack}/>
                    <p className='trans-title' style={{alignSelf: "center"}}>Transaction History</p>
                </div>

                <Row className="showRa showRa-report" style={{width: '100%', margin: '20px 0'}}>
                    <Col  sm="5"
                          xs="8"
                          style={{padding: 0}}>
                        <div className="inputTransH" style={{ display: 'flex', flexDirection: "row", alignItems: "center", gap: "12px"}}>
                            <DatePicker className={'report-input f9 report-input-mobile'} dateFormat={'dd/MM/yyyy'} selected={startDate} onChange={(date) => setStartDate(date)} />
                            <DatePicker className={'report-input f9 report-input-mobile'} dateFormat={'dd/MM/yyyy'} selected={endDate} onChange={(date) => setEndDate(date)} />
                        </div>
                    </Col>
                    <Col
                        style={{display: 'flex', padding: 0, margin: '10px 0', width: '100%'}}
                        sm="5"
                        xs="8"
                    >

                    <select className='transactionHistory-select f9 report-input-mobile report-select-mobile'
                            value={status} onChange={e => setStatus(e.target.value)}>
                        <option>Status</option>
                        <option value={'menang'}>Menang</option>
                        <option value={'kalah'}>kalah</option>
                        <option value={'pending'}>Pending</option>
                    </select>

                    <input className='transactionHistory-input f9 report-input-mobile'
                           placeholder="Periode"
                           type="number"
                           inputMode="numeric"
                           value={period}
                           onChange={(e) => onchangePeriode(e)}/>
                    </Col>

                    <Col sm="2"
                        xs={3}
                        style={{padding: 0}}>
                    <button style={{float: "right"}} className='transactionHistory-btn-search' onClick={submit}>Search</button>
                    </Col>
                </Row>
                <div className='transactionHistory-detail anDi'>

                    <select className='transactionHistory-select f9' value={status} onChange={e => setStatus(e.target.value)}>
                        <option>Status</option>
                        <option value={'menang'}>Menang</option>
                        <option value={'kalah'}>kalah</option>
                        <option value={'pending'}>Pending</option>
                    </select>

                    <input className='transactionHistory-input f9'
                           placeholder="Periode"
                           type="number"
                           inputMode="numeric"
                           value={period}
                           onChange={(e) => onchangePeriode(e)}/>

                    <div className="inputTransH" style={{ display: 'flex', flexDirection: "row", alignItems: "center", gap: "12px"}}>
                        <DatePicker className={'report-input f9'} dateFormat={'dd/MM/yyyy'} selected={startDate} onChange={(date) => setStartDate(date)} />
                        <p>To</p>
                        <DatePicker className={'report-input f9'} dateFormat={'dd/MM/yyyy'} selected={endDate} onChange={(date) => setEndDate(date)} />
                    </div>

                    <button className='transactionHistory-btn-search' onClick={submit}>Search</button>
                </div>
                <table id="table-history" style={{ width: "100%", background: "#1C4587", textAlign: 'center'}}>
                    <tr className="f9">
                        <th className="th-th">No</th>
                        <th className="th-th">Periode</th>
                        <th className="th-th">Tanggal</th>
                        <th className="th-th">Keterangan</th>
                        <th className="th-th">Status</th>
                        <th className="th-th">Debit</th>
                        <th className="th-th">Credit</th>
                        <th className="th-th">Balance</th>
                    </tr>
                    {games.length > 0 && history.map((item, index) => (
                        <tr className="f9" key={index}>
                            <td className="td-th">{index + 1}</td>
                            <td className="td-th">{item.code} - {item.periode}</td>
                            <td className="td-th">{item.waktu}</td>
                            <td className="td-th">{item.id_game > 5 ? games[item.id_game].game + ' ' + item.posisi : item.posisi} - {item.tebak}</td>
                            <td className='td-th'><span style={{color: item.status === 'pending' ? '#ffc200' : (item.status === 'menang' ? '#0df04c' : '#d7868e')}}>{item.status}</span>
                            </td>
                            <td className="td-th">{numeral(item.debet).format('0,0')}</td>
                                {item.jumlah !== 'undefined' && <td className="td-th"><span style={{color: getColorProfit(item)}}>{item.status !== 'pending' ? numeral(item.status === 'kalah' ? 0 : item.jumlah).format('0,0') : 0}</span></td>}
                                {typeof item.jumlah === 'undefined' && <td className="td-th"><span style={{color: getColorProfit(item)}}>{item.status !== 'pending' ? numeral(item.credit).format('0,0') : 0}</span></td>}
                            <td className="td-th">{numeral(item.saldo).format('0,0')}</td>

                        </tr>
                    ))}
                </table>
            </div>
            <DialogError/>
        </div>
    )
}

export default TransationHistory;