function Game() {
    return (
        <div style={{marginTop: "5%", padding: "2%"}}>
            <h3 style={{color: "black", textAlign: "center"}}> <span style={{color: "blue"}}>4D/3D/2D </span>- Colok Angka - Macau</h3>
            <h3 style={{color: "black", textAlign: "center"}}>Colok NAGA - Colok JITU - Tengah - Kombinasi</h3>
            <h3 style={{color: "black", textAlign: "center"}}>Dasar - (50 - 50) - SHIO -SIlang - Kembang</h3>

            <div style={{color: "black", marginTop: "2%"}}>
                <h6>4D, 3D, dan 2D</h6>
                <h6>Menebak 4 angka, 3 angka dan 2 angka.</h6>
            </div>

            <h6 style={{color: "black", marginTop: "2%"}}>Struktur: ABCD</h6>

            <div style={{color: "black", marginTop: "2%"}}>
                <h6>Misalnya keluar: 4321</h6>
                <h6>Berarti pemenang untul</h6>
                <h6>4D = 4321</h6>
                <h6>3D = 321</h6>
                <h6>2D = 21</h6>
            </div>

            <div style={{color: "black", marginTop: "2%"}}>
                <h6>Aturan permainan:</h6>
                <h6>1. Jika anda membeli diluar dari nomor yang dikeluarkan,
                    <br></br>
                    berarti anda kalah dan uang tidak dikembalikan same sekali.
                </h6>
                <h6>2. Jika anda membeli masing 100rb untuk angka:</h6>
                <h6>4D = 4321</h6>
                <h6>3D = 321</h6>
                <h6>2D = 21</h6>
            </div>

                <h6 style={{color: "black", marginTop: "2%"}}>
                    (Khusus untuk 4D,3D dan 2D diberikan diskon tambahan.
                        <br></br>
                    Diskon akan makin besar jika nilai akmulasi betting dalam satu periode makin besar
                    )
                </h6>


            <div style={{color: "black", marginTop: "2%"}}>
                <h6>Berarti kemenangan anda adalah:</h6>
                <h6>4D = 100rb x [Indexs kemenangan untuk 4D]</h6>
                <h6>3D = 100rb x [Indexs kemenangan untuk 3D]</h6>
                <h6>2D = 100rb x [Indexs kemenangan untuk 2D]</h6>
                <h6>(Catatan: nilai bet 100rb tidak dikembalikan ke member)</h6>
            </div>

            <div style={{color: "black", marginTop: "2%"}}>
                <h6>QUICK BET</h6>
                <h6>Untuk memudahkan pembelian dalam jumlah banyah bengan kombinasi nomor berturutan, misalnya:</h6>
                <h6>4321</h6>
                <h6>321</h6>
                <h6>21</h6>
                <h6>1</h6>
                <h6>maka dapat dilakukan dengan:</h6>
                <h6>BET: 4321 4*500x1000</h6>
                <h6>BET: 4321 3*500x1000</h6>
                <h6>BET: 4321 2*500x1000</h6>
                <h6>BET: 4321 1*500x1000</h6>
                <h6>Ini berarti anda sudah berbelanja:</h6>
                <h6>4321 untuk 400rb</h6>
                <h6>321 untuk 300rb</h6>
                <h6>21 untuk 200rb</h6>
                <h6>1 untuk 100rb</h6>
            </div>

                <h6 style={{color: "black", marginTop: "2%"}}>2D Posisi</h6>
            

            <div style={{color: "black", marginTop: "2%"}}>
                <h6>Struktur: ABCD</h6>
                <h6>Misalnya keluar nomor = 1234</h6>
                <h6>berati pemenang untuk 2D Depan adalah = 12</h6>
                <h6>berati pemenang untuk 2D Depan adalah = 23</h6>
                <h6>berati pemenang untuk 2D Depan adalah = 34</h6>
            </div>

            <h6 style={{color: "black", marginTop: "2%"}}>
                NB: Indeks menang dan diskon dapat dilihat di dagian Peraturan
            </h6>

        </div>
    )
}

export default Game