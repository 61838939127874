import React, {useState, useEffect} from 'react';
import {Col, Row, Table, Label} from "reactstrap";
import './home.css';
import http from '../../service/http';
import moment from 'moment'
import DatePicker from "react-datepicker";

const Home = ({sysConfig, location}) => {
    const [marketData, setMarketData] = useState([]);
    const [pasarans, setPasarans] = useState([]);
    const [page, setPage] = useState(0)
    const [startDate, setStartDate] = useState(new Date())
    const [game, setGame] = useState('')
    const fetchData = async (params={}) => {
        try {
            const data = await http.get({path: 'results', params});
            setPasarans(data.pasarans)
            setMarketData(data.results.map(das => das.filter(da => da.pasaran).map(d => {
                const dd = new Date(d.dateti).toDateString()
                const dds = dd.split(' ')
                d.date = dds[2] + dds[1] + dds[3]
                return d
            })))

            console.log("Call API Pasarans Success")
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {

// console.log('sysConfig', sysConfig)
        if (sysConfig.maintenance !== 'yes') fetchData();
        else setMarketData([])
    }, [sysConfig]);
    const submit = async () => {
        const params = {
            fromDate: moment(startDate).format('YYYY-MM-DD 00:00:00'),
        }
        if(game !== '') params.code = game

        fetchData(params)

    }
    return (
        <>
            <div className="wrapper-home">
                <Row style={{width: '100%', margin: '20px 0'}}>
                    <Col md={2} sm={0}></Col>
                    <Col md="4"
                         sm={5}
                         xs="8"
                         style={{padding: 0}}>
                        <Label>Game: </Label>
                        <select style={{marginLeft: 5}} className='transactionHistory-select f9' value={game}
                                onChange={e => setGame(e.target.value)}>
                            <option></option>
                            {pasarans.map(p => (<option value={p.code}>{p.pasaran}</option>))}
                        </select>

                    </Col>
                    <Col md="4"
                         sm={5}
                         xs="8"
                         style={{padding: 0}}>
                        <div className="inputTransH"
                             style={{display: 'flex', flexDirection: "row", alignItems: "center", gap: "12px"}}>
                            <Label>Date: </Label>
                            <DatePicker className={'report-input f9 report-input-mobile'} dateFormat={'dd/MM/yyyy'} selected={startDate} onChange={(date) => setStartDate(date)} />
                        </div>
                    </Col>
                    <Col sm="2"
                         xs={3}
                         style={{padding: 0}}>
                        <button style={{float: "right"}} className='transactionHistory-btn-search' onClick={submit}>Search</button>
                    </Col>
                </Row>
                {marketData.map((items) => {
                        return <div style={{width: '100%'}}>
                            {items[0] && <Label><b style={{textDecoration: 'underline'}}>{items[0].date}</b></Label>}
                            <Row className='result-item'>
                                {items.map((item, index) => (
                                        <Col md={4} sm={6} key={index} style={{marginTop: 20}}>
                                            <div className='result-img'>
                                                <img className='result-styled-img' src={item.pasaran.imageAdmin} alt=''/>
                                                <Row className='result-detail'>
                                                    <Col sm={6} style={{textAlign: 'right'}}>Game Code: {item.pasaran.code}
                                                        <table style={{float: 'right'}}>
                                                            <tr>
                                                                <th>1st Prize</th>
                                                            </tr>
                                                            <tr>
                                                                <th>2nd Prize</th>
                                                            </tr>
                                                            <tr>
                                                                <th>3rd Prize</th>
                                                            </tr>
                                                        </table>
                                                    </Col>
                                                    <Col sm={6}>Date: {item.date}
                                                        <table>
                                                            <tr>
                                                                <th>{item.keluaran}</th>
                                                            </tr>
                                                            <tr>
                                                                <th>{item.angka1}</th>
                                                            </tr>
                                                            <tr>
                                                                <th>{item.angka2}</th>
                                                            </tr>
                                                        </table>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>

                                ))}
                            </Row>

                        </div>
                    }
                )}
            </div>
        </>
    );
};

export default Home;
