const data = [ 
    {   
        num: 1,
        tebak: "AS Ganjil",
        tebakKop: "KOP Ganjil",
        amount: 5.000,
        disc: 400,
        total: 4.600
    },
    {
        num: 2,
        tebak: "AS Genanp",
        tebakKop: "KOP Gennap",
        amount: 5.000,
        disc: 400,
        total: 4.600
    },
    {
        num: 3,
        tebak: "AS Besar",
        tebakKop: "KOP Besar",
        amount: 5.000,
        disc: 400,
        total: 4.600
    },
    {
        num: 4,
        tebak: "AS Kercil",
        tebakKop: "KOP Kercil",
        amount: 5.000,
        disc: 400,
        total: 4.600
    },
    {
        num: 5,
        tebak: "AS Ganjil",
        tebakKop: "KOP Gennap",
        amount: 5.000,
        disc: 400,
        total: 4.600
    },

]
function Kombinasi() {

    const totals = {
        amount: data.reduce((acc, curr) => acc + curr.amount, 0),
        disc: data.reduce((acc, curr) => acc + curr.disc, 0),
        total: data.reduce((acc, curr) => acc + curr.total, 0)
    };
    return (
        <div >
            <table style={{color: "black"}} className="table-h5 f10">
                <tr className="limit-tr01">
                    <th style={{width: "5%"}} className="th-h5">No</th>
                    <th style={{width: "25%"}} className="th-h5">Tebak AS</th>
                    <th style={{width: "25%"}} className="th-h5">Tebak Kop</th>
                    <th style={{width: "17.5%"}} className="th-h5">Amount</th>
                    <th style={{width: "10%"}} className="th-h5">Disc(%)</th>
                    <th style={{width: "17.5%"}} className="th-h5">Total</th>
                </tr>

                {data.map((item, index) => (
                    <tr key={index}>
                        <td className="td-h5">{item.num}</td>
                        <td className="td-h5">{item.tebak}</td>
                        <td className="td-h5">{item.tebakKop}</td>
                        <td className="td-h5">{item.amount}</td>
                        <td className="td-h5">{item.disc}</td>
                        <td className="td-h5">{item.total}</td>
                    </tr>
                ))}

                <tr>
                    <td className="td-h5"></td>
                    <td className="td-h5 fontWeight1000">Total</td>
                    <td className="td-h5"></td>

                    <td className="td-h5 fontWeight1000">{totals.amount}</td>
                    <td className="td-h5 fontWeight1000">{totals.disc}</td>
                    <td className="td-h5 fontWeight1000">{totals.total}</td>
                </tr>

            </table>

            <table style={{marginTop: "5%", color: "black"}} className="table-h5 f10">
                <tr className="limit-tr01">
                    <th style={{width: "5%"}} className="th-h5">No</th>
                    <th style={{width: "25%"}} className="th-h5">Tebak AS</th>
                    <th style={{width: "25%"}} className="th-h5">Tebak Kop</th>
                    <th style={{width: "17.5%"}} className="th-h5">Amount</th>
                    <th style={{width: "10%"}} className="th-h5">Disc(%)</th>
                    <th style={{width: "17.5%"}} className="th-h5">Total</th>
                </tr>

                {data.map((item, index) => (
                    <tr key={index} className="limit-tr02">
                        <td className="td-h5">{item.num}</td>
                        <td className="td-h5">{item.tebak}</td>
                        <td className="td-h5">{item.tebakKop}</td>
                        <td className="td-h5">{item.amount}</td>
                        <td className="td-h5">{item.disc}</td>
                        <td className="td-h5">{item.total}</td>
                    </tr>
                ))}

                <tr>
                    <td className="td-h5"></td>
                    <td className="td-h5 fontWeight1000">Total</td>
                    <td className="td-h5"></td>

                    <td className="td-h5 fontWeight1000">{totals.amount}</td>
                    <td className="td-h5 fontWeight1000">{totals.disc}</td>
                    <td className="td-h5 fontWeight1000">{totals.total}</td>
                </tr>

            </table>


            <table style={{marginTop: "5%", color: "black"}} className="table-h5 f10">
                <tr className="limit-tr01">
                    <th style={{width: "5%"}} className="th-h5">No</th>
                    <th style={{width: "25%"}} className="th-h5">Tebak AS</th>
                    <th style={{width: "25%"}} className="th-h5">Tebak Kop</th>
                    <th style={{width: "17.5%"}} className="th-h5">Amount</th>
                    <th style={{width: "10%"}} className="th-h5">Disc(%)</th>
                    <th style={{width: "17.5%"}} className="th-h5">Total</th>
                </tr>

                {data.map((item, index) => (
                    <tr key={index}>
                        <td className="td-h5">{item.num}</td>
                        <td className="td-h5">{item.tebak}</td>
                        <td className="td-h5">{item.tebakKop}</td>
                        <td className="td-h5">{item.amount}</td>
                        <td className="td-h5">{item.disc}</td>
                        <td className="td-h5">{item.total}</td>
                    </tr>
                ))}

                <tr>
                    <td className="td-h5"></td>
                    <td className="td-h5 fontWeight1000">Total</td>
                    <td className="td-h5"></td>

                    <td className="td-h5 fontWeight1000">{totals.amount}</td>
                    <td className="td-h5 fontWeight1000">{totals.disc}</td>
                    <td className="td-h5 fontWeight1000">{totals.total}</td>
                </tr>

            </table>
        </div>
    )
}

export default Kombinasi;