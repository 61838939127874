import { useState } from "react";
import '../index.css'
import Five050 from './5050'
import D43D2D from './4D3D2D'
import ColokBebas from './ColokBebas'
import Macau from './Macau'
import ColokNaga from './ColokNaga'
import ColokJitu from './ColokJitu'
import Tengah from './Tengah'
import Dasar from './Dasar'
import Shio from './Shio'
import ShilangHomo from './SilangHomo'
import Kembang from './Kembang'
import Kombinasi from './Kombinasi'

const data = [
    {label: "4D3D2D"},
    {label: "Colok Bebas"},
    {label: "Macau Colok Bebas 2D"},
    {label: "Colok Naga"},
    {label: "Colok Jitu"},
    {label: "Tengah-Tepi"},
    {label: "Dasar"},
    {label: "50-50"},
    {label: "Shio"},
    {label: "Silang-Homo"},
    {label: "Kembang"},
    {label: "Kombinasi"},
]


function BetType() {

    const [active4D3D2D, setActive4D3D2D] = useState(true)
    const [activeColokBebas, setActiveColokBebas] = useState(false)
    const [activeMacau, setActiveMacau] = useState(false)
    const [activeColokNaga, setActiveColokNaga] = useState(false)
    const [activeColokJitu, setActiveColokJitu] = useState(false)
    const [activeTenga, setActiveTenga] = useState(false)
    const [activeDasar, setActiveDasar] = useState(false)
    const [active5050, setActive5050] = useState(false)
    const [activeShio, setActiveShio] = useState(false)
    const [activeSilangHomo, setActiveSilangHomo] = useState(false)
    const [activeKembang, setActiveKembang] = useState(false)
    const [activeKobinasi, setActiveKobinasi] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0)

    const handleAction = (index) => {

        setActiveIndex(index)
        console.log(index)
        const tabStateMap = {
             "4D3D2D": { active4D3D2D: true, activeColokBebas: false, activeMacau: false, activeColokNaga: false, activeColokJitu: false, activeTenga: false, activeDasar: false, active5050: false, activeShio: false, activeSilangHomo: false, activeKembang: false, activeKobinasi: false},
             "Colok Bebas": {active4D3D2D: false, activeColokBebas: true, activeMacau: false, activeColokNaga: false, activeColokJitu: false, activeTenga: false, activeDasar: false, active5050: false, activeShio: false, activeSilangHomo: false, activeKembang: false, activeKobinasi: false},
             "Macau Colok Bebas 2D": {active4D3D2D: false, activeColokBebas: false, activeMacau: true, activeColokNaga: false, activeColokJitu: false, activeTenga: false, activeDasar: false, active5050: false, activeShio: false, activeSilangHomo: false, activeKembang: false, activeKobinasi: false},
             "Colok Naga": {active4D3D2D: false, activeColokBebas: false, activeMacau: false, activeColokNaga: true, activeColokJitu: false, activeTenga: false, activeDasar: false, active5050: false, activeShio: false, activeSilangHomo: false, activeKembang: false, activeKobinasi: false},
             "Colok Jitu": {active4D3D2D: false, activeColokBebas: false, activeMacau: false, activeColokNaga: false, activeColokJitu: true, activeTenga: false, activeDasar: false, active5050: false, activeShio: false, activeSilangHomo: false, activeKembang: false, activeKobinasi: false},
             "Tengah-Tepi": {active4D3D2D: false, activeColokBebas: false, activeMacau: false, activeColokNaga: false, activeColokJitu: false, activeTenga: true, activeDasar: false, active5050: false, activeShio: false, activeSilangHomo: false, activeKembang: false, activeKobinasi: false},
             "Dasar": {active4D3D2D: false, activeColokBebas: false, activeMacau: false, activeColokNaga: false, activeColokJitu: false, activeTenga: false, activeDasar: true, active5050: false, activeShio: false, activeSilangHomo: false, activeKembang: false, activeKobinasi: false},
             "50-50": {active4D3D2D: false, activeColokBebas: false, activeMacau: false, activeColokNaga: false, activeColokJitu: false, activeTenga: false, activeDasar: false, active5050: true, activeShio: false, activeSilangHomo: false, activeKembang: false, activeKobinasi: false},
             "Shio": {active4D3D2D: false, activeColokBebas: false, activeMacau: false, activeColokNaga: false, activeColokJitu: false, activeTenga: false, activeDasar: false, active5050: false, activeShio: true, activeSilangHomo: false, activeKembang: false, activeKobinasi: false},
             "Silang-Homo": {active4D3D2D: false, activeColokBebas: false, activeMacau: false, activeColokNaga: false, activeColokJitu: false, activeTenga: false, activeDasar: false, active5050: false, activeShio: false, activeSilangHomo: true, activeKembang: false, activeKobinasi: false},
             "Kembang": {active4D3D2D: false, activeColokBebas: false, activeMacau: false, activeColokNaga: false, activeColokJitu: false, activeTenga: false, activeDasar: false, active5050: false, activeShio: false, activeSilangHomo: false, activeKembang: true, activeKobinasi: false},
             "Kombinasi": {active4D3D2D: false, activeColokBebas: false, activeMacau: false, activeColokNaga: false, activeColokJitu: false, activeTenga: false, activeDasar: false, active5050: false, activeShio: false, activeSilangHomo: false, activeKembang: false, activeKobinasi: true}
        }

        const { active4D3D2D, activeColokBebas, activeMacau, activeColokNaga, activeColokJitu, activeTenga, activeDasar, active5050, activeShio, activeSilangHomo, activeKembang, activeKobinasi } = tabStateMap[data[index]?.label || "default"]
        
        setActive4D3D2D(active4D3D2D)
        setActiveColokBebas(activeColokBebas)
        setActiveMacau(activeMacau)
        setActiveColokNaga(activeColokNaga)
        setActiveColokJitu(activeColokJitu)
        setActiveTenga(activeTenga)
        setActiveDasar(activeDasar)
        setActive5050(active5050)
        setActiveShio(activeShio)
        setActiveSilangHomo(activeSilangHomo)
        setActiveKembang(activeKembang)
        setActiveKobinasi(activeKobinasi)
    }




    return (
        <div className="bg-betType">

            <div className="div-button-Betype">

                {data.map((item, index) => (
                    <div onClick={() => handleAction(index)} key={index} >
                        <button className="list-Item-BetType" style={{ background: activeIndex === index ? 'red' : 'gray', color: activeIndex === index ? 'white' : 'black'}}>
                            {item.label}
                        </button>
    
                    </div>
                ))}
            </div>

            <div>
                {active4D3D2D && <D43D2D />}
            </div>

            <div>
                {activeColokBebas && <ColokBebas />}
            </div>

            <div>
                {activeMacau && <Macau />}
            </div>
            
            <div>
                {activeColokNaga && <ColokNaga />}
            </div>
            
            <div>
                {activeColokJitu && <ColokJitu />}
            </div>
            
            <div>
                {activeTenga && <Tengah />}
            </div>

            <div>
                {activeDasar && <Dasar/>}
            </div>

            <div>
                {active5050 && <Five050 />}
            </div>

            <div>
                {activeShio && <Shio />}
            </div>

            <div>
                {activeSilangHomo && <ShilangHomo />}
            </div>

            <div>
                {activeKembang && <Kembang />}
            </div>

            <div>
                {activeKobinasi && <Kombinasi />}
            </div>



            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "2%"}}>
                <button className="Button-BetType-Submit">
                    Submit
                </button>
            </div>

        </div>
    )
}

export default BetType;