import { Col } from 'reactstrap';

import {useEffect, useState} from "react";
import '../../games.css'
import GanjilGenap from "./components/GanjilGenap";
import http from "../../../../service/http";

const FiveFive = ({market, periode, betHistory, getReCeipt}) => {
    const [activeBetting, setActiveaBetting] = useState(0)
    const [bets, setBets] = useState([
        { label: 'GANJIL GENAP', background: "#427486", posisi: ['As', 'Kop', 'Kepala', 'Ekor'], tebak: ['Ganjil', 'Genap'], menangs: [] },
        { label: 'BESAR KECIL', background: "#16697D", posisi: ['As', 'Kop', 'Kepala', 'Ekor'], tebak: ['Besar', 'Kecil'], menangs: [] },
    ])
    const fetchData = async () => {
        try {
            const response = await http.get({
                path: 'users/menang/' + market._id,
                params: {gameId: 25}
            })
            setBets(bets.map(b => {
                b.posisi.map(p => {
                    b.tebak.map(t => {
                        const menang = response.find(x => x.ket === p + ' ' + t)
                        b.menangs.push(menang)
                    })
                })
                return b
            }))
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }
    useEffect(() => {


        fetchData()

    },[])
    return (
        <>{betHistory.length === 0 && <div className="fourd-wrapper">
                <Col xs="12" lg="12">
                    <div className="tab-overlow">
                        <div className="tab">
                            <div className="tab-header">
                                {bets.map((i, ix) => (
                                    <div style={{ background: `${i.background}` }} onClick={() => setActiveaBetting(ix)} className="tab-item tab-item-5050-BK" key={ix}>
                                        {i.label}
                                    </div>
                                ))}
                            </div>
                            {activeBetting === 0 && (<GanjilGenap dataTab={bets[0]} market={market} periode={periode} betHistory={betHistory} getReCeipt={getReCeipt}/>)}
                            {activeBetting === 1 && (<GanjilGenap dataTab={bets[1]} market={market} periode={periode} betHistory={betHistory} getReCeipt={getReCeipt} />)}
                        </div>
                    </div>
                </Col>
            </div>
        }</>
        )
}

export default FiveFive;