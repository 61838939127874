import '../../games.css'
import React, {useEffect, useState} from "react";
import http from "../../../../service/http";
import numeral from "numeral";
import {useDialog, useLoading} from "../../../../Component";
import LoadingUi from "../../../../Component/Loading/ui";
import {Col, Table, Row} from 'reactstrap';
const Shio = ({market, periode, betHistory, getReCeipt}) => {
    const init = [
        {tebakan: "Naga", kei: '', amount: '', bayar: 0, discount: 0},
        {tebakan: "Kelinci", kei: '', amount: '', bayar: 0, discount: 0},
        {tebakan: "Harimau", kei: '', amount: '', bayar: 0, discount: 0},
        {tebakan: "Kerbau", kei: '', amount: '', bayar: 0, discount: 0},
        {tebakan: "Tikus", kei: '', amount: '', bayar: 0, discount: 0},
        {tebakan: "Babi", kei: '', amount: '', bayar: 0, discount: 0},
        {tebakan: "Anjing", kei: '', amount: '', bayar: 0, discount: 0},
        {tebakan: "Ayam", kei: '', amount: '', bayar: 0, discount: 0},
        {tebakan: "Monyet", kei: '', amount: '', bayar: 0, discount: 0},
        {tebakan: "Kambing", kei: '', amount: '', bayar: 0, discount: 0},
        {tebakan: "Kuda", kei: '', amount: '', bayar: 0, discount: 0},
        {tebakan: "Ular", kei: '', amount: '', bayar: 0, discount: 0},
    ]
    const [loading, withLoading] = useLoading()
    const [DialogError, showDialogErrorWithMessage] = useDialog({
        title: 'ERROR',
        type: 'error'
    })
    const [totalBet, setTotalBet] = useState(0)
    const [autobet, setAutoBet] = useState('')
    const [bets, setBets] = useState(init)
    const calTotalBet = (data) => {
        const arr = data.filter(x => x.tebakan !== '')
        if (arr.length > 0) setTotalBet(arr.reduce((a, b) => {
            return {
                amount: Number(a.amount) + Number(b.amount)
            }
        }).amount)
    }
    const onChangeAutoBet = (e) => {
        if (e.target.value === '' || Number(e.target.value) > 0) {
            const _bets = bets.map(b => {
                b.amount = e.target.value
                b.bayar = b.amount - b.amount * b.discount / 100
                return b
            })
            setBets(_bets)
            calTotalBet(_bets)
            setAutoBet(e.target.value)
        }

    }
    const onChangeAmount = (e, index) => {
        const value = Number(e.target.value)
        if ((value >= 0) || e.target.value === '') {
            const _bets = [...bets]

            // console.log(e.target.value, numbers, numbers.includes(e.target.value))
            _bets[index].amount = e.target.value
            _bets[index].bayar = _bets[index].amount - _bets[index].amount * _bets[index].discount / 100
            calTotalBet(_bets)
            setBets(_bets)
        }
    }
    const submit = async () => {
        setBets(init)
        const payload = {
            betTypes: [],
            period: periode.length > 0 ? periode[0].no + 1 : 1,
            code: market.code,
            pid: market._id,
            gameId: 26
        }

        bets.filter(x => x.tebakan !== '' && x.amount > 0).map(b => {
            payload.betTypes.push({
                number: b.tebakan,
                amount: b.amount * 1000,
                posisi: 'Shio'
            })
        })

        try {
            if (payload.betTypes.length === 0) showDialogErrorWithMessage('No bet record !')
            else {
                const betrs = await withLoading(() => http.post({path: 'bet3', payload: JSON.stringify(payload)}))
                if (betrs.message && betrs.message !== '') {
                    showDialogErrorWithMessage(betrs.message)
                    if (betrs.toPage) {
                        const txid = betrs.toPage.split('&proses=')
                        if (txid[1]) {
                            const betH = await withLoading(() => http.get({path: 'users/bets/' + txid[1]}))
                            if (betH.length > 0) getReCeipt(betH)
                            else showDialogErrorWithMessage('No Bet accepted')
                        }
                    }
                } else {
                    const txid = betrs.toPage.split('&proses=')
                    const betH = await withLoading(() => http.get({path: 'users/bets/' + txid[1]}))
                    getReCeipt(betH)
                }
            }
        } catch (error) {
            showDialogErrorWithMessage(error.message)

        }

    }
    const fetchData = async () => {
        try {
            const response = await http.get({
                path: 'users/menang/' + market._id,
                params: {gameId: 26}
            })
            setBets(bets.map(b => {
                b.kei = response[0].nilai
                b.discount = response[0].discount
                return b
            }))
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }
    useEffect(() => {


        fetchData()

    }, [])
    return (
        <>{betHistory.length === 0 && <div>
            {loading && <LoadingUi/>}
            <div className="tab-overlow">

                <div className="tab">
                    <div style={{width: '100%', padding: '0 5px'}}>
                        <Row>
                            <Col className="tab-title font-12" xs={12}>Shio Bet Pannel IDR 1.000 = 1</Col>
                        </Row>
                        <Row>
                            <Col className="tab-title font-12" style={{textAlign: 'right'}} xs={12}>
                                Total Bet: {numeral(totalBet * 1000).format('0,0')}
                            </Col>
                        </Row>
                        <Table className="table-4d">
                            <thead>
                            <tr style={{verticalAlign: 'middle'}}>
                            <th>No.</th>
                            <th>Tebakan</th>
                            <th>PRIZE</th>
                            <th style={{minWidth: 140}} className={'table-input-Shio-th'}>
                                Taruhan
                                <input className="table-input table-input-none-Shio font-12"
                                       inputMode="numeric"
                                       value={autobet} placeholder='auto bet'
                                       onChange={onChangeAutoBet}/>
                                x1000
                            </th>
                            <th>Bayar</th>
                            </tr>
                            </thead>
                            <tbody>
                            {bets.map((i, ix) => (
                                <tr>
                                    <td className={'font-12'}>{ix + 1}</td>
                                    <td className={'font-12'}>{i.tebakan}</td>
                                    <td className={'font-12'}>{i.kei}</td>
                                    <td className={'font-12'}>
                                        <input placeholder=''
                                               className='table-input table-input-Shio'
                                               inputMode="numeric"
                                               value={i.amount}
                                               onChange={(e) => onChangeAmount(e, ix)}/>
                                        X1000
                                    </td>
                                    <td className={'font-12'}>{i.bayar}</td>
                                </tr>
                            ))}

                            <tr>
                                <td style={{textAlign: 'center'}} colSpan={5}>
                                    <div style={{margin: 'auto'}}>
                                        <button style={{marginRight: 10}} className='button-submit'
                                                onClick={submit}>Kirim
                                        </button>
                                        <button  className='button-submit' style={{background: "#D0453B"}}>Hapus
                                        </button>
                                    </div>
                                </td>

                            </tr>
                            </tbody>
                        </Table>


                    </div>
                </div>
            </div>
            <DialogError/>
        </div>}
        </>
    )
}

export default Shio