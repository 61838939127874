import { reduce } from 'ramda'

export default (schema) => values => {
  const { error } = schema.validate(values)
  if (!error) {
    return {}
  }
  const generateErr = (accumulator, { message, path: [name] }) => {
    return {
      ...accumulator,
      [name]: message
    }
  }
  return reduce(generateErr, {}, error.details)
}