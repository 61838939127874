import numeral from "numeral";
const data = [
    {
        num: 1,
        tebak: 0,
        amount: 10.000,
        disc: 1.500,
        total: 8.500
    },
    {
        num: 2,
        tebak: 1,
        amount: 10.000,
        disc: 1.500,
        total: 8.500
    },
    {
        num: 3,
        tebak: 2,
        amount: 10.000,
        disc: 1.500,
        total: 8.500
    },
    {
        num: 4,
        tebak: 3,
        amount: 10.000,
        disc: 1.500,
        total: 8.500
    },
    {
        num: 5,
        tebak: 4,
        amount: 10.000,
        disc: 1.500,
        total: 8.500
    },
    {
        num: 6,
        tebak: 5,
        amount: 10.000,
        disc: 1.500,
        total: 8.500
    },
    {
        num: 7,
        tebak: 6,
        amount: 10.000,
        disc: 1.500,
        total: 8.500
    },
    {
        num: 8,
        tebak: 7,
        amount: 10.000,
        disc: 1.500,
        total: 8.500
    },
    {
        num: 9,
        tebak: 8,
        amount: 10.000,
        disc: 1.500,
        total: 8.500
    },
    {
        num: 10,
        tebak: 9,
        amount: 10.000,
        disc: 1.500,
        total: 8.500
    }
]

function ColokBebas() {
    const totals = {
        amount: data.reduce((acc, curr) => acc + curr.amount, 0),
        disc: data.reduce((acc, curr) => acc + curr.disc, 0),
        total: data.reduce((acc, curr) => acc + curr.total, 0)
    };
    return (
        <div style={{marginTop: "5%"}}>
            <table style={{color: "black"}} className="table-h5">
                <tr className="limit-tr01">
                    <th style={{width: "5%"}} className="th-h5">No</th>
                    <th style={{width: "30%"}} className="th-h5">Tebak 1</th>
                    <th style={{width: "20%"}} className="th-h5">Amount</th>
                    <th style={{width: "20%"}} className="th-h5">Disc(%)</th>
                    <th style={{width: "20%"}} className="th-h5">Total</th>
                </tr>

                {data.map((item, index) => (
                    <tr key={index}>
                        <td className="td-h5">{item.num}</td>
                        <td className="td-h5 fontWeight1000">{item.tebak}</td>
                        <td className="td-h5">{numeral(item.amount).format('0.000')}</td>
                        <td className="td-h5">{numeral(item.disc).format('0.000')}</td>
                        <td className="td-h5"> {numeral(item.total).format('0.000')}</td>
                    </tr>              
                ))}

                <tr>
                    <td className="td-h5"></td>
                    <td className="td-h5">Total</td>
                    <td className="td-h5">{numeral(totals.amount).format('0.000')}</td>
                    <td className="td-h5">{numeral(totals.disc).format('0.000')}</td>
                    <td className="td-h5">{numeral(totals.total).format('0.000')}</td>
                </tr>

            </table>
        </div>
    )
}

export default ColokBebas;