export default {
  DASHBOARD: 'pengumuman',
  TRANSACTIONS: 'Transaksi',
  DEPOSITS: 'Setoran',
  WITHDRAW: 'Pengembalian dana',
  TRANSFERS: 'Transfer',
  BETTING_HISTORIES: 'sejarah taruhan',
  REPORTS: 'Laporan',
  BONUS: 'Bonus',
  BANKING: 'Perbankan',
  USER_MANAGEMENT: 'manajemen pengguna',
  USERS: 'Pengguna',
  CREATE_PARTNER: 'Buat Mitra',
  CREATE_PLAYER: 'Buat Player',
  CREATE_AGENT: 'Buat Agen',
  CREATE_STAFF: 'Buat Staf',
  POSITION_TAKING: 'Pengambilan Posisi',
  COMMISSIONS: 'Komisi',
  GAME_SETTING: 'Pengaturan game',
  PROFILE: 'Profil',
  LOGOUT: 'Keluar',
  SEARCH: 'Cari',
  FROM: 'Dari tanggal',
  TO: 'Ke tanggal',
  ACTION: 'Tindakan',
  DATE: 'Tanggal',
  RECEIPT: 'Resi',
  STATUS: 'Status',
  ACCOUNT_NO: 'No rekening',
  ACCOUNT_NAME: 'Nama akun',
  TO_BANK: 'Ke bank',
  FROM_BANK: 'Dari Bank',
  CURRENCY: 'Mata uang',
  AMOUNT: 'Jumlah',
  USERNAME: 'Nama pengguna',
  'NO.': 'Tidak.',
  ACCEPT_DEPOSIT: 'setoran permintaan Anda diterima',
  REJECT_DEPOSIT: 'setoran permintaan Anda ditolak',
  DEPOSIT_REQUEST: 'Permintaan Setoran',
  ID: 'ID',
  BANK_ACCOUNT_NAME: 'Nama akun bank',
  BANK_ACCOUNT_NO: 'Rekening Bank no',
  UPDATE: 'Memperbarui',
  UPDATE_BY: 'Perbarui oleh',
  APPROVE: 'Menyetujui',
  ACCEPT: 'menerima',
  REJECT: 'Menolak',
  SUCCESS: 'keberhasilan',
  WARNING: 'peringatan',
  FAIL: 'urung',
  AGENT: 'Agen',
  BETID: 'Bertaruh Id',
  BET: 'Bertaruh',
  WINLOSE: 'Win Lose',
  WINAMOUNT: 'Menangkan Jumlah',
  JACKPOT: 'hadiah main kartu',
  CREATED: 'Dibuat',
  LOADING: 'Memuat',
  TOTAL: 'Total',
  TOTAL_BET: 'Taruhan total',
  TOTAL_WINLOSE: 'Total Winlose',
  TOTAL_TURNOVER: 'Total Perputaran',
  BETTING_DETAIL: 'Betting Details',
  SELECT_AGENT: 'Pilih Agen',
  SELECT_GAME: 'Pilih Game',
  SALE: 'Penjualan',
  DOWNLINE: 'Downline',
  DOWNLINE_ACCOUNT: 'Downline Akun',
  TOTAL_DOWNLINE_WINLOSE: 'Total Menurunkan Downline Menurunkan',
  TURNOVER: 'Pergantian',
  DOWNLINE_TURNOVER: 'Perputaran Downline',
  MEMBER_TURNOVER: 'Pergantian Anggota',
  ADMIN: 'Admin',
  PARTNER: 'Pasangan',
  PLAYER: 'Player',
  COMPANY_TOTAL: 'Total Perusahaan',
  SHARE_PERCEN: 'Bagikan %',
  COMM_PERCEN: 'Komisi Berbagi %',
  TOTAL_NET: 'Total Laba Bersih',
  NO_DATA: 'Tidak ada data yang tersedia...',
  ADD: 'Menambahkan',
  BANK_NAME: 'Nama Bank',
  BANK_CONFIG: 'Konfigurasi perbankan',
  SUBMIT: 'Kirimkan',
  SELECT_ROLE: 'Pilih Peran',
  PHONE: 'Telepon',
  BALANCE: 'Keseimbangan',
  ROLE: 'posisi',
  ACTIVE: 'Aktif',
  INACTIVE: 'Non-aktif',
  ENTER_PASS: 'Masukkan kata kunci',
  UPDATE_USER: 'Perbarui Pengguna',
  SELECT_STATUS: 'Pilih status',
  UPDATE_USER_SUCCESS: 'Perbarui Pengguna Berhasil',
  YOU_REQUEST_SUBMITED_SUCCESS: 'Permintaan Anda berhasil dikirim',
  PLEASE_CONTACT_ADMIN: 'Silakan hubungi administrator',
  COMM_CANNOT_GREATE_THAN: 'Komisi tidak boleh lebih dari orang tua PT',
  UPDATE_PERCEN: 'Perbarui Persentase',
  CREATE_AGENT_SUCCESS: 'Buat Agen Sukses',
  CREATE_PARTNER_SUCCESS: 'Buat Mitra Berhasil',
  CREATE_PLAYER_SUCCESS: 'Buat Pemain Sukses',
  CREATE_STAFF_SUCCESS: 'Buat Staf Sukses',
  CREATE_AGENT_ERROR: 'Buat Kesalahan Agen',
  CREATE_PARTNER_ERROR: 'Buat Kesalahan Mitra',
  CREATE_PLAYER_ERROR: 'Buat Kesalahan Pemain',
  CREATE_STAFF_ERROR: 'Buat Kesalahan Staf',
  ENTER_USERNAME: 'Masukkan nama pengguna',
  ENTER_FULLNAME: 'Masukkan Nama Lengkap',
  ENTER_PHONE: 'Masukkan Telepon',
  CREATE_NOW: 'Buat sekarang',
  ERROR: 'Error !!!',
  USER_INFO: 'Info Pengguna',
  EMAIL: 'Email',
  OLD_PASS: 'password lama',
  IPAY_QR_CODE: 'KODE QR',
  REGISTER_PARTNER: 'MITRA REGISTER',
  REGISTER_AGENT: 'AGEN REGISTER',
  REGISTER_PLAYER: 'PEMAIN DAFTAR',
  LOGIN: 'Login',
  LOGIN_ERROR: 'Kesalahan Login',
  NEW_PASS:'Kata sandi baru',
  SETTINGS:'PENGATURAN',
  PERCEN: 'persentase',
  ACCOUNT: 'Akun',
  LAST_LOGIN: 'Login terakhir',
  TOTAL_PARTNER_ACTIVE_SUSPENDED: 'Mitra (aktif / dijeda)',
  TOTAL_AGENT_ACTIVE_SUSPENDED: 'Agen (aktif / dijeda)',
  TOTAL_PLAYER_ACTIVE_SUSPENDED: 'Player (aktif / dijeda)',
  ENTER_NEWWIN_TOKEN: 'Masukkan Token Newwin',
  ENTER_NEWWIN_URL: 'Masukkan Url Newwin',
  ANNOUNCEMENT: 'Pengumuman',
  GAME_MANAGEMENT: 'Manajemen Game',
  PROMOSI: 'Promosi',
  PENGATURAN: 'Pengaturan',
  DOWNLINE_RECEIIVABLE: 'Dapat diterima secara downline',
  EARN_COMM: 'Dapatkan komisi',
  PAY_COMM: 'Bayar komisi',
  UPLINE_PAYABLE: 'Dapat digunakan untuk upline',
  PLAYER_WINLOSE: 'Pemain menang atau kalah',
  SYNC_REAL_REPORT: 'Sinkronkan Laporan Nyata',
  FULL_REPORTS: 'Laporan menang atau kalah',
  FULL_REPORTS_PAYABLE: 'Laporan penyelesaian',
  PLAYER_COMM: 'Komisen pemain',
  PLAYER_TOTAL: 'Jumlah pemain'
}