import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Table,
    ModalBody,
    ModalFooter,
    Button,
    Modal,
    ModalHeader,
    DropdownToggle,
    DropdownMenu, DropdownItem, Dropdown
} from 'reactstrap';
import http from '../../service/http';
import './games.css';
import {useDialog, useLoading} from '../../Component'
import LoadingUi from '../../Component/Loading/ui'
import FourD from './component/FourD'
import TwoDDepan from './component/TwoDDepan'
import ColokBebas from './component/ColokBebas'
import ColokBebas2D from './component/ColokBebas2D'
import ColokNaga from './component/ColokNaga'
import ColokJitu from './component/ColokJitu'
import Tengah from './component/Tengah'
import Dasar from './component/Dasar'
import Shio from './component/Shio'
import Silang from './component/Silang'
import Kembang from './component/Kembang'
import Kombinasi from './component/Kombinasi'
import FiveFive from './component/50-50'
import TwoDTengah from './component/TwoDTengah'
import Macau from './component/Macau'

import numeral from "numeral";
import store from "store";

const Games = ({refreshUser}) => {
    const [loading, withLoading] = useLoading()
    const [selectedGameId, setSelectedGameId] = useState(1);
    const [selectedGuideId, setSelectedGuideId] = useState(-1);
    const [listGames, setListGames] = useState([]);
    const [periode, setPeriod] = useState(1);
    const market = JSON.parse(localStorage.getItem('market'))
    const [totalReceipt, setTotalReceipt] = useState({})
    const [betHistory, setBetHistory] = useState([])
    const [modal, setModal] = useState(false);
    const [selectGame, setSelectGame] = useState(0);
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [dropdownOpenChild, setDropdownOpenChild] = useState(false)
    const [user, setUser] = useState({})
    const [gamesConfig, setGameConfig] = useState({})

    const [DialogError, showDialogErrorWithMessage] = useDialog({
        title: 'ERROR',
        type: 'error'
    })
    const toggle = () => setModal(!modal);
    const toggleSelectGame = (e) => {
        // console.log(e.target.value)
        const el = document.getElementById('selectGameChild')
        if(!['1'].includes(e.target.value)) {
            el.style.display = 'none'
        } else el.style.display = 'block'
        setSelectedGameId(Number(e.target.value))
        setDropdownOpen((prevState) => !prevState);
    };
    const toggleSelectGameChild = (e) => {
        setSelectGame(Number(e.target.value))
        setDropdownOpenChild((prevState) => !prevState);
    };

    const gameChildren = {
        1: [{
            title: '4D, 3D, 2D',
        }, {
            title: '2D Quick Bet',
        }, {
            title: 'Quick Bet',
        }, {
            title: 'BB Compuran',
        }]
    }
    const closeBtn = (
        <button style={{
            border: 'none',
            background: 'inherit',
            float: 'right',
            color: '#fff',
            fontSize: 40,
        }} className="close" onClick={toggle} type="button">
            &times;
        </button>
    );
    const getGames = async () => {
        const [gamesData, periodes, me, response] = await withLoading(() => Promise.all([
            http.get({ path: 'games' }),
            http.get({ path: 'periodes/' + market._id }),
            http.get({ path: 'me' }),
            http.get({ path: 'users/menang/' + market._id }),
        ]))
        setUser(me)
        setPeriod(periodes)
        setListGames(gamesData.filter((item) => ![2,3].includes(item.id_game)))
        const gamesConfig = response.find(x => x.ket === 'games')
        setGameConfig(gamesConfig)
    }

    useEffect(() => {
        getGames()

    }, [])

    const handleGuideClick = (gameId=null) => {
        if(!gameId) gameId = selectedGameId > 0 ? selectedGameId * -1 : selectedGameId
        setSelectedGuideId(gameId)
        toggle()
    }
    const handleTabClick = (gameId) => {
        const g = listGames.find(x => x.id_game === gameId)
        if(g && !gamesConfig.games[g.game]) showDialogErrorWithMessage(g.game + ' locked, please contact upline')
        else {
            setBetHistory([])
            setSelectedGameId(gameId);
        }

    };
    const getReCeipt = (betH) => {
        setBetHistory(betH)
        refreshUser()
        setTotalReceipt(betH.reduce((a, b) => {
            return {
                disc: a.disc + b.disc,
                taruhan: a.taruhan + b.taruhan,
                bayar: a.bayar + b.bayar,
            }
        }))
    }
    const gamesName = {
        19: 'Colok Bebas',
        21: 'Colok Naga',
        23: 'Tengah',
        24: 'Dasar',
        29: 'Kombinasi',
    }
    const showGuide = (gameId) => {
        switch (gameId) {
            case -28:
                return <div>
                    <h4>KEMBANG KEMPIS</h4><br/>

                    Struktur=ABCD<br/>
                    Jika Menebang posisi Depan maka yang menjadi fokus adalah AB<br/>
                    Jika Menebang posisi Tengah maka yang menjadi fokus adalah BC<br/>
                    Jika Menebang posisi Belakang maka yang menjadi fokus adalah CD<br/>

                    {'KEMBANG jika A < B ataupun B < C ataupun C < D'}<br/>
                    KEMPIS jika A> B ataupun B > C ataupun C > D<br/>
                    KEMBAR jika A = B ataupun B = C ataupun C = D<br/>
                <br/>
                    <h5>Analisis I: Beli Posisi Depan</h5>
                    Keluar : 4321<br/>
                    Yang menjadi pedoman adalah posisi Depan, berarti 43<br/>
                    43 => 4 > 3, hasil = KEMPIS<br/>
                <br/>
                    <h5>Analisis II:</h5>
                    Keluar : 4236 Beli Posisi Tengah<br/>
                    Yang menjadi pedoman adalah posisi Tengah, berarti 23<br/>
                    {'23 => 2 < 3, hasil = KEMBANG'}<br/>
                    <br/>
                    <h5>Analisis III: Beli Posisi Belakang</h5>
                    Keluar : 4099<br/>
                    Yang menjadi pedoman adalah posisi Belakang, berarti 99<br/>
                    99 => Hasil = KEMBAR<br/>


                    Jika dilakukan pembelian dengan 100rb dan menang maka:<br/>
                    Menang = 100rb + [Indeks kemenangan untuk KEMBANG-KEMPIS]<br/>

                    NB: Indeks menang dan diskon dapat dilihat di bagian Peraturan
                </div>
            case -27:
                return <div>
                    <h4>SILANG HOMO</h4><br/>

                    Permainan ini kadang disebut suami istri.<br/>

                    Menebak dari posisi Depan,Tengah,Belakang.<br/>

                    Contoh no 1234<br/>
                    Yang dimaksud dengan Posisi Depan adalah 2 no terdepan yaitu 12<br/>
                    Yang dimaksud dengan Posisi Tengah adalah 2 no ditengah yaitu 23<br/>
                    Yang dimaksud dengan Posisi Belakang adalah 2 no terbelakang yaitu 34<br/>

                    SILANG = Terdapat Ganjil dan Genap<br/>
                    HOMO = Terdapat 1 pasang Ganjil atau 1 pasang Genap<br/>
                    <br/>
                    <h5>Analisis I: Beli Posisi Depan</h5>
                    Keluar : 4321.<br/>
                    Yang menjadi pedoman adalah posisi Depan, berarti 12<br/>
                    12 => 1=ganjil dan 2=genap , berarti hasil = SILANG<br/>
                    <br/>
                    <h5>Analisis II: Beli Posisi Tengah</h5>
                    Keluar : 4326.<br/>
                    Yang menjadi pedoman adalah posisi Tengah, berarti 32<br/>
                    32 => 3=ganjil dan 2=genap , berarti hasil = SILANG<br/>
                    <br/>
                    <h5>Analisis III: Beli Posisi Belakang</h5>
                    Keluar : 4533.<br/>
                    Yang menjadi pedoman adalah posisi Belakang, berarti 33<br/>
                    33 => 3=ganjil dan 3=ganjil , berarti hasil = HOMO<br/>


                    Jika dilakukan pembelian dengan 100rb dan menang maka:<br/>
                    Menang = 100rb + [Indeks kemenangan untuk Silang-Homo]<br/>

                    NB: Indeks menang dan diskon dapat dilihat di bagian Peraturan
                </div>
            case -26:
                return <div>
                    <h4>SHIO</h4><br/>
                    Struktur: ABCD<br/>
                    Menebak SHIO dari posisi 2D<br/>
                    SHIO merupakan 12 lambang kelahiran dalam penanggalan China.<br/>
                    Dalam permainan ini, setiap lambang diwakili dengan satu nomor.<br/><br/>
                    <h5>Analisis I:</h5>
                    Keluar : 4321<br/>
                    Permainan ini hanya memperhatikan posisi 2D, berarti yang dipedomanin = 21<br/>
                    Hasil = 21-12 = 9 (shio disesuaikan dengan tabel diatas)<br/>
                    <br/>
                    <h5>Analisis II:</h5>
                    Keluar : 6543<br/>
                    Permainan ini hanya memperhatikan posisi 2D, berarti yang dipedomanin = 43<br/>
                    Hasil = 43-36 = 7 (shio disesuaikan dengan tabel diatas)<br/>
                    <br/>
                    <h5>Analisis III:</h5>
                    Keluar : 1389<br/>
                    Permainan ini hanya memperhatikan posisi 2D, berarti yang dipedomanin = 89<br/>
                    Hasil = 89-84 = 5 (shio disesuaikan dengan tabel diatas)<br/>

                    catatan: nilai yang dikurangi merupakan kelipatan 12.<br/>

                    Jika dilakukan pembelian dengan 100rb dan menang maka:<br/>
                    Menang = 100rb + [Indeks kemenangan untuk SHIO]<br/>

                    NB: Indeks menang dan diskon dapat dilihat di bagian Peraturan
                </div>
            case -25:
                return <div>
                    <h4>50-50</h4><br/>
                    Menebak ganjil/genap, besar/kecil<br/>
                    Permainan ini sangat menarik karena pasarannya naik turun sesuai keinginan market pada waktu
                    tersebut. Dengan demikian, nilai pembelian dipengaruhi kei (pasaran)..<br/>

                    Struktur: ABCD<br/>
                    Menebak ganjil/genap dan besar/kecil dari posisi:<br/>
                    A=AS<br/>
                    B=KOP<br/>
                    C=KEPALA<br/>
                    D=EKOR<br/>
                    Besar/Kecil: 0-4=kecil, 5-9=besar<br/>
                    Ganjil/Genap : 1=ganjil, 2=genap dan seterusnya<br/>
                    <br/>
                    <h5>Analisis I:</h5>
                    Keluar : 4327<br/>
                    Berarti pemenang adalah yang memilih:<br/>
                    AS GENAP/KECIL<br/>
                    KOP GANJIL/KECIL<br/>
                    KEPALA GENAP/KECIL<br/>
                    EKOR GANJIL/BESAR<br/>
                    Misal anda membeli dengan dana Rp.100rb untuk AS Genap, menang = 100rb + [indeks pasaran AS Genap
                    50-50]<br/>
                    Atau:<br/>
                    Jika membeli dengan dana Rp.100rb untuk Ekor Ganjil, menang = 100rb + [indeks pasaran Ekor Ganjil
                    50-50]<br/>
                    Atau:<br/>
                    Jika membeli dengan dana Rp.100rb untuk AS Ganjil, kalah = 100rb + [indeks pasaran AS Ganjil
                    50-50]<br/>

                    NB: Indeks menang dan diskon dapat dilihat di bagian Peraturan
                </div>
            case -24:
                return <div>
                    <h4>Dasar</h4><br/>
                    Menebak ganjil/genap dan besar/kecil dari penjumlah angka-angka 2D.<br/>
                    Nilai pembelian ditentukan pasaran (kei) pada saat itu.<br/>

                    Struktur: CD (2 angka terakhir)<br/>
                    Kecil = angka 0-4<br/>
                    Besar = angka 5-9<br/>
                    Ganjil = 1,3,5,7,9<br/>
                    Genap=0,2,4,6,8<br/>

                    <br/>
                    <h5>Analisis I:</h5>
                    Keluar : 1234,<br/>
                    3+4 = 7<br/>
                    Berarti keluar : Ganjil dan Besar<br/>
                    <br/>
                    <h5>Analisis II:</h5>
                    Keluar : 5678,<br/>
                    7+8 = 15<br/>
                    Karena angka 15 lebih besar dari 9, kembali dihitung 1+5=6<br/>
                    Berarti keluar : Genap dan Besar<br/>
                    <br/>
                    <h5>Analisis III:</h5>
                    Keluar : 1204,<br/>
                    0+4 = 4<br/>
                    Berarti keluar : Genap dan Kecil<br/>
                    Misal anda membeli dengan Rp.100rb untuk Genap, menang = 100rb + [indeks menang untuk Dasar]<br/>

                    NB: Indeks menang dan diskon dapat dilihat di bagian Peraturan
                </div>
            case -23:
                return <div>
                    <h4>TENGAH - TEPI</h4><br/>
                    Struktur: ABCD<br/>
                    Menebak tengah/tepi dari posisi 2D<br/>
                    Tengah= Diantara 25 sampai 74<br/>
                    Tepi =Diantara 00 sampai 24 dan 75 sampai 99<br/>

                    Struktur: ABCD<br/>
                    <br/>
                    <h5>Analisis I:</h5>
                    Keluar : 4321.<br/>
                    Permainan ini hanya memperhatikan posisi 2D, berarti yang dipedomani = 21<br/>
                    21 = TEPI<br/>
                    Misalnya dibeli posisi TEPI dengan nilai 100rb.<br/>
                    Berarti menang:<br/>
                    100rb + [Indeks kemenangan untuk tepi-tengah] + Kei<br/>
                    <br/>
                    <h5>Analisis II:</h5>
                    Keluar : 4356.<br/>
                    Permainan ini hanya memperhatikan posisi 2D, berarti yang dipedomani =56<br/>
                    56 = TENGAH<br/>
                    Misalnya dibeli posisi TENGAH dengan nilai 100rb.<br/>
                    Berarti menang:<br/>
                    100rb + [Indeks kemenangan untuk tepi-tengah] + Kei<br/>

                    NB: Indeks menang dan diskon dapat dilihat di bagian Peraturan
                </div>
            case -22:
                return <div>
                    <h4>Colok Jitu</h4><br/>
                    Menebak satu angka pada posisi tertentu dari 4D.<br/>

                    Struktur: ABCD<br/>
                    <br/>
                    <h5>Analisis I:</h5>
                    Keluar : 4321<br/>
                    Misalnya dibeli 4 pada posisi AS dengan nilai 100rb.<br/>
                    Berarti menang:<br/>
                    100rb + [Indeks kemenangan untuk colok jitu]<br/>

                    <br/>
                    <h5>Analisis II:</h5>
                    Keluar : 4331<br/>
                    Misalnya dibeli 3 pada posisi KOP dengan nilai 100rb.<br/>
                    Berarti menang:<br/>
                    100rb + [Indeks kemenangan untuk colok jitu]. Hasilnya sama dengan analisis I karena hanya<br/>
                    memperhatikan posisi yang dipasang.<br/>

                    <br/>
                    <h5>Analisis III:</h5>
                    Keluar : 4331<br/>
                    Misalnya dibeli 4 pada posisi EKOR dengan nilai 100rb.<br/>
                    Berarti kalah. Biarpun nilai 4 keluar pada posisi AS tapi tidak akan mepengaruhi pemilihan di pososi
                    EKOR<br/>

                    NB: Indeks menang dan diskon dapat dilihat di bagian Peraturan
                </div>
            case -21:
                return <div>
                    <h4>Colok Naga</h4><br/>
                    Cara kerja seperti colok bebas 2D / MACAU tapi mesti yang keluar 3 angka dari 4D.<br/>

                    Struktur: ABCD<br/>
                    <br/>
                    <h5>Analisis I:</h5>
                    Keluar : 4321<br/>
                    Misalnya dibeli 4 ,2 dan 3 dengan nilai 100rb.<br/>
                    Berarti menang:<br/>
                    karena keluar 3 digit,angka 4,2 dan 3.<br/>
                    100rb + [Indeks kemenangan untuk colok naga , kategori: 3 digit]<br/>
                    <br/>

                    <h5>Analisis II:</h5>
                    Keluar : 4321<br/>
                    Misalnya dibeli 4,2 dan 6 dengan nilai 100rb.<br/>
                    Berarti KALAH<br/>
                    karena keluar hanya 2 digit,angka 4 dan 2 .dan angka 6 tidak muncul<br/>
                    nilai betting tidak dikembalikan<br/>
                    <br/>

                    <h5>Analisis III:</h5>
                    Keluar : 4331<br/>
                    Misalnya dibeli 4,3 dan 3 dengan nilai 100rb.<br/>
                    Berarti menang:<br/>
                    karena keluar 3 digit,angka 4,3 dan 2.<br/>
                    100rb + [Indeks kemenangan untuk colok naga , kategori: 3 digit]<br/>
                    <br/>
                    <h5>Analisis IV:</h5>
                    Keluar : 4324<br/>
                    Misalnya dibeli 4,3 dan 2 dengan nilai 100rb.<br/>
                    karena keluar 4 digit,angka 4,3 dan 2.<br/>
                    Berarti menang:<br/>
                    100rb + [Indeks kemenangan untuk colok naga, kategori: 4 digit]<br/>

                    NB: Indeks menang dan diskon dapat dilihat di bagian Peraturan<br/>
                </div>
            case -20:
                return <div>
                    <h4>Macau / Colok bebas 2D</h4><br/>
                    Cara kerja seperti colok angka tapi mesti yang keluar 2 angka dari 4D.<br/>

                    Struktur: ABCD<br/>
                    <br/>
                    <h5>Analisis I:</h5>
                    Keluar : 4321<br/>
                    Misalnya dibeli 4 dan 2 dengan nilai 100rb.<br/>
                    Berarti menang:<br/>
                    100rb + [Indeks kemenangan untuk Macau, kategori: 2 digit]<br/>

                    <br/>
                    <h5>Analisis II:</h5>
                    Keluar : 4321<br/>
                    Misalnya dibeli 4 dan 6 dengan nilai 100rb.<br/>
                    Berarti KALAH dan nilai betting tidak dikembalikan<br/>
                    <br/>

                    <h5>Analisis III:</h5>
                    Keluar : 4331<br/>
                    Misalnya dibeli 4 dan 3 dengan nilai 100rb.<br/>
                    Berarti menang:<br/>
                    100rb + [Indeks kemenangan untuk Macau, kategori: 3 digit]<br/>
                </div>
            case -19:
                return <div>
                    <h4>Colok Angka</h4><br/>
                    Lebih di kenal COLOK BEBAS<br/>

                    Menebak salah satu angka dari 4D. Posisi angka bisa dimana saja<br/>

                    Struktur: ABCD<br/>
                    <br/>
                    <h5>Analisis I:</h5>
                    Keluar : 4321<br/>
                    Misalnya pembelian Angka 3 dengan nilai taruhan 100rb.<br/>
                    Berarti menang:<br/>
                    100rb + [Indeks kemenangan untuk colok angka]<br/>

                    <br/>
                    <h5>Analisis II:</h5>
                    Keluar : 4331<br/>
                    Misalnya pembelian Angka 3 dengan nilai taruhan 100rb.<br/>
                    Berarti menang:<br/>
                    100rb + ([Indeks kemenangan untuk colok Angka] x 2)<br/>

                    dan seterusnya untuk setiap kembaran yang berhasil ditebak, otomatis mendapat kelipatan [Indeks<br/>
                    kemenangan untuk colok angka]<br/>

                    NB: Indeks menang dan diskon dapat dilihat di bagian Peraturan<br/>
                </div>
            case -1:
            case -4:
            case -5:
                return <div>
                    <h4>4D, 3D dan 2D</h4>
                    Menebak 4 angka, 3 angka dan 2 angka. <br/>

                    Struktur: ABCD <br/>

                    Misalnya keluar : 4321 <br/>
                    Berarti pemenang untuk <br/>
                    4D = 4321 <br/>
                    3D=321 <br/>
                    2D=21 <br/>

                    Aturan permainan: <br/>
                    1. Jika anda membeli diluar dari nomor yang dikeluarkan, berarti anda kalah dan uang tidak <br/>
                    dikembalikan sama sekali. <br/>
                    2. Jika anda membeli masing 100rb untuk angka: <br/>
                    4D = 4321 <br/>
                    3D=321 <br/>
                    2D=21 <br/>

                    (Khusus untuk 4D,3D dan 2D diberikan diskon tambahan. Diskon akan makin besar jika nilai
                    akumulasi <br/>
                    betting dalam satu periode makin besar) <br/>

                    Berarti kemenangan anda adalah: <br/>
                    4D = 100rb x [Indeks kemenangan untuk 4D] <br/>
                    3D = 100rb x [Indeks kemenangan untuk 3D] <br/>
                    2D = 100rb x [Indeks kemenangan untuk 2D] <br/>
                    (Catatan: nilai bet 100rb tidak dikembalikan ke member) <br/>
<br/>

                    <h4>QUICK BET</h4>
                    Untuk memudahkan pembelian dalam jumlah banyak dengan kombinasi nomor berturutan, misalnya:<br/>
                    4321<br/>
                    321<br/>
                    21<br/>
                    1<br/>
                    maka dapat dilakukan dengan:<br/>
                    BET : 4321 4*500 x1000<br/>
                    BET : 4321 3*400 x1000<br/>
                    BET : 4321 2*300 x1000<br/>
                    BET : 4321 1*100 x1000<br/>
                    Ini berarti anda sudah berbelanja:<br/>
                    4321 untuk 500rb<br/>
                    321 untuk 400rb<br/>
                    21 untuk 300rb<br/>
                    1 untuk 100rb<br/>
<br/>
                    <h4>2D Posisi</h4>

                    Struktur: ABCD<br/>
                    Misalnya keluar nomor = 1234<br/>
                    berati pemenang untuk 2D Depan adalah = 12<br/>
                    berati pemenang untuk 2D Tengah adalah = 23<br/>
                    berati pemenang untuk 2D Belakang adalah = 34<br/>

                    NB: Indeks menang dan diskon dapat dilihat di bagian Peraturan<br/>
                </div>
                break
            default:
                return <div>
                    <h4>Kombinasi</h4>
                    Struktur ABCD <br/>
                    A=AS <br/>
                    B=KOP <br/>
                    C=KEPALA <br/>
                    D=EKOR <br/>
                    Besar/Kecil: 0-4=kecil, 5-9=besar <br/>
                    Ganjil/Genap: 1=ganjil, 2=genap dan seterusnya <br/>

                    Anda dapat menebak Genap/Ganjil, besar/Kevil <br/>
                    dari 2 kombinasi antara AS, KOP, KEPALA, EKOR <br/><br/>

                    Analisis: <br/>
                    keluar: 1845 <br/><br/>

                    berarti pemenang untuk: <br/>
                    As kecil/Ganjil dan KOP besar/Ganjil <br/>
                    KEPALA Kecil/Genap dan EKOR Besar/Ganjil <br/>
                    As Kecil/Ganjil dan KEPALA kecil/Genap <br/>
                    AS Kecil/Ganjil dan EKOR besar/ganjil <br/>
                    KOP Besar/genap dan KEPALA Kecil/Genap <br/>
                    KOP besar/Genap dan EKOR Besar/Ganjil <br/><br/>

                    Misalnya anda membeli AS KECIL dan KOP GENAP seharga 100rb, <br/>
                    maka menang = 100rb + [indeks kemenangan untuk kombinasi 2] <br/>
                    atu: <br/>
                    jika membeli KOP GENAP dan KEPELA GENAP shharga 100rb, <br/>
                    maka menang = 100rb + [indeks kemenangan untuk kombinasi 2] <br/>
                    atau: <br/>
                    jika membeli KEPALA KECIL dan EKOR GENAP saharga 100rb, berarti KALAH <br/>
                    (Anda harus menebak keduanya dengan Benar agar Menang) <br/>
                    <br/>
                    NB: Indeks menang dan diskon dapat dilihat di bagian Peraturan <br/>
                </div>
        }
    }
    return (
        <div className="games-wrapper">
            {loading && <LoadingUi/>}
            {listGames.length > 0 && <Row className={'anDi'}>
                <Col xs="2"></Col>
                <Col xs="8">
                    <div style={{display: 'flex', width: '100%'}} className={'games'}>
                        <h5 style={{margin: 'auto', fontSize: 30, marginBottom: 20}} className='games-title'>Game
                            Type</h5>
                    </div>
                </Col>
                <Col xs="2">
                    <div
                        className={'games-tab-item ' + (selectedGameId === 0 ? 'active' : '')}
                        onClick={() => handleGuideClick()}
                        style={{width: 160, margin: 'auto'}}
                    >
                        Guide
                    </div>
                </Col>
            </Row>}
            {listGames.length > 0 && <div className={'showRa'}><Row style={{display: 'flex'}}>
                <Col xs="12" md="12" xl="true" style={{textAlign: 'center'}}>
                    <h2 style={{fontWeight: 'bold', margin: 20}}>Betting Table</h2>
                </Col>
                <Col xs="6" className={'selectGame'} style={{textAlign: 'center'}}>
                    <Dropdown style={{width: '100%'}} isOpen={dropdownOpen} toggle={toggleSelectGame}
                              direction={'down'}>
                        <DropdownToggle className='selectGameBettingRp'
                                        caret><span>{listGames.find(x => x.id_game === Number(selectedGameId))?.game}</span></DropdownToggle>
                        <DropdownMenu>
                            {listGames.map(m => <DropdownItem key={m.id_game}
                                                              value={m.id_game}>{m.game}</DropdownItem>)}
                        </DropdownMenu>

                        </Dropdown>
                </Col>
                <Col xs="6"className={'selectGame'} id={'selectGameChild'} style={{textAlign: 'center'}}>
                        <Dropdown style={{width: '100%'}} isOpen={dropdownOpenChild} toggle={toggleSelectGameChild} direction={'down'}>
                            <DropdownToggle className='selectGameBettingRp'
                                            caret><span>{gameChildren[selectedGameId] ? gameChildren[selectedGameId][selectGame]?.title : ''}</span></DropdownToggle>
                            <DropdownMenu>
                                {gameChildren[selectedGameId] && gameChildren[selectedGameId].map((m, i) => <DropdownItem key={m.title}
                                                                  value={i}>{m.title}</DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>
                </Col>
            </Row>
            </div>}
            <Row>
                <Col xs="12" md="12" xl="true" className={'anDi'}>
                    <div className='games-tab-list'>
                        {listGames.map((game, index) => (
                            <div
                                className={selectedGameId === game.id_game ? 'games-tab-item-active' : 'games-tab-item'}
                                onClick={() => handleTabClick(game.id_game)}
                                key={index}
                            >
                                {game.game}
                            </div>
                        ))}
                    </div>


                </Col>
                <Col xs="12" md="12" xl="true">
                    {selectedGameId && (
                        <div className="selected-game">
                            {listGames.map((game, index) => {
                                    if (game.id_game === selectedGameId) {
                                        switch (game.game) {
                                            case "4D":
                                                return <FourD key={index} market={market} periode={periode}
                                                              getReCeipt={getReCeipt} betHistory={betHistory}
                                                              setBetHistory={setBetHistory} selectGame={selectGame}
                                                              setSelectGame={setSelectGame}/>
                                            case "2D Depan":
                                                return <TwoDDepan key={index} market={market} periode={periode}
                                                                  getReCeipt={getReCeipt} betHistory={betHistory}
                                                                  setBetHistory={setBetHistory}/>
                                            case "2D Tengah":
                                                return <TwoDTengah key={index} market={market} periode={periode}
                                                                   getReCeipt={getReCeipt} betHistory={betHistory}
                                                                   setBetHistory={setBetHistory}/>
                                            case "Colok Bebas":
                                                return <ColokBebas key={index} market={market} periode={periode}
                                                                   getReCeipt={getReCeipt} betHistory={betHistory}/>
                                            case "Colok Bebas 2D":
                                                return <ColokBebas2D key={index} market={market} periode={periode}
                                                                     getReCeipt={getReCeipt} betHistory={betHistory}
                                                                     setBetHistory={setBetHistory}/>
                                            case "Macau":
                                                return <Macau key={index} market={market} periode={periode}
                                                              getReCeipt={getReCeipt} betHistory={betHistory}
                                                              setBetHistory={setBetHistory}/>
                                            case "Colok Naga":
                                                return <ColokNaga key={index} market={market} periode={periode}
                                                                  getReCeipt={getReCeipt} betHistory={betHistory}
                                                                  setBetHistory={setBetHistory}/>
                                            case "Colok Jitu":
                                                return <ColokJitu key={index} market={market} periode={periode}
                                                                  getReCeipt={getReCeipt} betHistory={betHistory}
                                                                  setBetHistory={setBetHistory}/>
                                            case "Tengah":
                                                return <Tengah key={index} market={market} periode={periode}
                                                               getReCeipt={getReCeipt} betHistory={betHistory}
                                                               setBetHistory={setBetHistory}/>
                                            case "Dasar":
                                                return <Dasar key={index} market={market} periode={periode}
                                                              getReCeipt={getReCeipt} betHistory={betHistory}
                                                              setBetHistory={setBetHistory}/>
                                            case "50 - 50":
                                                return <FiveFive key={index} market={market} periode={periode}
                                                                 getReCeipt={getReCeipt} betHistory={betHistory}
                                                                 setBetHistory={setBetHistory}/>
                                            case "Shio":
                                                return <Shio key={index} market={market} periode={periode}
                                                             getReCeipt={getReCeipt} betHistory={betHistory}
                                                             setBetHistory={setBetHistory}/>
                                            case "Silang":
                                                return <Silang key={index} market={market} periode={periode}
                                                               getReCeipt={getReCeipt} betHistory={betHistory}
                                                               setBetHistory={setBetHistory}/>
                                            case "Kembang":
                                                return <Kembang key={index} market={market} periode={periode}
                                                                getReCeipt={getReCeipt} betHistory={betHistory}
                                                                setBetHistory={setBetHistory}/>
                                            case "Kombinasi":
                                                return <Kombinasi key={index} market={market} periode={periode}
                                                                  getReCeipt={getReCeipt} betHistory={betHistory}
                                                                  setBetHistory={setBetHistory}/>
                                            case "TwoDTengah":
                                                return <TwoDTengah key={index} market={market} periode={periode}
                                                                   getReCeipt={getReCeipt} betHistory={betHistory}
                                                                   setBetHistory={setBetHistory}/>
                                            default:
                                                return null;
                                        }
                                    }
                                return null;
                            })}
                            {betHistory.length > 0 &&
                                <>
                                    <Table className='font-10' style={{
                                        border: '1px solid', background: '#2e3192',
                                        marginTop: 30
                                    }}>
                                        <thead className='tr-number-Digital'>
                                        <tr>
                                            <th colSpan={7} style={{textAlign: 'center'}}>Total
                                                bet: {numeral(totalReceipt.taruhan).format('0,0')}&emsp; Total
                                                Discount: {numeral(totalReceipt.disc).format('0,0')}&emsp;Total
                                                Bayar: {numeral(totalReceipt.bayar).format('0,0')}</th>
                                        </tr>
                                        <tr>
                                            <th>No</th>
                                            <th>Tekeban</th>
                                            <th>Bet Type</th>
                                            <th>Bet</th>
                                            <th>Disc (%)</th>
                                            <th>Bayar</th>
                                            <th>X-Menang</th>
                                        </tr>

                                        </thead>

                                        <tbody>
                                        {betHistory.map((b, i) =>
                                            <tr key={b._id}>
                                                <td>{i + 1}</td>
                                                <td className='number'>{b.tebak}</td>
                                                {[19, 21,23,24,29].includes(b.id_game) ?
                                                    <td className='number'>{gamesName[b.id_game]}</td> :
                                                    <td className='number'>{b.posisi}</td>}
                                                <td className='number'>{numeral(b.taruhan).format('0,0')}</td>
                                                <td className='number'>{b.disc}</td>
                                                <td className='number'>{numeral(b.bayar).format('0,0.0')}</td>
                                                <td className='number'>{![23, 24, 25, 26, 28].includes(b.id_game) ? 'X' + b.kei + '=' : ''} {numeral([21,22,19, 20, 26,29].includes(b.id_game) ? b.jumlah + b.bayar : b.jumlah).format('0,0.0')}</td>
                                            </tr>
                                        )}

                                        </tbody>
                                    </Table>
                                    <div className="table-group-btn">
                                        <button className="table-btn table-btn-BTN" onClick={() => setBetHistory([])}>Okay</button>
                                    </div>
                                </>}
                        </div>
                    )}
                </Col>
            </Row>
            <Modal className={'guide'} isOpen={modal} toggle={toggle} style={{
                '--bs-modal-width': '80%',
                textAlign: 'center',
                color: '#fff'
            }}>
                <ModalHeader style={{
                    display: 'flow',
                    border: 'none',
                    paddingTop: 0

                }} toggle={toggle} close={closeBtn}></ModalHeader>
                <ModalBody>
                    {showGuide(selectedGuideId)}
                </ModalBody>

            </Modal>
            <DialogError/>
        </div>
    );
}

export default Games;
