export default {
  DASHBOARD: 'ဒိုင်ခွက်',
  TRANSACTIONS: 'အရောင်းအဝယ်',
  DEPOSITS: 'အပ်ငွေများ',
  WITHDRAW: 'ဆုတ်ခွာ',
  TRANSFERS: 'လွှဲပြောင်းခြင်း',
  BETTING_HISTORIES: 'လောင်းကစားခြင်းရာဇဝင်',
  REPORTS: 'အစီရင်ခံစာမျ',
  BONUS: 'အပိုဆု',
  BANKING: 'ဘဏ်လုပ်ငန်',
  USER_MANAGEMENT: 'အသုံးပြုသူစီမံခန့်ခွဲမှု',
  USERS: 'သုံးစွဲသူမျာ',
  CREATE_PARTNER: 'မိတ်ဖက်ဖန်တီးပါ',
  CREATE_PLAYER: 'ဖန်တီ ကစားသမား',
  CREATE_AGENT: 'ဖန်တီ အေးဂျင့်',
  CREATE_STAFF: 'ဖန်တီ ဝန်ထမ်းများ',
  POSITION_TAKING: 'ရာထူးနေရာရယူခြင်',
  COMMISSIONS: 'ကော်မရှင်',
  GAME_SETTING: 'ဂိမ်းချိန်ညှိခြင်',
  PROFILE: 'ကိုယ်ရေးအကျဉ်း',
  LOGOUT: 'ထွက်ပေါက်',
  SEARCH: 'ရှာဖွေရန်',
  FROM: 'နေ့မှ စ၍',
  TO: 'ယနေ့အထိ',
  ACTION: 'လှုပ်ရှားမှု',
  DATE: 'နေ့စွဲ၊',
  RECEIPT: 'လက်ခံဖြတ်ပိုင်',
  STATUS: 'အခြေအနေ',
  ACCOUNT_NO: 'အကောင့်နံပါတ်',
  ACCOUNT_NAME: 'အကောင့်အမည်',
  TO_BANK: 'ဘဏ်သို့',
  FROM_BANK: 'ဘဏ်မှ',
  CURRENCY: 'ငွေကြေ',
  AMOUNT: 'ပမာဏ',
  USERNAME: 'အသုံးပြုသူအမည်',
  'NO.': 'မဟုတ်ဘူး။.',
  ACCEPT_DEPOSIT: 'မင်းရဲ့တောင်းဆိုမှုသိုက်ကိုလက်ခံလိုက်ပြီ',
  REJECT_DEPOSIT: 'မင်းရဲ့တောင်းဆိုမှုသိုက်ကိုငြင်းပယ်လိုက်ပြီ',
  DEPOSIT_REQUEST: 'အပ်ငွေတောင်းခံမှု',
  ID: 'ID',
  BANK_ACCOUNT_NAME: 'ဘဏ်စာရင်းအမည်',
  BANK_ACCOUNT_NO: 'ဘဏ်စာရင်းနံပါတ်',
  UPDATE: 'နောက်ဆုံးသတင်း',
  UPDATE_BY: 'အားဖြင့်အသစ်ပြောင်းခြင်း',
  APPROVE: 'အတည်ပြု',
  ACCEPT: 'လက်ခံပါ၊',
  REJECT: 'ငြင်းပယ်',
  SUCCESS: 'အောင်မြင်မှု',
  WARNING: 'သတိပေးချက်',
  FAIL: 'ကျရှုံ',
  AGENT: 'အကျိုးဆောင်',
  BETID: 'BetId',
  BET: 'လောင်းကစာ',
  WINLOSE: 'ချစ်သူ',
  WINAMOUNT: 'အနိုင်ရငွေပမာဏ',
  JACKPOT: 'ထီပေါက်',
  CREATED: 'နေပြည်တော်',
  LOADING: 'တင်',
  TOTAL: 'စုစုပေါင်',
  TOTAL_BET: 'စုစုပေါင်းလောင်းခြင်း',
  TOTAL_WINLOSE: 'စုစုပေါင်း ချစ်သူ',
  TOTAL_TURNOVER: 'စုစုပေါင်းလည်ပတ်မှု',
  BETTING_DETAIL: 'လောင်းကစားအသေးစိတ်',
  SELECT_AGENT: 'အေးဂျင့် ကိုရွေးပါ',
  SELECT_GAME: 'ဂိမ်းရွေးပါ',
  SALE: 'ရောင်းမည်',
  DOWNLINE: 'အောက်လိုင်',
  DOWNLINE_ACCOUNT: 'အော့ဖ်လိုင်းအကောင့်',
  TOTAL_DOWNLINE_WINLOSE: 'စုစုပေါင်းအောက်ခြေ ချစ်သူ',
  TURNOVER: 'လည်ပတ်ငွေကြေးကြောင့်',
  DOWNLINE_TURNOVER: 'အကျဘက်လည်ပတ်မှု',
  MEMBER_TURNOVER: 'အသင်းဝင်လည်ပတ်မှု',
  ADMIN: 'အုပ်ချုပ်ရေ',
  PARTNER: 'လုပ်ဖော်ကိုင်ဖက်',
  PLAYER: 'ကစားသမား',
  COMPANY_TOTAL: 'ကုမ္ပဏီစုစုပေါင်း',
  SHARE_PERCEN: 'ဝေစု% ',
  COMM_PERCEN: 'ကော်မရှင် %',
  TOTAL_NET: 'စုစုပေါင်းအသားတင်',
  NO_DATA: 'အချက်အလက်မရရှိနိုင်ပါ...',
  ADD: 'ထည့်ပါ',
  BANK_NAME: 'ဘဏ်အမည်',
  BANK_CONFIG: 'ဘဏ်လုပ်ငန်းသတ်မှတ်ချက်',
  SUBMIT: 'တင်သွင်း',
  SELECT_ROLE: 'Role ကိုရွေးပါ',
  PHONE: 'ဖုန်',
  BALANCE: 'လက်ကျန်ငွေ',
  ROLE: 'အခန်းကဏ္',
  ACTIVE: 'တက်ကြွ',
  INACTIVE: 'လှုပ်ရှားမှု',
  ENTER_PASS: 'စကားဝှက်ရေးထည့်',
  UPDATE_USER: 'သုံးစွဲသူအားအပ်ဒိတ်လုပ်ခြင်',
  SELECT_STATUS: 'Select status',
  UPDATE_USER_SUCCESS: 'Uသုံးစွဲသူကိုအောင်အောင်မြင်မြင်မွမ်းမံခြင်',
  YOU_REQUEST_SUBMITED_SUCCESS: 'သင်၏တောင်းဆိုမှုကိုအောင်မြင်စွာတင်သွင်းခဲ့သည်',
  PLEASE_CONTACT_ADMIN: 'ကျေးဇူးပြု၍ စီမံခန့်ခွဲသူနှင့်ဆက်သွယ်ပါ',
  COMM_CANNOT_GREATE_THAN: 'မိခင်၏ PT ထက်ကဲ့ရဲ့ခြင်းကိုခံနိုင်သည်',
  UPDATE_PERCEN: 'အဆင့်မြှင့်တင်မှု ရာခိုင်နှုန်း',
  CREATE_AGENT_SUCCESS: 'အေးဂျင့် ကိုအောင်အောင်မြင်မြင်ဖန်တီးပါ',
  CREATE_PARTNER_SUCCESS: 'လုပ်ဖော်ကိုင်ဖက်ကိုအောင်မြင်အောင်ဖန်တီ',
  CREATE_PLAYER_SUCCESS: 'ကစားသမား ကိုအောင်မြင်အောင်ဖန်တီ',
  CREATE_STAFF_SUCCESS: '၀ န်ထမ်းများအောင်မြင်စွာဖန်တီးပါ',
  CREATE_AGENT_ERROR: 'အေဂျင်စီအမှားဖန်တီ',
  CREATE_PARTNER_ERROR: 'လုပ်ဖော်ကိုင်ဖက်အမှားဖန်တီးပါ',
  CREATE_PLAYER_ERROR: 'ကစားသူအမှားဖန်တီးပါ',
  CREATE_STAFF_ERROR: '၀ န်ထမ်းအမှားဖန်တီးပါ',
  ENTER_USERNAME: 'သုံးစွဲသူအမည်ထည့်ပါ',
  ENTER_FULLNAME: 'နာမည်အပြည့်အစုံ ရိုက်ထည့်',
  ENTER_PHONE: 'ဖုန်းကိုရိုက်ထည့်ပါ။',
  CREATE_NOW: 'ယခုဖန်တီးပါ',
  ERROR: 'အမှား !!!',
  USER_INFO: 'အသုံးပြုသူအချက်အလက်',
  EMAIL: 'အီးမေးလ်၊',
  OLD_PASS: 'စကားဝှက်ဟောင်',
  IPAY_QR_CODE: 'QR CODE',
  REGISTER_PARTNER: 'မှတ်ပုံတင်ပါ မိတ်ဖက်',
  REGISTER_AGENT: 'မှတ်ပုံတင်ရန်ကိုယ်စားလှယ်',
  REGISTER_PLAYER: 'မှတ်ပုံတင်သူ',
  LOGIN: 'လော့ဂ်အင်',
  LOGIN_ERROR: 'ဝင်ရောက်ရန်အမှား',
  NEW_PASS: 'စကားဝှက်အသစ်',
  SETTINGS: 'ချိန်ညှိချက်မျာ',
  PERCEN: 'ရာခိုင်နှုန်း',
  ACCOUNT: 'အကောင့်',
  LAST_LOGIN: 'နောက်ဆုံးဝင်ပါ',
  TOTAL_PARTNER_ACTIVE_SUSPENDED: 'ပါတနာ (တက်ကြွ / ဆိုင်းငံ့မှု)',
  TOTAL_AGENT_ACTIVE_SUSPENDED: 'အကျိုးဆောင် (တက်ကြွ / ဆိုင်းငံ့မှု)',
  TOTAL_PLAYER_ACTIVE_SUSPENDED: 'ကစားသမား (တက်ကြွ / ဆိုင်းငံ့မှု)',
  ENTER_NEWWIN_TOKEN: 'Newwin Token ကိုရိုက်ထည့်ပါ',
  ENTER_NEWWIN_URL: 'Newwin Url ကိုရိုက်ထည့်ပါ',
  ANNOUNCEMENT: 'ကြေငြာချက်',
  GAME_MANAGEMENT: 'ဂိမ်းစီမံခန့်ခွဲမှု',
  PROMOTION: 'ပရိုမိုးရှင်း',
  SETTING: 'ချိန်ညှိချက်များ',
  DOWNLINE_RECEIIVABLE: 'လက်ခံရရှိနိုင်သောအော့ဖ်လိုင်း',
  EARN_COMM: 'ကော်မရှင်ဝင်ငွေ',
  PAY_COMM: 'ပေးဆောင်သောကော်မရှင်',
  UPLINE_PAYABLE: 'အွန်လိုင်းသွားလို့ရမယ်',
  PLAYER_WINLOSE: 'ကစားသမားကအနိုင်ရသို့မဟုတ်ရှုံးသည်',
  SYNC_REAL_REPORT: 'စစ်မှန်သောအစီရင်ခံစာကိုတိုက်ဆိုင်ညှိယူပါ',
  FULL_REPORTS: 'အစီရင်ခံစာအနိုင်ရသို့မဟုတ်ရှုံး',
  FULL_REPORTS_PAYABLE: 'အခြေချမှုအစီရင်ခံစာ',
  PLAYER_COMM: 'ကစားသမားကော်မရှင်',
  PLAYER_TOTAL: 'ကစားသမားစုစုပေါင်းအရေအတွက်'
}