
import '../../games.css'

const data = [
    { tebakan: "0" },
    { tebakan: "" },
    { tebakan: "" },
    { tebakan: "" },
    { tebakan: "" },
    { tebakan: "" },
    { tebakan: "" },
    { tebakan: "" },
    { tebakan: "" },
]

const ColokBebas2D = () => {
    return (
        <div className="colok-Bebas2D">
            <div className="tab-overlow">
                <div className="tab">
                    <div className='tab-styled-box'>
                        <div className='bet-Pannel-CB2D'>
                            <p className="tab-text">Colok Bebas 2D Bet Pannel IDR 1.000 = 1</p>
                            <p className="tab-text tab-title-Total">Total Bet: 0</p>
                        </div>
                        <div>

                            <div className="table-thead">
                                <span className='col-CBD2-01'>No.</span>
                                <span className='col-CBD2-02'>Tebakan 1</span>
                                <span className='col-CBD2-03'>Tebakan 2</span>

                                <span className='col-CB2D-02' >Taruhan <span>
                                    <input style={{ maxWidth: "105px" }} className='table-input-none' placeholder='auto bet' disabled />
                                </span>(x1000)</span>
                            </div>


                            {data.map((i, ix) => (

                                <div className="table-item table-item-CB2D">
                                    <p className='tab-text tab-text-CB2D-01'>{ix + 1}</p>
                                    <p className='tab-text tab-text-CB2D-02'>{ix}</p>
                                    <p className='tab-text tab-text-CB2D-03'>{ix}</p>

                                    <div className="table-display table-display-CB2D" >
                                        <input className='table-input' placeholder='' />
                                        <p className='tab-text'>X1000</p>
                                    </div>
                                </div>
                            ))}

                            <div className="table-group-btn">
                                <button className="table-btn">Kirim</button>
                                <button style={{ background: "#D0453B" }} className="table-btn">Hapus</button>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ColokBebas2D