export default {
  DASHBOARD: 'pengumuman',
  TRANSACTIONS: 'Urus niaga',
  DEPOSITS: 'Deposit',
  WITHDRAW: 'Tarik balik',
  TRANSFERS: 'Pemindahan',
  BETTING_HISTORIES: 'sejarah taruhan',
  REPORTS: 'Laporan',
  BONUS: 'Bonus',
  BANKING: 'Perbankan',
  USER_MANAGEMENT: 'Pengurusan pengguna',
  USERS: 'Pengguna',
  CREATE_PARTNER: 'Buat Rakan Kongsi',
  CREATE_PLAYER: 'Buat Pemain',
  CREATE_AGENT: 'Buat Ejen',
  CREATE_STAFF: 'Buat Staf',
  POSITION_TAKING: 'Mengambil Jawatan',
  COMMISSIONS: 'Suruhanjaya',
  GAME_SETTING: 'Tetapan permainan',
  PROFILE: 'Profil',
  LOGOUT: 'keluar',
  SEARCH: 'Carian',
  FROM: 'Dari tarikh',
  TO: 'Ke Tarikh',
  ACTION: 'Tindakan',
  DATE: 'Tarikh',
  RECEIPT: 'Resit',
  STATUS: 'Status',
  ACCOUNT_NO: 'Nombor akaun',
  ACCOUNT_NAME: 'Nama akaun',
  TO_BANK: 'Kepada Bank',
  FROM_BANK: 'Dari Bank',
  CURRENCY: 'Mata wang',
  AMOUNT: 'Jumlah',
  USERNAME: 'Nama pengguna',
  'NO.': 'Tidak.',
  ACCEPT_DEPOSIT: 'deposit permintaan anda diterima',
  REJECT_DEPOSIT: 'deposit permintaan anda ditolak',
  DEPOSIT_REQUEST: 'Permintaan Deposit',
  ID: 'ID',
  BANK_ACCOUNT_NAME: 'Nama Akaun Bank',
  BANK_ACCOUNT_NO: 'Akaun Bank no',
  UPDATE: 'Kemas kini',
  UPDATE_BY: 'Kemas kini oleh',
  APPROVE: 'Terima',
  ACCEPT: 'terima',
  REJECT: 'Tolak',
  SUCCESS: 'kejayaan',
  WARNING: 'amaran',
  FAIL: 'kegagalan',
  AGENT: 'Ejen',
  BETID: 'taruhan ID',
  BET: 'taruhan',
  WINLOSE: 'Menang kalah',
  WINAMOUNT: 'menang jumlah',
  JACKPOT: 'Jackpot',
  CREATED: 'Dibuat',
  LOADING: 'Memuatkan',
  TOTAL: 'Jumlah',
  TOTAL_BET: 'jumlah pertaruhan',
  TOTAL_WINLOSE: 'jumlah kemenangan kalah',
  TOTAL_TURNOVER: 'Jumlah Perolehan',
  BETTING_DETAIL: 'Butiran Pertaruhan',
  SELECT_AGENT: 'Pilih Agen',
  SELECT_GAME: 'Pilih permainan',
  SALE: 'Jualan',
  DOWNLINE: 'Downline',
  DOWNLINE_ACCOUNT: 'Downline Akaun',
  TOTAL_DOWNLINE_WINLOSE: 'Jumlah Downline menang kalah',
  TURNOVER: 'Perolehan',
  DOWNLINE_TURNOVER: 'Downline Perolehan',
  MEMBER_TURNOVER: 'Member Perolehan',
  ADMIN: 'pentadbiran',
  PARTNER: 'Rakan kongsi',
  PLAYER: 'Pemain',
  COMPANY_TOTAL: 'Jumlah Syarikat',
  SHARE_PERCEN: 'Kongsi %',
  COMM_PERCEN: 'suruhanjaya %',
  TOTAL_NET: 'Jumlah Bersih',
  NO_DATA: 'Tiada data tersedia...',
  ADD: 'Tambah',
  BANK_NAME: 'Nama bank',
  BANK_CONFIG: 'Konfigurasi perbankan',
  SUBMIT: 'Hantar',
  SELECT_ROLE: 'Pilih Peranan',
  PHONE: 'Telefon',
  BALANCE: 'Seimbang',
  ROLE: 'posisi',
  ACTIVE: 'Aktif',
  INACTIVE: 'Tidak aktif',
  ENTER_PASS: 'Masukkan kata laluan',
  UPDATE_USER: 'Kemas kini Pengguna',
  SELECT_STATUS: 'Pilih status',
  UPDATE_USER_SUCCESS: 'Kemas kini Pengguna Berjaya',
  YOU_REQUEST_SUBMITED_SUCCESS: 'Permintaan anda diserahkan dengan jayanya',
  PLEASE_CONTACT_ADMIN: 'Sila hubungi pentadbir',
  COMM_CANNOT_GREATE_THAN: 'Suruhanjaya tidak boleh lebih besar daripada PT',
  UPDATE_PERCEN: 'Kemas kini Percenage',
  CREATE_AGENT_SUCCESS: 'Buat Agen Berjaya',
  CREATE_PARTNER_SUCCESS: 'Buat Rakan berjaya',
  CREATE_PLAYER_SUCCESS: 'Buat Pemain Berjaya',
  CREATE_STAFF_SUCCESS: 'Buat Kakitangan Berjaya',
  CREATE_AGENT_ERROR: 'Buat Kesilapan Ejen',
  CREATE_PARTNER_ERROR: 'Buat Ralat Rakan',
  CREATE_PLAYER_ERROR: 'Buat Kesilapan Pemain',
  CREATE_STAFF_ERROR: 'Buat Ralat Staf',
  ENTER_USERNAME: 'Masukkan nama pengguna',
  ENTER_FULLNAME: 'Masukkan Nama Penuh',
  ENTER_PHONE: 'Masukkan Telefon',
  CREATE_NOW: 'Buat Sekarang',
  ERROR: 'Ralat !!!',
  USER_INFO: 'Maklumat Pengguna',
  EMAIL: 'E-mel',
  OLD_PASS: 'Kata laluan lama',
  IPAY_QR_CODE: 'KOD QR',
  REGISTER_PARTNER: 'PENDAFTARAN PARTNER',
  REGISTER_AGENT: 'AGEN DAFTAR',
  REGISTER_PLAYER: 'PLAYER DAFTAR',
  LOGIN: 'Log masuk',
  LOGIN_ERROR: 'Ralat Log masuk',
  PERCEN: 'persentase',
  ACCOUNT: 'Akaun',
  LAST_LOGIN: 'Log masuk kali terakhir',
  TOTAL_PARTNER_ACTIVE_SUSPENDED: 'Rakan kongsi (aktif / dijeda)',
  TOTAL_AGENT_ACTIVE_SUSPENDED: 'Agen (aktif / digantung)',
  TOTAL_PLAYER_ACTIVE_SUSPENDED: 'Pemain (aktif / dijeda)',
  ENTER_NEWWIN_TOKEN: 'Masukkan Token Newwin',
  ENTER_NEWWIN_URL: 'Masukkan Url Newwin',
  ANNOUNCEMENT: 'Pengumuman',
  GAME_MANAGEMENT: 'Pengurusan Permainan',
  PROMOSI: 'Promosi',
  PENGATURAN: 'Tetapan',
  DOWNLINE_RECEIIVABLE: 'Diterima downline',
  EARN_COMM: 'Komisen didapat',
  PAY_COMM: 'Komisen dibayar',
  UPLINE_PAYABLE: 'Bayar untuk masuk dalam talian',
  PEMAIN_WINLOSE: 'Pemain menang kalah',
  SYNC_REAL_REPORT: 'Segerakkan Laporan Sebenar',
  FULL_REPORTS: 'Laporan Menang kalah',
  FULL_REPORTS_PAYABLE: 'Laporan penyelesaian',
  PLAYER_COMM: 'Komisen pemain',
  PLAYER_TOTAL: 'Jumlah pemain'
}