
import '../../games.css'
import {useDialog, useLoading} from "../../../../Component";
import React, {useEffect, useState} from "react";
import http from "../../../../service/http";
import numeral from "numeral";
import LoadingUi from "../../../../Component/Loading/ui";

const Silang = ({market, periode, betHistory, getReCeipt}) => {
    const init = [
        { tebakan: "Depan", kei: '', amount: '', posisi: 'Silang' },
        { tebakan: "Tengah", kei: '', amount: '', posisi: 'Silang' },
        { tebakan: "Belakang", kei: '', amount: '', posisi: 'Silang' },
    ]
    const init2 = [
        { tebakan: "Depan", kei: '', amount: '', posisi: 'Homo' },
        { tebakan: "Tengah", kei: '', amount: '', posisi: 'Homo' },
        { tebakan: "Belakang", kei: '', amount: '', posisi: 'Homo' },
    ]
    const [loading, withLoading] = useLoading()
    const [DialogError, showDialogErrorWithMessage] = useDialog({
        title: 'ERROR',
        type: 'error'
    })
    const [totalBet, setTotalBet] = useState(0)
    const [autobet, setAutoBet] = useState(['', ''])

    const [bets, setBets] = useState(init)
    const [bets2, setBets2] = useState(init2)
    const calTotalBet = (data) => {
        const arr = data.filter(x => x.tebakan !== '')
        if(arr.length > 0) setTotalBet(arr.reduce((a, b) => {
            return {
                amount: Number(a.amount) + Number(b.amount)
            }
        }).amount)
    }
    const onChangeAutoBet = (e) => {
        if(e.target.value === '' || Number(e.target.value) > 0) {
            const _bets = bets.map(b => {
                b.amount = e.target.value
                return b
            })
            setBets(_bets)
            calTotalBet(_bets.concat(bets2))
            const _autobet = [...autobet]
            _autobet[0] = e.target.value
            setAutoBet(_autobet)
        }

    }
    const onChangeAutoBet2 = (e) => {
        if(e.target.value === '' || Number(e.target.value) > 0) {
            const _bets = bets2.map(b => {
                b.amount = e.target.value
                return b
            })
            setBets2(_bets)
            calTotalBet(_bets.concat(bets))
            const _autobet = [...autobet]
            _autobet[1] = e.target.value
            setAutoBet(_autobet)
        }

    }
    const onChangeAmount = (e, index) => {
        const value = Number(e.target.value)
        if((value >= 0) || e.target.value === '') {
            const _bets = [...bets]

            // console.log(e.target.value, numbers, numbers.includes(e.target.value))
            _bets[index].amount = e.target.value
            calTotalBet(_bets.concat(bets2))
            setBets(_bets)
        }
    }
    const onChangeAmount2 = (e, index) => {
        const value = Number(e.target.value)
        if((value >= 0) || e.target.value === '') {
            const _bets = [...bets2]

            // console.log(e.target.value, numbers, numbers.includes(e.target.value))
            _bets[index].amount = e.target.value
            calTotalBet(_bets.concat(bets))
            setBets2(_bets)
        }
    }
    const submit = async () => {
        setBets(init)
        setBets2(init2)
        const payload = {
            betTypes: [],
            period: periode.length > 0 ? periode[0].no + 1 : 1,
            code: market.code,
            pid: market._id,
            gameId: 27
        }

        bets.filter(x => x.tebakan !== '' && x.amount > 0).map(b => {
            payload.betTypes.push({
                number: b.posisi,
                amount: b.amount * 1000,
                posisi: b.tebakan
            })
        })
        bets2.filter(x => x.tebakan !== '' && x.amount > 0).map(b => {
            payload.betTypes.push({
                number: b.posisi,
                amount: b.amount * 1000,
                posisi: b.tebakan
            })
        })

        try {
            if(payload.betTypes.length === 0) showDialogErrorWithMessage('No bet record !')
            else {
                const betrs = await withLoading(() => http.post({path: 'bet3', payload: JSON.stringify(payload)}))
                if (betrs.message && betrs.message !== '') {
                    showDialogErrorWithMessage(betrs.message)
                    if (betrs.toPage) {
                        const txid = betrs.toPage.split('&proses=')
                        if (txid[1]) {
                            const betH = await withLoading(() => http.get({path: 'users/bets/' + txid[1]}))
                            if(betH.length > 0)getReCeipt(betH)
                            else showDialogErrorWithMessage('No Bet accepted')
                        }
                    }
                } else {
                    const txid = betrs.toPage.split('&proses=')
                    const betH = await withLoading(() => http.get({path: 'users/bets/' + txid[1]}))
                    getReCeipt(betH)
                }
            }
        } catch (error) {
            showDialogErrorWithMessage(error.message)

        }

    }
    const fetchData = async () => {
        try {
            const response = await http.get({
                path: 'users/menang/' + market._id,
                params: {gameId: 27}
            })
            setBets(bets.map(b => {
                const menang = response.find(x => x.ket === b.posisi + ' ' + b.tebakan)
                b.kei = menang.nilai
                return b
            }))
            setBets2(bets2.map(b => {
                const menang = response.find(x => x.ket === b.posisi + ' ' + b.tebakan)
                b.kei = menang.nilai
                return b
            }))
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }
    useEffect(() => {
        fetchData()

    },[])
    return (
        <>{betHistory.length === 0 && <div>
            {loading && <LoadingUi/>}
            <div className="tab-overlow">

                <div className="tab">
                    <div className='tab-styled-box'>
                        <div style={{padding: "12px 0"}}>
                            <p className="tab-title font-10">SILANG</p>
                            <p style={{marginRight: 10}} className="tab-title tab-title-Total font-10">Total Bet: {numeral(totalBet * 1000).format('0,0')}</p>
                        </div>


                        <table className='table'>
                            <tr className="table-thead table-item-Silang font-10">
                                <th className='table-thead-01 font-10'>No.</th>
                                <th className='table-thead-02 font-10'>Tebakan</th>
                                <th className='table-thead-03 font-10'>Kei(%)</th>

                                <th className='table-thead-04' style={{ display: "flex", flexDirection: 'row', alignItems: "center", gap: "4px" }}>Taruhan
                                    <input className='table-thead-01 table-input-none table-input-none-SL'
                                           inputMode="numeric"
                                           value={autobet[0]} placeholder='auto bet'
                                           onChange={onChangeAutoBet}/>
                                    x1000
                                </th>
                            </tr>
                            {bets.map((i, ix) => (
                                <tr key={i.tebakan+i.posisi} className="table-item-Silang" >
                                    <td className='tab-text tab-text-Silang01 font-10'>{ix + 1}</td>
                                    <td className='tab-text tab-text-Silang02 font-10'>{i.tebakan}</td>
                                    <td  className='tab-text tab-text-Silang03 font-10'>{i.kei}</td>
                                    <div className="table-display table-display-Silang font-10">
                                        <input className='table-input table-input-Silang font-10' placeholder=''
                                               inputMode="numeric"
                                               value={i.amount}
                                               onChange={(e) => onChangeAmount(e, ix)}/>
                                        <p className='tab-text tab-text-Silang-X font-10'>X1000</p>
                                    </div>
                                </tr>
                            ))}
                        </table>

                        <div>
                            <p className="tab-title tab-title-SL">HOMO</p>
                        </div>

                        <table className='table'>
                            <tr className="table-thead table-item-Silang">
                                <th className='table-thead-01 font-10'>No.</th>
                                <th className='table-thead-02 font-10'>Tebakan</th>
                                <th className='table-thead-03 font-10'>Kei(%)</th>

                                <th className='table-thead-04 font-10' style={{ display: "flex", flexDirection: 'row', alignItems: "center", gap: "4px" }}>Taruhan
                                    <input className='table-input-none table-input-none-SL font-10'
                                           inputMode="numeric"
                                           value={autobet[1]} placeholder='auto bet'
                                           onChange={onChangeAutoBet2}/>
                                    x1000
                                </th>
                            </tr>

                            {bets2.map((i, ix) => (
                                <tr key={i.tebakan+i.posisi} className="table-item-Silang" >
                                    <td className='tab-text tab-text-Silang01 font-10'>{ix + 1}</td>
                                    <td className='tab-text tab-text-Silang02 font-10'>{i.tebakan}</td>
                                    <td  className='tab-text tab-text-Silang03 font-10'>{i.kei}</td>
                                    <div className="table-display table-display-Silang font-10">
                                        <input className='table-input table-input-Silang font-10' placeholder=''
                                               inputMode="numeric"
                                               value={i.amount}
                                               onChange={(e) => onChangeAmount2(e, ix)}/>
                                        <p className='tab-text tab-text-Silang-X font-10'>X1000</p>
                                    </div>
                                </tr>
                            ))}
                        </table>

                        <div className="table-group-btn">
                            <button className="table-btn table-btn-BTN" onClick={submit}>Kirim</button>
                            <button style={{ background: "#D0453B" }} className="table-btn table-btn-BTN">Hapus</button>
                        </div>
                    
                    </div>
                </div>
            </div>
            <DialogError/>
        </div>}
        </>
    )
}

export default Silang