
import '../report.css'
import React, {useEffect, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import http from "../../../service/http";
import moment from "moment";
import {useDialog, useLoading} from "../../../Component";
import LoadingUi from "../../../Component/Loading/ui";
import numeral from "numeral";
import ImgBack from "../../../assets/img/icons/back.png";

const BettingDetila = ({ bets, setBets }) => {
    const [total, setTotal] = useState({
        taruhan: 0,
        bayar: 0,
        jumlah: 0,
        winlose: 0
    })
    useEffect(() => {
        console.log("Betting Detila")

        if(bets.length > 0) {
            if(bets.length === 1) setTotal({
                taruhan: bets[0].taruhan,
                bayar: bets[0].bayar,
                jumlah: bets[0].jumlah,
                winlose: bets[0].winlose,
            })
            else setTotal(bets.reduce((a, b) => {
                const jumlah = b.status !== 'pending' ? b.jumlah : 0
                const winlose = b.status !== 'pending' ? b.winlose * 1 : 0
                return {
                    taruhan: a.taruhan + b.taruhan,
                    bayar: a.bayar + b.bayar,
                    jumlah: jumlah + a.jumlah,
                    winlose: winlose + Number(a.winlose),
                    status: b.status
                }
            }))
        } else {
            setTotal({
                taruhan: 0,
                bayar: 0,
                jumlah: 0,
                winlose: 0
            })
        }
    }, []);
    const getColorProfit = (bet) => {
        let color = '#fff'
        if(bet.status !== 'pending') {
            if(bet.jumlah > 0) color = '#0df04c'
            else if(bet.jumlah < 0) color = '#d7868e'
        }
        return color
    }

    
    return (
        <div className="report-wrapper">
            <div className="report-container">
                <div style={{display: "flex", justifyContent: "start", width: "100%"}}>
                <img style={{cursor: "pointer"}} className="bettingRe-IconBack" src={ImgBack} onClick={() => setBets([])}/>
                <p className='bettingRe-title' style={{fontSize: 24}}>Betting Detila</p>
                </div>
                <table id="report" style={{width: "100%", background: "#1C4587", textAlign: 'center'}}>
                    <tr className='f9' style={{fontSize: 25, fontWeight: 'bold'}}>
                        <th style={{borderRight: 0}}>Total</th>

                        <th style={{borderRight: 0, borderLeft: 0}}
                            colSpan={2}>Bet: {numeral(total.taruhan).format('0,0')}</th>
                        <th style={{borderRight: 0, borderLeft: 0}}
                            colSpan={2}>Discount: {numeral(total.taruhan - total.bayar).format('0,0')}</th>
                        <th style={{borderRight: 0, borderLeft: 0}}
                            colSpan={2}>Bayar: {numeral(total.bayar).format('0,0')}</th>
                        <th style={{borderLeft: 0}}
                            colSpan={2}>Profit: {numeral(total.jumlah - total.bayar).format('0,0')}</th>
                    </tr>
                    <tr className='f9'>
                        <th style={{width: "5%"}}>Periode</th>
                        <th style={{minWidth: 280}}>Invoice</th>
                        <th style={{width: "15%"}}>Game code</th>
                        <th style={{width: "15%"}}>Tebak</th>
                        <th style={{width: "15%"}}>Status</th>
                        <th style={{width: "15%"}}>Bet</th>
                        <th style={{width: "15%"}}>Disc(%)</th>
                        <th style={{width: "15%"}}>Bayar</th>
                        <th style={{minWidth: 100}}>Total Profit</th>
                    </tr>
                    {bets.map(b => <tr>
                        <td className='f9'>{b.periode}</td>
                        <td className='f9'>{b.invoice}</td>
                        <td className='f9'>{b.code}</td>
                        <td className='f9'>{[1, 2, 3, 4, 5].includes(b.id_game) && !b.posisi.includes('-') ? 'BetDiskon-' : ''}{b.posisi} - {b.tebak}</td>
                        <td className='f9'><span className={'status'}
                                                 style={{color: b.status === 'pending' ? '#ffc200' : (b.status === 'menang' ? '#0df04c' : '#d7868e')}}>{b.status}</span>
                        </td>
                        <td className='f9'>{numeral(b.taruhan).format('0,0')}</td>
                        <td className='f9'>{b.disc}</td>
                        <td className='f9'>{numeral(b.bayar).format('0,0')}</td>
                        <td className='f9'><span
                            style={{color: getColorProfit(b)}}>{b.status !== 'pending' ? numeral(b.jumlah).format('0,0') : 0}</span>
                        </td>
                    </tr>)}
                </table>
            </div>
        </div>
    )
}

export default BettingDetila;