import React from 'react';
import Loading from 'react-fullscreen-loading';

class App extends React.Component {
    render() {
        return(
            <Loading loading background="#d2d6de00" loaderColor="#3498db" />
        )
    }
}

export default App;