
const data = [ 
    {   
        num: 1,
        tebak: "Depan",
        amount: 5.000,
        disc: 125,
        total: 4.875
    },
    {
        num: 2,
        tebak: "Tengah",
        amount: 5.000,
        disc: 125,
        total: 4.875
    },
    {
        num: 3,
        tebak: "Belakang",
        amount: 5.000,
        disc: 125,
        total: 4.875
    }

]
function SilangHomo() {

    
    const totals = {
        amount: data.reduce((acc, curr) => acc + curr.amount, 0),
        disc: data.reduce((acc, curr) => acc + curr.disc, 0),
        total: data.reduce((acc, curr) => acc + curr.total, 0)
    };
    return (
        <div >

            <table style={{color: "black"}} className="table-h5 f12">
                <tr className="limit-tr01">
                    <th colspan="5" className="th-h5">Silang</th>  
                </tr>
                <tr className="limit-tr01">
                    <th style={{width: "5%"}} className="th-h5">No</th>
                    <th style={{width: "35%"}} className="th-h5">Tebak</th>
                    <th style={{width: "20%"}} className="th-h5">Amount</th>
                    <th style={{width: "20%"}} className="th-h5">Disc(%)</th>
                    <th style={{width: "20%"}} className="th-h5">Total</th>
                </tr>

                {data.map((item, index) => (
                    <tr key={index}>
                        <td className="td-h5">{item.num}</td>
                        <td className="td-h5">{item.tebak}</td>                
                        <td className="td-h5">{item.amount}</td>
                        <td className="td-h5">{item.disc}</td>
                        <td className="td-h5">{item.total}</td>
                    </tr>
                ))}

                <tr>
                    <td className="td-h5"></td>
                    <td className="td-h5 fontWeight1000">Total</td>
                    <td className="td-h5 fontWeight1000">{totals.amount}</td>
                    <td className="td-h5 fontWeight1000">{totals.disc}</td>
                    <td className="td-h5 fontWeight1000">{totals.total}</td>
                </tr>

            </table>


            <table style={{marginTop: "2%", color: "black"}} className="table-h5 f12">
                <tr className="limit-tr01">
                    <th colspan="5" className="th-h5">Homo</th>              
                </tr>
                <tr className="limit-tr01">
                    <th style={{width: "5%"}} className="th-h5">No</th>
                    <th style={{width: "35%"}} className="th-h5">Tebak</th> 
                    <th style={{width: "20%"}} className="th-h5">Amount</th>
                    <th style={{width: "20%"}} className="th-h5">Disc(%)</th>
                    <th style={{width: "20%"}} className="th-h5">Total</th>
                </tr>

                {data.map((item, index) => (
                    <tr key={index}>
                        <td className="td-h5">{item.num}</td>
                        <td className="td-h5">{item.tebak}</td>                
                        <td className="td-h5">{item.amount}</td>
                        <td className="td-h5">{item.disc}</td>
                        <td className="td-h5">{item.total}</td>
                    </tr>
                ))}

                <tr>
                    <td className="td-h5"></td>
                    <td className="td-h5 fontWeight1000">Total</td>
                    <td className="td-h5 fontWeight1000">{totals.amount}</td>
                    <td className="td-h5 fontWeight1000">{totals.disc}</td>
                    <td className="td-h5 fontWeight1000">{totals.total}</td>
                </tr>

            </table>

        </div>
    )
}

export default SilangHomo