
import '../../games.css'
import React, {useState} from "react";
import {useDialog, useLoading} from "../../../../Component";
import http from "../../../../service/http";
import numeral from "numeral";
import LoadingUi from "../../../../Component/Loading/ui";
import { Container, Row, Col, Input, Table } from 'reactstrap';
const ColokJitu = ({market, periode, betHistory, getReCeipt}) => {
    const init = [
        { tebakan: "", posisi: '', amount: '' },
        { tebakan: "", posisi: '', amount: '' },
        { tebakan: "", posisi: '', amount: '' },
        { tebakan: "", posisi: '', amount: '' },
        { tebakan: "", posisi: '', amount: '' },
        { tebakan: "", posisi: '', amount: '' },
        { tebakan: "", posisi: '', amount: '' },
        { tebakan: "", posisi: '', amount: '' },
        { tebakan: "", posisi: '', amount: '' },
        { tebakan: "", posisi: '', amount: '' },
    ]
    const [totalBet, setTotalBet] = useState(0)
    const [autobet, setAutoBet] = useState('')
    const [bets, setBets] = useState(init)
    let localbets = []
    const runReceipt = () => {
        getReCeipt(localbets)
    }
    const [loading, withLoading] = useLoading()
    const [DialogError, showDialogErrorWithMessage] = useDialog({
        title: 'ERROR',
        type: 'error'
    })
    const calTotalBet = (data) => {
        const arr = data.filter(x => x.tebakan !== '' && x.posisi !== '')
        if(arr.length > 0) setTotalBet(arr.reduce((a, b) => {
            return {
                amount: Number(a.amount) + Number(b.amount)
            }
        }).amount)
    }
    const onChangePosisi = (e, index) => {
        const _bets = [...bets]
        _bets[index].posisi = e.target.value
        calTotalBet(_bets)
        setBets(_bets)
    }
    const onChangeTebak = (e, index) => {
        const value = Number(e.target.value)
        if((e.target.value.length === 1 && value >= 0) || e.target.value === '') {
            const _bets = [...bets]

            const numbers = [_bets[index].tebakan, _bets[index].tebak2, _bets[index].tebak3]
            // console.log(e.target.value, numbers, numbers.includes(e.target.value))
            if(e.target.value !== '' && numbers.includes(e.target.value)) return
            _bets[index].tebakan = e.target.value
            calTotalBet(_bets)
            setBets(_bets)
        }
    }
    const onChangeAmount = (e, index) => {
        const value = Number(e.target.value)
        if((value >= 0) || e.target.value === '') {
            const _bets = [...bets]

            // console.log(e.target.value, numbers, numbers.includes(e.target.value))
            _bets[index].amount = e.target.value
            calTotalBet(_bets)
            setBets(_bets)
        }
    }
    const onChangeAutoBet = (e) => {
        if(e.target.value === '' || Number(e.target.value) > 0) {
            const _bets = bets.map(b => {
                if(b.tebakan !== '' && b.posisi !== '') {
                    b.amount = e.target.value
                }
                return b
            })
            setBets(_bets)
            calTotalBet(_bets)
            setAutoBet(e.target.value)
        }
    }
    const submit = async () => {
        setBets(init)
        const payload = {
            betTypes: [],
            period: periode.length > 0 ? periode[0].no + 1 : 1,
            code: market.code,
            pid: market._id,
            gameId: 22
        }

        bets.filter(x => x.tebakan !== '' && x.posisi !== '' && x.amount > 0).map(b => {
            payload.betTypes.push({
                number: b.tebakan,
                amount: b.amount * 1000,
                posisi: b.posisi
            })
        })

        try {
            if(payload.betTypes.length === 0) showDialogErrorWithMessage('No bet record !')
            else {
                const betrs = await withLoading(() => http.post({path: 'bet3', payload: JSON.stringify(payload)}))
                const txid = betrs.toPage.split('&proses=')
                const betH = await withLoading(() => http.get({path: 'users/bets/' + txid[1]}))
                if (betrs.message && betrs.message !== '') {
                    if (betH.length > 0) {
                        localbets = betH
                        showDialogErrorWithMessage(betrs.message, runReceipt)
                    }
                    else showDialogErrorWithMessage(betrs.message)
                } else {

                    getReCeipt(betH)
                }
            }
        } catch (error) {
            showDialogErrorWithMessage(error.message)

        }

    }
    return (
        <>{betHistory.length === 0 && <div>
            {loading && <LoadingUi/>}
            <div className="tab-overlow tab-overlow-CJ">
                <div className="tab">
                    <div className='tab-styled-box'>
                        <div>
                            <p className="tab-text font-10">Colok Jitu Bet Pannel IDR 1.000 = 1</p>
                            <p style={{marginRight: 10}} className="tab-text tab-title-Total font-10">Total Bet: {numeral(totalBet * 1000).format('0,0')}</p>
                        </div>

                        <div>
                            <Table style={{fontSize: 18}}>
                                <thead>
                                <tr>
                                    <th style={{paddingLeft: 5}}>No.</th>
                                    <th>Tebakan</th>
                                    <th>Posisi</th>
                                    <th>
                                        <div style={{display: 'flex'}}>
                                            <p style={{
                                                margin: '0 10px',
                                                lineHeight: '27px'}}>Taruhan</p>
                                            <input className='table-input-none table-input-none-CJ'
                                                   placeholder='auto bet'
                                                   value={autobet}
                                                   inputMode="numeric"
                                                   onChange={onChangeAutoBet}/>
                                        </div>

                                    </th>
                                    <th><p className='tab-text tab-text-1000-CJ font-10'>X1000</p></th>
                                </tr>
                                </thead>
                                <tbody>
                                {bets.map((i, ix) => (
                                    <tr key={ix + i.tebakan}>
                                        <td>
                                            {ix + 1}
                                        </td>
                                        <td>
                                            <input className='table-input' placeholder=''
                                                   inputMode="numeric"
                                                   value={i.tebakan}
                                                   onChange={(e) => onChangeTebak(e, ix)}
                                            />
                                        </td>
                                        <td>
                                            <select className='table-select font-10'
                                                    value={i.posisi}
                                                    onChange={(e) => onChangePosisi(e, ix)}>
                                                <option></option>
                                                <option value="As">As</option>
                                                <option value="Kop">Kop</option>
                                                <option value="Kepala">Kepala</option>
                                                <option value="Ekor">Ekor</option>
                                            </select>
                                        </td>
                                        <td>
                                                <input className='table-input' placeholder=''
                                                       inputMode="numeric"
                                                       value={i.amount}
                                                       onChange={(e) => onChangeAmount(e, ix)}
                                                />
                                        </td>
                                        <td>
                                            <p className='tab-text tab-text-1000-CJ font-10'>X1000</p>
                                        </td>
                                    </tr>

                                    ))}
                                </tbody>
                            </Table>

                            <div className="table-group-btn">
                                <button className="table-btn table-btn-BTN" onClick={submit}>Kirim</button>
                                <button style={{ background: "#D0453B" }} className="table-btn table-btn-BTN">Hapus</button>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <DialogError/>
        </div>}
        </>
    )
}

export default ColokJitu