import React, { useState, useEffect } from 'react';
import {
  Badge, Nav, NavItem, NavLink, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown
} from 'reactstrap'
import numeral from "numeral";
import PropTypes from 'prop-types';
import profileicon from '../../assets/img/icons/Profile.png'
import walleticon from '../../assets/img/icons/Wallet.png'
import logouticon from '../../assets/img/icons/logout.png'
import refresh from '../../assets/img/icons/refresh.png'
import bars from '../../assets/img/icons/bars.svg'
import store from 'store'
import '../styled.css'
import { Link } from 'react-router-dom';
import ImgBetting from '../../assets/img/icons/BettingReport.png'
import ImgHistoryTransaction from '../../assets/img/icons/TransactionHistory.png'
import { initializeApp } from 'firebase/app'
import { getDatabase, ref, onValue, get, child } from "firebase/database"
import config from '../../config'
import queryString from 'query-string'

const link = [
  // { title: "Home", href: "/" },
  // { title: "Games", href: "/games" },
  { title: "Transation History", href: "/history" },
  { title: "Betting Report", href: "/report" },
  { title: "Result", href: "/result" },
  // { title: "Tools", href: "/tools" },
  // { title: "History02", href: "/history02" },
  // { title: "dashboard", href: "/dashboard" },
  // { title: "betType", href: "/betType"}
]

const propTypes = {
  children: PropTypes.node,
}

const defaultProps = {}

const sleep = ms => new Promise(r => setTimeout(r, ms))
function DefaultHeader(props) {
  const { t, user, refreshUser, location } = props
  const initialValues = queryString.parse(location.search)
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeBtn, setActiveBtn] = useState('');
  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.classList.toggle('no-scroll', !isMenuOpen);

  }
  const firebaseConfig = {
    "type": "service_account",
    "project_id": config.fireBaseProjectId,
    "client_email": config.fireBaseClientEmail,
    "client_id": config.fireBaseClientId,
    "auth_uri": "https://accounts.google.com/o/oauth2/auth",
    "token_uri": "https://oauth2.googleapis.com/token",
    "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
    "client_x509_cert_url": `https://www.googleapis.com/robot/v1/metadata/x509/${config.fireBaseClientEmail}`,
    "universe_domain": "googleapis.com",
    databaseURL: config.fireBaseDatabaseURL
  }
  const app = initializeApp(firebaseConfig)
  const database = getDatabase(app);
  const cartRef = ref(database, 'hksydney/users')
 useEffect(() => {
    setActiveBtn(activeBtn)
 }, [activeBtn])
useEffect(() => {
  if (user && user.username) {

    onValue(cartRef, async (snapshot) => {
      await sleep(2000)
      const data = snapshot.val();
      // console.log(data.timestamp.toString(), data)
      if( data && data.to.toLowerCase() === user.username.toLowerCase()) {
        const loginTime = localStorage.getItem('loginTime')

        if( data.timestamp.toString() !== loginTime) {

          window.location.href = '/login'
        }
      } else if(data && data.content === 'deactive-parent'){
        // console.log(data)
        if(data.parents.includes(user.username) || data.parents.includes(user.parent)) window.location.href = '/login'
      } else if(data && data.content === 'config'){
        props.refreshNews(data.timestamp)
      } else if(data && data.content === 'config-market' && initialValues.t !== data.timestamp.toString()){
        window.location.href = '/?t=' + data.timestamp.toString()
      } else {
        console.log('Data not found');
      }
    })
  }
 }, [])

  return (
    <React.Fragment>
      <div className='wrapper-header'>
        <div className='container-header'>
          <div className=''>
            <div className='styled-header-group'>
              <Link to={"/"}>
                <img style={{ width: "100px" }} src="./logovip.png" alt="logo" />
              </Link>
              <div className='styled-header-item-group'>
                <div className='styled-header-item'>
                  <img className='styled-header-img' src={profileicon} alt="profileicon" />
                  <span className='styled-text-header-none'>Username:</span>
                  <span className='styled-text-header'>
                    {user.username && String(user.username.length) > 15 ? (
                      <span>{`${user.username.substring(0, 8)}...${user.username.substring(user.username.length - 3)}`}</span>
                    ) : (
                      <span>{user.username}</span>
                    )}
                  </span>
                </div>
                <div className='styled-header-item'>
                  <img className='styled-header-img' src={walleticon} alt="walleticon" />
                  <span className='styled-text-header-none'>Balance: </span>
                  <span className='styled-text-header'>{numeral(user.saldo).format('0,0')}</span>
                  <img style={{ width: "14px", height: '14px', cursor: 'pointer' }} onClick={refreshUser} src={refresh} alt="refresh" />
                </div>
              </div>
            </div>
          </div>

          <div className='styled-btn-group'>
            <Link to={"/results"}>
                  <button className={activeBtn === "2" ? "btn-header-active" : "btn-header"} onClick={() => setActiveBtn("2")}>Results</button>
            </Link>

            <Link to={"/report"}>
                  <button className={activeBtn === "0" ? "btn-header-active" : "btn-header"} onClick={() => setActiveBtn("0")}>Betting Report</button>
            </Link>

            <Link to={'/history'}>
                <button className={activeBtn === "1" ? "btn-header-active" : "btn-header"} onClick={() => setActiveBtn("1")}>Transation History</button>
            </Link>

            {/* <Link to={'/tools'}>
            <button className={activeBtn === "2" ? "btn-header-active" : "btn-header"} onClick={() => setActiveBtn("2")}>Tools</button>
            </Link>

            <Link to={'/history02'}>
            <button className={activeBtn === "3" ? "btn-header-active" : "btn-header"} onClick={() => setActiveBtn("3")}>History</button>
            </Link>

            <Link to={'/dashboard'}>
            <button className={activeBtn === "4" ? "btn-header-active" : "btn-header"} onClick={() => setActiveBtn("4")}>Dashboard</button>
            </Link>

            <Link to={'/betType'}>
            <button className={activeBtn === "5" ? "btn-header-active" : "btn-header"} onClick={() => setActiveBtn("5")}>BetType</button>
            </Link> */}

          </div>

          <Link to={"/login"} className="styled-header-item-none">
            <div className='styled-header-item'>
              <img className='styled-header-img' src={logouticon} alt="walleticon" />
              <span className='styled-text-header'>Logout</span>
            </div>
          </Link>
          <div className='styled-header-bars' onClick={handleMenuClick}>
            <img style={{ width: '30px', height: "30px", cursor: "pointer" }} src={bars} alt="bars" />
          </div>
          {isMenuOpen && (
            <div className='menu-mobile' id="mobile-menu">
              <div className='styled-menu-mobile'>
                <Link to={"/"}>
                  <img style={{ width: "100px" }} src="./logovip.png" alt="logo" />
                </Link>
                <div className='styled-header-bars' onClick={handleMenuClick}>
                  <img style={{ width: '30px', height: "30px", cursor: "pointer" }} src={bars} alt="bars" />
                </div>
              </div>
              <div className='menu-mobile-list'>

                {/* <div className='menu-mobile-item'>
                  {link.map((i, ix) => (   
                    <Link key={ix} to={i.href} onClick={handleMenuClick}>{i.title}</Link>
                  ))}
                </div> */}


                <div className='menu-mobile-item'>
                  {link.map((i, ix) => (
                    <Link key={ix} to={i.href} onClick={handleMenuClick} style={{width: '100%'}}>
                      {i.title === "Betting Report" && (
                        <img className='reportIcon' src={ImgBetting} style={{ position: "relative", zIndex: 9999, margin: 0, height: 48 }} />
                      )}
                      {i.title === "Transation History" && (
                        <img className='historyTransactionIcon' src={ImgHistoryTransaction} style={{ position: "relative", zIndex: 9999, margin: 0, height: 48 }} />
                      )}
                      {i.title}
                    </Link>
                  ))}
                </div>


                <Link to={"/login"} onClick={handleMenuClick} style={{ width: "100%" }}>
                  <button className='menu-mobile-btn-logout'>
                    <img className='menu-mobile-list-Img' src={logouticon} alt="logout" style={{height: 38, width: 38}} />
                    {"  "} <span className='menu-mobile-list-Text'>Logout</span>
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

    DefaultHeader.propTypes = propTypes
    DefaultHeader.defaultProps = defaultProps
export default DefaultHeader;
