import numeral from "numeral";
import {Col, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import http from "../../../../service/http";

function Invoice({setPage}) {
    const [market, setMarket] = useState(null)
    const [invoices, setDataInvoice] = useState([])
    const [markets, setMarkets] = useState([])
    const [total, setTotal] = useState({
        taruhan: 0,
        bayar: 0,
        winlose: 0,
    })

    const selectMarket = (id) => {
        const m = markets.find(x => x._id === id)
        setMarket(m)
    }
    const fetchDataResult = async () => {
        try {
            const data = await http.get({ path: 'periodes/' + market._id });
            fetchDataInvoice(data[0])
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const fetchDataInvoice = async (period) => {
        try {
            const data = await http.get({ path: 'report/betlist', params: {
                    periode: period.no,
                    pid: market._id
                } });
            setDataInvoice(data);
            if(data.length > 0) {
                if(data.length === 1) setTotal(data[0])
                else setTotal(data.reduce((a, b) => {
                        return {
                            taruhan: Number(a.taruhan) + Number(b.taruhan),
                            bayar: Number(a.bayar) + Number(b.bayar),
                            winlose: Number(a.winlose) + Number(b.winlose)
                        }
                    }
                ))
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const fetchData = async () => {
        try {
            const data = await http.get({ path: 'pasarans' });
            setMarkets(data);
            setMarket(data[0])
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        if(market) fetchDataResult();
    }, [market])
    useEffect(() => {
        fetchData();
    }, [])
    return (
        
        <div style={{marginTop: "20px"}}>
            <Row>
                <Col xs={8}>
                    <div style={{display: 'flex', marginBottom: 20}}>
                        <div className="history-button">
                            <button onClick={() => setPage(0)} className='history-list-button' style={{
                                background: 'gray',
                                color: '#000'
                            }}>RESULT
                            </button>

                        </div>
                        <div className="history-button">
                            <button className='history-list-button' style={{
                                background: 'red',
                                color: 'white'
                            }}>INVOICE
                            </button>

                        </div>
                        <div className="history-button">
                            <button onClick={() => setPage(2)} className='history-list-button' style={{
                                background: 'gray',
                                color: '#000'
                            }}>TRANSACTION HISTORY
                            </button>

                        </div>
                    </div>
                </Col>
                <Col xs={4} style={{textAlign: 'right'}}>
                    {market && <select className='result-select f9'
                                       value={market._id} onChange={e => selectMarket(e.target.value)}>
                        {markets.map(m => <option value={m._id}>{m.code}</option>)}
                    </select>}
                </Col>
            </Row>
                <table className="table-h5 f12">
                    <tr style={{color: "white", backgroundColor: "red"}}>
                        <th className="th-h5" style={{width: "5%"}}>No</th>
                        <th className="th-h5" style={{width: "20%"}}>Game</th>
                        <th className="th-h5" style={{width: "25%"}}>Beli</th>
                        <th className="th-h5" style={{width: "25%"}}>Bayar</th>
                        <th className="th-h5" style={{width: "25%"}}>Total Menang</th>
                    </tr>

                        {invoices.map((item, index) => (
                            <tr key={index} style={{color: "black"}}>
                                <td className="td-h5">{index+1}</td>
                                <td className="td-h5">{item.games[0].game}</td>
                                <td className="td-h5">{numeral(item.taruhan).format('0,000.0')}</td>
                                <td className="td-h5">{numeral(item.bayar).format('0,000.0')}</td>
                                <td className="td-h5">{numeral(item.winlose).format('0,000.0')}</td>
                            </tr>
                        ))}

                </table>
                <table className="table-h5 f12">
                    <tr className="invoice-table-last">
                        <th className="th-h5" style={{width: "25%"}}>Total</th>
                        <th className="th-h5" style={{width: "25%"}}>{numeral(total.taruhan).format('0,000.0')}</th>
                        <th className="th-h5" style={{width: "25%"}}>{numeral(total.bayar).format('0,000.0')}</th>
                        <th className="th-h5" style={{width: "25%"}}>{numeral(total.winlose).format('0,000.0')}</th>
                    </tr>
                </table>
            </div>
    )
}

export default Invoice;