export default {
  title: process.env.REACT_APP_TITLE,
  logo: process.env.REACT_APP_LOGO,
  currency: process.env.REACT_APP_API_CURRENCY,
  baseUrl: process.env.REACT_APP_API_BASEURL,
  baseUIUrl: process.env.REACT_APP_BASEURL,
  socketUrl: process.env.REACT_APP_SOCKET_BASEURL,
  fireBaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  fireBaseClientEmail: process.env.REACT_APP_FIREBASE_CILENT_EMAIL,
  fireBaseClientId: process.env.REACT_APP_FIREBASE_CILENT_ID,
  fireBaseDatabaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  lang: 'en'
}