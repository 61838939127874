import React, { useState } from 'react';
import { Col } from 'reactstrap';
import D2Depan from './D2Depan';
import '../../games.css';

const data = [
    { label: 'BET DISKON', background: "#427486" },
    { label: 'BET BB', background: "#2E5F30" },
    { label: 'BET FULL', background: "#352D14" },
    { label: 'PRIZE123', background: "#B6621D" },
]

const TwoDDepan = ({setBetHistory, betHistory, market, periode, getReCeipt}) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0)
    const handleTabClick = (index) => {
        setBetHistory([])
        console.log(index)
        setActiveTabIndex(index)
    }
    function getBackgroundColor(bgrColor) {
        switch (bgrColor) {
            case 'Diskon':
                return '#427486'
            case 'BetBB':
                return '#2e5f30'
            case 'BetFull':
                return '#352d14'
            case 'Prize':
                return '#b6621d'
            default:
                return '#b6621d'
        }
    }
    return (
        <>{betHistory.length === 0 && <Col>
                    <div className="tab-overlow tab-overlow-2Depan">
                        <div className="tab">
                            <div className="tab-header" >
                                {data.map((i, ix) => (
                                    <div key={i.label} style={{ background: `${i.background}` }} className="tab-item font-10"
                                            onClick={() => handleTabClick(ix)}>
                                        {i.label}
                                    </div>
                                ))}
                            </div>
                            {activeTabIndex === 0 && <D2Depan getBackgroundColor={getBackgroundColor} getReCeipt={getReCeipt} betHistory={betHistory}
                                                                isDisCount={true} market={market} periode={periode} bgrColor={'Diskon'}/>}
                            {activeTabIndex === 1 && <D2Depan getBackgroundColor={getBackgroundColor} getReCeipt={getReCeipt} betHistory={betHistory}
                                                                betType={'BetBB'} market={market} periode={periode} bgrColor={'BetBB'}/>}
                            {activeTabIndex === 2 && <D2Depan getBackgroundColor={getBackgroundColor} getReCeipt={getReCeipt} betHistory={betHistory}
                                                                betType={'BetFull'} market={market} periode={periode} bgrColor={'BetFull'}/>}
                            {activeTabIndex === 3 && <D2Depan getBackgroundColor={getBackgroundColor} getReCeipt={getReCeipt} betHistory={betHistory}
                                                                betType={'Prize123'} market={market} periode={periode} bgrColor={'Prize'}/>}
                        </div>
                    </div>
                </Col>
            }
        </>
    )
}

export default TwoDDepan;
