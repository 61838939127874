import { Container, Row, Col } from 'reactstrap';
import http from "../../../../service/http";
import React, {useEffect, useState} from "react";
import {useDialog, useLoading} from "../../../../Component";
import LoadingUi from "../../../../Component/Loading/ui";

const init = [
    { tebakan: "0", amount: ''},
    { tebakan: "1", amount: '' },
    { tebakan: "2", amount: '' },
    { tebakan: "3", amount: '' },
    { tebakan: "4", amount: '' },
    { tebakan: "5", amount: '' },
    { tebakan: "6" , amount: ''},
    { tebakan: "7", amount: '' },
    { tebakan: "8", amount: '' },
    { tebakan: "9", amount: '' },
]
const ColokBebas = ({market, periode, getReCeipt, betHistory}) => {
    const [loading, withLoading] = useLoading()
    const [autobet, setAutoBet] = useState('')
    const [bebas, setBebas] = useState(init)
    const [Dialog, showDialogWithMessage] = useDialog({
        title: 'SUCCESS'
    })
    let localbets = []
    const runReceipt = () => {
        getReCeipt(localbets)
    }
    const [DialogError, showDialogErrorWithMessage] = useDialog({
        title: 'ERROR',
        type: 'error'
    })
    const fetchData = async () => {
        try {
            const response = await http.get({
                path: 'users/menang/' + market._id, params: {gameId: 19}
            })
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }
    const onChangeAutoBet = (e) => {
        if(e.target.value === '' || Number(e.target.value) > 0) {
            setBebas(bebas.map(b => {
                b.amount = e.target.value
                return b
            }))
            setAutoBet(e.target.value)
        }
    }
    const onChangeBetAmount = (e, i) => {
        if(e.target.value === '' || Number(e.target.value) > 0) {
            const _bebas = [...bebas]
            _bebas[i].amount = e.target.value
            setBebas(_bebas)
        }
    }
    const submit = async () => {
        setBebas([
            { tebakan: "0", amount: ''},
            { tebakan: "1", amount: '' },
            { tebakan: "2", amount: '' },
            { tebakan: "3", amount: '' },
            { tebakan: "4", amount: '' },
            { tebakan: "5", amount: '' },
            { tebakan: "6" , amount: ''},
            { tebakan: "7", amount: '' },
            { tebakan: "8", amount: '' },
            { tebakan: "9", amount: '' },
        ])
        const payload = {
            betTypes: [],
            period: periode.length > 0 ? periode[0].no + 1 : 1,
            code: market.code,
            pid: market._id,
            gameId: 19
        }

        bebas.filter(x => x.amount > 0).map(b => {
                payload.betTypes.push({
                    number: b.tebakan,
                    amount: b.amount * 1000
                })
        })

        try {
            if(payload.betTypes.length === 0) showDialogErrorWithMessage('No bet record !')
            else {
                const betrs = await withLoading(() => http.post({path: 'bet2', payload: JSON.stringify(payload)}))
                const txid = betrs.toPage.split('&proses=')
                const betH = await withLoading(() => http.get({path: 'users/bets/' + txid[1]}))
                if (betrs.message && betrs.message !== '') {
                    if (betH.length > 0) {
                        localbets = betH
                        showDialogErrorWithMessage(betrs.message, runReceipt)
                    }
                    else showDialogErrorWithMessage(betrs.message)
                } else {
                    getReCeipt(betH)
                }
            }
        } catch (error) {
            showDialogErrorWithMessage(error.message)

        }

    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <>{betHistory.length === 0 && <div>
            {loading && <LoadingUi/>}
                <Row>
                    <Col xs="12" lg="12">
                        <div className="tab-overlow">
                            <div className="tab">
                                <div className='tab-styled-box'>
                                    <div style={{padding: "12px 0"}}>
                                        <p className="tab-text font-12">Colok Bebas Bet Pannel IDR 1.000 = 1</p>
                                        <Col xs="10">
                                            <p style={{display: "flex", justifyContent: "right"}} className="tab-text font-12">Total Bet: 0</p>
                                        </Col>
                                    </div>
                                    <div>
                                        <div className="table-thead">

                                            <div className='col-left-CB'>
                                                <span className='col-Number-CB'>No.</span>
                                                <span className='col-Tebakan-CB'>Tebakan</span>
                                            </div>

                                            <div className='col-right-CB'>
                                                <p className='tab-text tab-text-taru-CB font-12'>Taruhan</p>
                                                <input className='table-input-none table-input-none-CB'
                                                       value={autobet} placeholder='auto bet' onChange={onChangeAutoBet} />
                                                <p className='tab-text tab-text-CB-1000'>x1000</p>
                                            </div>

                                        </div>
                                        {bebas.map((i, ix) => (
                                            <div className="table-item table-item-CB" key={ix}>
                                                <p className='tab-text tab-text-Number-CB'>{ix + 1}</p>
                                                <p className='tab-text tab-text-Tebakan-CB'>{i.tebakan}</p>
                                                    <input className='table-input table-input-CB' placeholder=''
                                                           value={bebas[ix].amount} onChange={(e) => onChangeBetAmount(e, ix)}/>
                                                    <p className='font-12 cb-Bebas-100'>X1000</p>                             
                                            </div>
                                        ))}
                                        <div className="table-group-btn">
                                            <button className="table-btn table-btn-BTN" onClick={submit}>Kirim</button>
                                            <button style={{ background: "#D0453B" }} className="table-btn table-btn-BTN">Hapus</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            <Dialog/>
            <DialogError/>
        </div>}
            </>
    )
}

export default ColokBebas;
