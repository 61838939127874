export default {
  DASHBOARD: '公告',
  TRANSACTIONS: '交易次数',
  DEPOSITS: '存款',
  WITHDRAW: '退款',
  TRANSFERS: '转账',
  BETTING_HISTORIES: '投注历史',
  REPORTS: '报告',
  BONUS: '奖金',
  BANKING: '银行',
  USER_MANAGEMENT: '用户管理',
  USERS: '用户',
  CREATE_PARTNER: '添加伙伴',
  CREATE_PLAYER: '添加玩家',
  CREATE_AGENT: '添加代理',
  CREATE_STAFF: '添加子帐号',
  POSITION_TAKING: '佔成',
  COMMISSIONS: '佣金',
  GAME_SETTING: '游戏设定',
  PROFILE: '个人资料',
  LOGOUT: '登出',
  SEARCH: '搜索',
  FROM: '从日期',
  TO: '到日期',
  ACTION: '行动',
  DATE: '日期',
  RECEIPT: '收据',
  STATUS: '状态',
  ACCOUNT_NO: '户口号码',
  ACCOUNT_NAME: '用户名',
  TO_BANK: '到银行',
  FROM_BANK: '从银行',
  CURRENCY: '货币',
  AMOUNT: '金额',
  USERNAME: '用户名',
  'NO.': '没有。',
  ACCEPT_DEPOSIT: '您的请求存款已被接受',
  REJECT_DEPOSIT: '您的请求存款被拒绝',
  DEPOSIT_REQUEST: '存款要求',
  ID: '编号',
  BANK_ACCOUNT_NAME: '银行账户名称',
  BANK_ACCOUNT_NO: '银行帐号',
  UPDATE: '更新资料',
  UPDATE_BY: '更新依据',
  APPROVE: '批准',
  ACCEPT: '接受',
  REJECT: '拒绝',
  SUCCESS: '成功',
  WARNING: '警告',
  FAIL: '失败',
  AGENT: '代理人',
  BETID: '投注编号',
  BET: '投注',
  WINLOSE: '输赢',
  WINAMOUNT: '中奖金额',
  JACKPOT: '头奖',
  CREATED: '已建立',
  LOADING: '载入中',
  TOTAL: '总额',
  TOTAL_BET: '总投注',
  TOTAL_WINLOSE: '总输赢',
  TOTAL_TURNOVER: '总营业额',
  BETTING_DETAIL: '投注详情',
  SELECT_AGENT: '选择代理',
  SELECT_GAME: '选择游戏',
  SALE: '销售',
  DOWNLINE: '下线',
  DOWNLINE_ACCOUNT: '下线帐户',
  TOTAL_DOWNLINE_WINLOSE: '总下线输赢',
  TURNOVER: '营业额',
  DOWNLINE_TURNOVER: '下线营业额',
  MEMBER_TURNOVER: '会员营业额',
  ADMIN: '管理员',
  PARTNER: '伙伴',
  PLAYER: '玩家',
  COMPANY_TOTAL: '公司合计',
  SHARE_PERCEN: '股份',
  COMM_PERCEN: '股份佣金',
  TOTAL_NET: '净利',
  NO_DATA: '无可用数据...',
  ADD: '添加',
  BANK_NAME: '银行名称',
  BANK_CONFIG: '银行配置',
  SUBMIT: '提交',
  SELECT_ROLE: '选择角色',
  PHONE: '电话',
  BALANCE: '结余',
  ROLE: '角色',
  ACTIVE: '有效帐户',
  INACTIVE: '无效帐户',
  ENTER_PASS: '输入密码',
  UPDATE_USER: '更新用户',
  SELECT_STATUS: '选择状态',
  UPDATE_USER_SUCCESS: '更新用户成功',
  YOU_REQUEST_SUBMITED_SUCCESS: '您的请求已成功提交',
  PLEASE_CONTACT_ADMIN: '请联系管理员',
  COMM_CANNOT_GREATE_THAN: '佣金不能大于上线',
  UPDATE_PERCEN: '更新百分数',
  CREATE_AGENT_SUCCESS: '创建代理成功',
  CREATE_PARTNER_SUCCESS: '成功创建合作伙伴',
  CREATE_PLAYER_SUCCESS: '创建玩家成功',
  CREATE_STAFF_SUCCESS: '成功创建员工',
  CREATE_AGENT_ERROR: '创建代理错误',
  CREATE_PARTNER_ERROR: '创建合作伙伴错误',
  CREATE_PLAYER_ERROR: '创建玩家错误',
  CREATE_STAFF_ERROR: '创建人员错误',
  ENTER_USERNAME: '输入用户名',
  ENTER_FULLNAME: '输入全名',
  ENTER_PHONE: '输入电话',
  CREATE_NOW: '立即建立',
  ERROR: '错误 !!!',
  USER_INFO: '用户信息',
  EMAIL: '电子邮件',
  OLD_PASS: '旧密码',
  IPAY_QR_CODE: '二维码',
  REGISTER_PARTNER: '注册合作伙伴',
  REGISTER_AGENT: '注册代理',
  REGISTER_PLAYER: '注册玩家',
  LOGIN: '登录',
  LOGIN_ERROR: '登录错误',
  NEW_PASS: '新密码',
  SETTINGS:'设定',
  PERCEN: '百分率',
  ACCOUNT: '帐户',
  LAST_LOGIN: '上次登录',
  TOTAL_PARTNER_ACTIVE_SUSPENDED: '伙伴（有效/已暂停）',
  TOTAL_AGENT_ACTIVE_SUSPENDED: '代理（有效/已暂停）',
  TOTAL_PLAYER_ACTIVE_SUSPENDED: '玩家（有效/已暂停）',
  ENTER_NEWWIN_TOKEN: '輸入Newwin代幣',
  ENTER_NEWWIN_URL: '輸入Newwin網址',
  ANNOUNCEMENT: '公告',
  GAME_MANAGEMENT: '遊戲管理',
  PROMOTION: '促销活动',
  SETTING: '设置',
  DOWNLINE_RECEIIVABLE: '应收下线',
  EARN_COMM: '赚取佣金',
  PAY_COMM: '支付佣金',
  UPLINE_PAYABLE: '应付上线',
  PLAYER_WINLOSE: '玩家输赢',
  SYNC_REAL_REPORT: '同步真实报告',
  FULL_REPORTS: '输赢报告',
  FULL_REPORTS_PAYABLE: '结算报告',
  PLAYER_COMM: '玩家佣金',
  PLAYER_TOTAL: '玩家总数',
  BARREL_REPORTS: '桶报告',
  EXPENSES: '费用',
  CONFIG_SHARE: '配置股份',
  CREATED_BY: '创建',
  UPDATED_AT: '更新成功',
  ENTER_SHARE_AMOUNT: '输入股份金额',
  PAYOUT: '支付',
  PROFIT: '利润',
  GROUP_TOTAL: '团体总计',
  GROUP_PROFIT: '团体利润',
  GROUP_MEMBER: '团体成员'
}