import http from '../../../../../../service/http';
import '../../../../games.css'
import React, {useState} from 'react';
import numeral from 'numeral'
import {useDialog, useLoading} from '../../../../../../Component'
import LoadingUi from '../../../../../../Component/Loading/ui'
import {Col, Table} from 'reactstrap';
const numbers = {
    Besar: ["50", "51", "52", "53", "54", "55", "56", "57", "58", "59", "60", "61", "62", "63", "64", "65", "66", "67", "68", "69", "70", "71", "72", "73", "74", "75", "76", "77", "78", "79", "80", "81", "82", "83", "84", "85", "86", "87", "88", "89", "90", "91", "92", "93", "94", "95", "96", "97", "98", "99"],
    Kecil: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49"],
    Ganjil: ["01", "03", "05", "07", "09", "11", "13", "15", "17", "19", "21", "23", "25", "27", "29", "31", "33", "35", "37", "39", "41", "43", "45", "47", "49", "51", "53", "55", "57", "59", "61", "63", "65", "67", "69", "71", "73", "75", "77", "79", "81", "83", "85", "87", "89", "91", "93", "95", "97", "99"],
    Genap: ["00", "02", "04", "06", "08", "10", "12", "14", "16", "18", "20", "22", "24", "26", "28", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "60", "62", "64", "66", "68", "70", "72", "74", "76", "78", "80", "82", "84", "86", "88", "90", "92", "94", "96", "98"]
}
const BETDISKONTab = ({getBackgroundColor, getReCeipt, bgrColor, isDisCount, betType, market, periode}) => {
    const [loading, withLoading] = useLoading()
    const [betData, setBetData] = useState({
        betTypes: '2D Belakang',
        tebak: 'Besar',
        amount: ''
    })
    const [Dialog, showDialogWithMessage] = useDialog({
        title: 'SUCCESS'
    })
    const [DialogError, showDialogErrorWithMessage] = useDialog({
        title: 'ERROR',
        type: 'error'
    })
    let localbets = []
    const runReceipt = () => {
        getReCeipt(localbets)
    }
    const submit = async () => {
        const betTypesSum = {}
        const payload = {
            betTypes: [],
            period: periode.length > 0 ? periode[0].no + 1 : 1,
            code: market.code,
            betType: betData.betTypes,
            pid: market._id
        }

        if(betData.amount > 0) numbers[betData.tebak].map(n => {
            const betTypes = (isDisCount ? '' : betType + '-') + betData.betTypes
            const id_game =  betData.betTypes ===  '2D Belakang' ? 3 : (betData.betTypes === '2D Depan' ? 4 : 5)
            console.log(id_game, betData)
            payload.betTypes.push({
                number: n,
                betTypes,
                id_game,
                amount: betData.amount * 1000
            })
            // if(!betTypesSum[betTypes]) betTypesSum[betTypes] = {}
            // if(!betTypesSum[betTypes][n]) betTypesSum[betTypes][n] = 0
            // betTypesSum[betTypes][n] += betData.amount * 1000
        })

        try {
            if(payload.betTypes.length > 0) {
                // payload.betTypesSum = betTypesSum
                const betrs = await withLoading(() => http.post({path: 'bet', payload: JSON.stringify(payload)}))
                const txid = betrs.toPage.split('&proses=')
                const betH = await withLoading(() => http.get({path: 'users/bets/' + txid[1]}))
                if (betrs.message && betrs.message !== '') {
                    if (betH.length > 0) {
                        localbets = betH
                        showDialogErrorWithMessage(betrs.message, runReceipt)
                    }
                    else showDialogErrorWithMessage(betrs.message)
                }
                else {
                    // showDialogWithMessage('YOU_REQUEST_SUBMITED_SUCCESS')
                    if (betH.length > 0) getReCeipt(betH)
                    else showDialogErrorWithMessage('No bet accepted')
                }
            }
        } catch (error) {
            showDialogErrorWithMessage(error.message)
        }
    }
    const onchangeAmount = (e) => {
        let value = Number(e.target.value)
        if (e.target.value === '' || value > 0) {
            setBetData({...betData, amount: e.target.value})
        }
    }
    return (
        <Col xs="12" lg="12">
            {loading && <LoadingUi/>}
            <div className="tab-body-4D-432D" style={{backgroundColor: getBackgroundColor(bgrColor)}}>
                <div style={{textAlign: 'center'}}>
                    <p className="">Quick 2D Bet Pannel IDR 1.000 = 1</p>
                </div>
                <div style={{textAlign: 'right'}}>
                    <p className="">Total Bet: {numeral(betData.amount * 50000).format('0,0')}</p>
                </div>
                <Table className={'table-4d'}>
                    <thead>
                    <tr>
                        <th>Posisi</th>
                        <th>Tebakan</th>
                        <th>Taruhan (x1000)</th>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <select className="table-select d2-quickbet font-10"
                                    onChange={(e) => setBetData({...betData, betTypes: e.target.value})}>
                                <option value={'2D Belakang'}>2D Belakang</option>
                                <option value={'2D Depan'}>2D Depan</option>
                                <option value={'2D Tengah'}>2D Tengah</option>
                            </select>
                        </td>
                        <td>
                            <select className="table-select d2-quickbet font-10"
                                    onChange={(e) => setBetData({...betData, tebak: e.target.value})}>
                                <option value={'Besar'}>Besar</option>
                                <option value={'Kecil'}>Kecil</option>
                                <option value={'Ganjil'}>Ganjil</option>
                                <option value={'Genap'}>Genap</option>
                            </select>
                        </td>
                        <td>
                            <input className="table-input d2-quickbet"
                                   value={betData.amount}
                                   type="number"
                                   inputMode="numeric"
                                   onChange={(e) => onchangeAmount(e)}/>
                        </td>
                        <td>
                            <button onClick={submit} className='button-Submit-4D d2-quickbet'>Submit</button>
                        </td>
                    </tr>
                    </tbody>
                </Table>

                <Dialog/>
                <DialogError/>
            </div>
        </Col>
    )
}
export default BETDISKONTab