import '../../index.css'

const data = [
    {
        level: 'Deposit',
        num01: '0 - 499,999',
        numb02: '500, 000 - 2,999,999',
        numb03: '3,000,000 - 9,999,999',
        numb04: '10,000,000 - 15,000,000'
    },
    {
        level: '4D',
        num01: '66%',
        numb02: '66%',
        numb03: '66%',
        numb04: '66%'
    },
    {
        level: '3D',
        num01: '59%',
        numb02: '59%',
        numb03: '59%',
        numb04: '59%'
    },
    {
        level: '2D',
        num01: '29%',
        numb02: '29%',
        numb03: '29%',
        numb04: '29%'
    },
    {
        level: '2D Depan',
        num01: '29%',
        numb02: '29%',
        numb03: '29%',
        numb04: '29%'
    },
    {
        level: '2D Tengah',
        num01: '29%',
        numb02: '29%',
        numb03: '29%',
        numb04: '29%'
    },
    {
        level: 'Colok Bebas',
        num01: '5%',
        numb02: '5%',
        numb03: '5%',
        numb04: '5%'
    },
    {
        level: 'Macau',
        num01: '15%',
        numb02: '15%',
        numb03: '15%',
        numb04: '15%'
    },
    {
        level: 'Colok Naga',
        num01: '15%',
        numb02: '15%',
        numb03: '15%',
        numb04: '15%'
    },
    {
        level: 'Colok Jitu',
        num01: '7%',
        numb02: '7%',
        numb03: '7%',
        numb04: '7%'
    },
    {
        level: 'Tengah',
        num01: '29%',
        numb02: '0%',
        numb03: '0%',
        numb04: '0%'
    },
    {
        level: 'Dasar',
        num01: '0%',
        numb02: '0%',
        numb03: '0%',
        numb04: '0%'
    },
    {
        level: '50-50',
        num01: '2%',
        numb02: '2%',
        numb03: '2%',
        numb04: '2%'
    },
    {
        level: 'Shio',
        num01: '8%',
        numb02: '8%',
        numb03: '8%',
        numb04: '8%'
    },
    {
        level: 'Shilang',
        num01: '0%',
        numb02: '0%',
        numb03: '0%',
        numb04: '0%'
    },
    {
        level: 'Kembang',
        num01: '0%',
        numb02: '0%',
        numb03: '0%',
        numb04: '0%'
    },
    {
        level: 'Komisabi',
        num01: '8%',
        numb02: '8%',
        numb03: '8%',
        numb04: '8%'
    },

]
function PromoDiskon() {
    return (
        <div style={{marginTop: "5%", padding: "2%"}}>

            <table style={{color: "black"}} className="table-h5">
                <tr className='dashboard-PD-row01'>
                    <th className="th-h5">LEVEL</th>
                    <th className="th-h5">0</th>
                    <th className="th-h5">1</th>
                    <th className="th-h5">2</th>
                    <th className="th-h5">3</th>
                </tr>

                    {data.map((item, index) => (
                        <tr key={index}>
                            <td className="td-h5 dashboard-PD-Col01" style={{width: "20%"}}>{item.level}</td>
                            <td className="td-h5" style={{width: "20%"}}>{item.num01}</td>
                            <td className="td-h5" style={{width: "20%"}}>{item.numb02}</td>
                            <td className="td-h5" style={{width: "20%"}}>{item.numb03}</td>
                            <td className="td-h5" style={{width: "20%"}}>{item.numb04}</td>
                        </tr>
                    ))}
            </table>
        </div>
    )
}

export default PromoDiskon