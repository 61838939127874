import React from 'react'
import { FormControl, TextField } from '@material-ui/core'

export default ({ input: { input, meta }, ...rest }) => {
  // console.log(meta)
  return (<FormControl margin="normal" required fullWidth={rest.fullWidth}>
    <TextField {...input} {...rest} />
    {meta.touched && meta.error && (
      <div style={{ color: '#f50057' }}>{meta.error}</div>
    )}
  </FormControl>)
}
