
const data = [ 
    {   
        num: 1,
        tebak: "Monyet",
        amount: 5.000,
        disc: 4000,
        total: 4.600
    },
    {
        num: 2,
        tebak: "Kambing",
        amount: 5.000,
        disc: 4000,
        total: 4.600
    },
    {
        num: 3,
        tebak: "Kuda",
        amount: 5.000,
        disc: 4000,
        total: 4.600
    },
    {
        num: 4,
        tebak: "Naga",
        amount: 5.000,
        disc: 4000,
        total: 4.600
    },
    {
        num: 5,
        tebak: "Ular",
        amount: 5.000,
        disc: 4000,
        total: 4.600
    },
    {
        num: 6,
        tebak: "Kelinci",
        amount: 5.000,
        disc: 4000,
        total: 4.600
    },
    {
        num: 7,
        tebak: "Harimau",
        amount: 5.000,
        disc: 4000,
        total: 4.600
    },
    {
        num: 8,
        tebak: "Kerbau",
        amount: 5.000,
        disc: 4000,
        total: 4.600
    },
    {
        num: 9,
        tebak: "Tikus",
        amount: 5.000,
        disc: 4000,
        total: 4.600
    },
    {
        num: 10,
        tebak: "Anjing",
        amount: 5.000,
        disc: 4000,
        total: 4.600
    }

]
function Shio() {

    
    const totals = {
        amount: data.reduce((acc, curr) => acc + curr.amount, 0),
        disc: data.reduce((acc, curr) => acc + curr.disc, 0),
        total: data.reduce((acc, curr) => acc + curr.total, 0)
    };
    return (
        <div >

                <table style={{color: "black"}} className="table-h5 f10">
                    <tr className="limit-tr01">
                        <th style={{width: "5%"}} className="th-h5">No</th>
                        <th style={{width: "35%", height: "50px"}} className="th-h5">Tebak</th>
                        <th style={{width: "22.5%"}} className="th-h5">Amount</th>
                        <th style={{width: "15%"}} className="th-h5">Disc(%)</th>
                        <th style={{width: "22.5%"}} className="th-h5">Total</th>
                    </tr>

                    {data.map((item, index) => (
                        <tr key={index}>
                            <td className="td-h5">{item.num}</td>
                            <td style={{height: "50px"}} className="td-h5 shio-col02">{item.tebak}</td>                
                            <td className="td-h5">{item.amount}</td>
                            <td className="td-h5">{item.disc}</td>
                            <td className="td-h5">{item.total}</td>
                        </tr>
                    ))}

                    <tr>
                        <td className="td-h5"></td>
                        <td className="td-h5 fontWeight1000">Total</td>
                        <td className="td-h5 fontWeight1000">{totals.amount}</td>
                        <td className="td-h5 fontWeight1000">{totals.disc}</td>
                        <td className="td-h5 fontWeight1000">{totals.total}</td>
                    </tr>

                </table>

        </div>
    )
}

export default Shio