
import '../../games.css'
import React, {useState} from "react";
import {useDialog, useLoading} from "../../../../Component";
import http from "../../../../service/http";
import LoadingUi from "../../../../Component/Loading/ui";
import numeral from "numeral";

const Tengah = ({market, periode, betHistory, getReCeipt}) => {
    const init = [
        { tebakan: "Tepi", amount: '' },
        { tebakan: "Tengah", amount: '' },
    ]
    const [totalBet, setTotalBet] = useState(0)
    const [bets, setBets] = useState(init)
    const [loading, withLoading] = useLoading()
    const [DialogError, showDialogErrorWithMessage] = useDialog({
        title: 'ERROR',
        type: 'error'
    })
    const calTotalBet = (data) => {
        const arr = data.filter(x => x.tebakan !== '')

        console.log(arr)
        if(arr.length > 0) setTotalBet(arr.reduce((a, b) => {
            return {
                amount: Number(a.amount) + Number(b.amount)
            }
        }).amount)
    }
    const onChangeAmount = (e, index) => {
        const value = Number(e.target.value)
        if((value >= 0) || e.target.value === '') {
            const _bets = [...bets]
            // console.log(e.target.value, numbers, numbers.includes(e.target.value))
            console.log(_bets)
            _bets[index].amount = e.target.value
            calTotalBet(_bets)
            setBets(_bets)
        }
    }

    console.log(bets)
    const submit = async () => {
        setBets(init)
        const payload = {
            betTypes: [],
            period: periode.length > 0 ? periode[0].no + 1 : 1,
            code: market.code,
            pid: market._id,
            gameId: 23
        }

        bets.filter(x => x.tebakan !== '' && x.amount > 0).map(b => {
            payload.betTypes.push({
                number: b.tebakan,
                amount: b.amount * 1000,
                posisi: b.tebakan
            })
        })

        try {
            if(payload.betTypes.length === 0) showDialogErrorWithMessage('No bet record !')
            else {
                const betrs = await withLoading(() => http.post({path: 'bet3', payload: JSON.stringify(payload)}))
                if (betrs.message && betrs.message !== '') {
                    showDialogErrorWithMessage(betrs.message)
                    if (betrs.toPage) {
                        const txid = betrs.toPage.split('&proses=')
                        if (txid[1]) {
                            const betH = await withLoading(() => http.get({path: 'users/bets/' + txid[1]}))
                            if(betH.length > 0)getReCeipt(betH)
                            else showDialogErrorWithMessage('No Bet accepted')
                        }
                    }
                } else {
                    const txid = betrs.toPage.split('&proses=')
                    const betH = await withLoading(() => http.get({path: 'users/bets/' + txid[1]}))
                    getReCeipt(betH)
                }
            }
        } catch (error) {
            showDialogErrorWithMessage(error.message)

        }

    }
    return (
        <>{betHistory.length === 0 && <div>
            {loading && <LoadingUi/>}
            <div className="tab-overlow">
                <div className="tab">
                    <div className='tab-styled-box'>

                        <div>
                            <p className="tab-title font-10">Tengah Bet Pannel IDR 1.000 = 1</p>
                            <p style={{marginRight: 10}} className="tab-title tab-title-Total font-10">Total Bet: {numeral(totalBet * 1000).format('0,0')}</p>
                        </div>

                        <table className='table'>
                            <tr className="table-thead table-thead-Tengah font-10">
                                <th className='col-01-Tengah'>Tebakan</th>
                                <th className='col-02-Tengah'>Taruhan</th>
                                <th></th>
                            </tr>
                            {bets.map((i, ix) => (
                                <tr className="table-item table-item-TT">
                                    <td className='tab-text col-01-Tengah font-10'>{i.tebakan}</td>
                                    <td className="table-display col-02-Tengah-down">
                                        <input className='table-input table-input-Tengah font-10' placeholder=''
                                               inputMode="numeric"
                                               value={i.amount}
                                               onChange={(e) => onChangeAmount(e, ix)}/>
                                    </td>
                                    <td><p className='tab-text numTengah font-10'>X1000</p></td>
                                </tr>
                            ))}
                            <div className="table-group-btn">
                                <button className="table-btn table-btn-BTN" onClick={submit}>Kirim</button>
                                <button style={{ background: "#D0453B" }} className="table-btn table-btn-BTN">Hapus</button>
                            </div>    
                        </table>
                    </div>
                </div>
            </div>
            <DialogError/>
        </div>}
            </>
    )
}

export default Tengah