import React, {useEffect, useState} from "react";
import {Row, Col, Table} from 'reactstrap';
import '../../games.css'

// Quick 2D
// import BETDISKONTab from "./components/BETDISKONTab";
import BETDISKONTab from "./components/Quick2D";

// Tab Quick Bet
import BETDISKONTab_BQ from "./components/QuickBet/BETDISKONTabQB";

// Tab 4D,3D,2D

import BETBBTab_432D from "./components/4D3D2D/BETBBTab432D";

// BB Compuran
import BETDISKONTab_BB from "./components/BB_Compuran/BETDISKONTab_BB";

import numeral from "numeral";
import http from "../../../../service/http";
import {useDialog} from "../../../../Component";

const data = [
    { label: '4D, 3D, 2D' },
    { label: 'Quick 2D' },
    { label: 'Quick Bet' },
    { label: 'BB Compuran' },
]

const dataTab = [
    { label: 'BET DISKON', background: "#427486" },
    { label: 'BET BB', background: "#2E5F30" },
    { label: 'BET FULL', background: "#352D14" },
    { label: 'PRIZE123', background: "#B6621D" },
]
const FourD = ({market, periode, getReCeipt, betHistory, setBetHistory, selectGame, setSelectGame, games}) => {
    // console.log(selectGame)
    // const [showX, setShowX] = useState(true);

    const [activeTab_D43D2D, setActiveaBettingD43D2D] = useState(0)
    const [activeBetting, setActiveaBetting] = useState(0)
    const [activeTab_QB, setActiveaBettingQB] = useState(0)
    const [activeTab_BB, setActiveTab_BB] = useState(0)
    const [activeTabIndex, setActiveTabIndex] = useState(0); // State để lưu index của tab đang được chọn
    const [user, setUser] = useState({})
    const [gamesConfig, setGameConfig] = useState({games: {}})

    const [DialogError, showDialogErrorWithMessage] = useDialog({
        title: 'ERROR',
        type: 'error'
    })
    const permutator = (arr) => {
        let resultArr = [];
        if(arr.length === 0) return [];
        if(arr.length ===1) return [arr];

        for(let i =0 ; i<arr.length ; i++) {
            const currentElement = arr[i];

            const otherElements = arr.slice(0,i).concat(arr.slice(i+1));
            const swappedPermutation = permutator(otherElements);

            for(let j =0 ; j < swappedPermutation.length ; j++)
            {
                const finalSwappedPermutation =
                    [currentElement].concat(swappedPermutation[j]);

                resultArr.push(finalSwappedPermutation);
            }
        }
        return resultArr // [...new Set(resultArr.map(r => r.toString().replaceAll(',', '')))];
    }
    const allEqual = arr => arr.every( v => v === arr[0] )
    const _arrayCreateQuickbet = (array, size) => {
        // console.log(size, array, array.slice(array.length-size), allEqual(array.slice(array.length-size)))
        if(allEqual(array)) return [array.slice(0, size)]
        else if(array.length == size) return permutator(array)
        else if(size == 3 && allEqual(array.slice(0, size))) {
            return permutator(array.slice(array.length-size)).concat([array.slice(0, size)])
        }
        else if(size == 2 && allEqual(array.slice(0, 3))) {
            const rs = permutator(array.slice(2, 4))
            rs.push(array.slice(0, 2))
            return rs
        }
        else if(allEqual(array.slice(0, 2)) && allEqual(array.slice(2, 4))) {
            if(size == 2) {
                const rs = permutator(array.slice(1, 3))
                rs.push(array.slice(0, 2))
                rs.push(array.slice(2, 4))
                return rs
            } else if(array.length <= 4) return permutator(array.slice(0, 3)).concat(permutator(array.slice(1)))
            return permutator(array)
        }
        else if(allEqual([array[0], array[2]]) && allEqual([array[1], array[3]])) {
            if(size == 2) {
                const rs = permutator(array.slice(1, 3))
                rs.push([array[0], array[2]])
                rs.push([array[1], array[3]])
                return rs
            }
            return permutator(array.slice(0, 3)).concat(permutator(array.slice(1)))
        }
        else if(allEqual([array[0], array[3]]) && allEqual([array[1], array[2]])) {
            if(size == 2) {
                const rs = permutator(array.slice(0, 2))
                rs.push([array[0], array[3]])
                rs.push([array[1], array[2]])
                return rs
            }
            return permutator(array.slice(0, 3)).concat(permutator([array[0], array[1], array[3]]))
        }
        else if(array.length <= 4 && allEqual([array[1], array[2]])) {
            if(size == 2) {
                const rs = array.length == 4 ? permutator(array.slice(0, 2)).concat(permutator(array.slice(2, 4))) : [[array[0], array[1]], [array[0], array[2]]]
                rs.push([array[1], array[2]])
                return rs
            }
            return permutator(array.slice(array.length-size)).concat([array.slice(0, size)])
        }
        else if(array.length <= 4 && allEqual(array.slice(array.length-size))) {
            return permutator(array.slice(0, size)).concat([array.slice(array.length-size)])
        }


        var result = [];
        array.forEach(function iter(parts) {
            return function (v) {
                var temp = parts.concat(v);
                // if(size === 4) console.log(temp)
                if (parts.includes(v)) {
                    return;
                }
                if (temp.length === size) {

                    result.push(temp);
                    return;
                }
                array.forEach(iter(temp));
            }
        }([]));
// console.log(array, result, size)
        return result;
    }
    const arrayCreateQuickbet = (array, size) => {
        const rs = _arrayCreateQuickbet(array, size)
        // console.log(array, size, rs)
        const rss = []
        rs.map(r => {
            rss.push(r.slice(0, size))
            if(r.length > size * 2) rss.push(r.slice(size, size * 2))
            if(r.length > size * 3) rss.push(r.slice(size*2, size * 3))
        })
        return [...new Set(rss.map(r => r.toString().replaceAll(',', '')))]
    }
    function getBackgroundColor(bgrColor) {
        switch (bgrColor) {
            case 'Diskon':
                return '#427486';
            case 'BetBB':
                return '#2e5f30';
            case 'BetFull':
                return '#352d14';
            case 'Prize':
                return '#b6621d';
            default:
                return '#b6621d';
        }
    }
    const handleTabClick = (index) => {
        setBetHistory([])
        let disableGames = ''
        // console.log(gamesConfig)
        if(gamesConfig.games) {
            if (!gamesConfig.games['4D']) disableGames += '4D'

            if (!gamesConfig.games['3D']) disableGames += ' 3D'
            if (!gamesConfig.games['2D']) disableGames += ' 2D'
        }
        if ((disableGames !== '' && [0].includes(index)) || (disableGames.includes('2D') && index === 1)) showDialogErrorWithMessage(disableGames + ' locked, please contact upline')
        else {
            setActiveTabIndex(index); // Cập nhật index của tab đang được chọn
            setSelectGame(index)
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const me = await http.get({path: 'me'})
                const response = await http.get({
                    path: 'users/menang/' + market._id,
                })
                let disableGames = ''
                const gamesConfig = response.find(x => x.ket === 'games')
                if(gamesConfig) {
                    if (!gamesConfig.games['4D']) disableGames += '4D'

                    if (!gamesConfig.games['3D']) disableGames += ' 3D'
                    if (!gamesConfig.games['2D']) disableGames += ' 2D'
                }
                if (disableGames !== '') showDialogErrorWithMessage(disableGames + ' locked, please contact upline')
                setGameConfig(gamesConfig)
                setUser(me)

            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        fetchData()
    }, [])
    return (
        <div className="fourd-wrapper">
            <Row className="content">
                <div className="games-tab  anDi">
                    <h1  style={{margin: 20, fontSize: 30}} className="games-title">Betting Method</h1>
                    <div className="games-tab-list">
                        {data.map((item, index) => (
                            <div
                                className={activeTabIndex === index ? 'games-tab-item-active' : 'games-tab-item'}
                                onClick={() => handleTabClick(index)}
                                key={index}
                                style={{ background: activeTabIndex === index ? '#FDD122' : 'transparent' }}
                            >
                                {item.label}
                            </div>
                        ))}
                    </div>
                </div>
           
                {betHistory.length === 0 && selectGame === 0 && (
                    <div style={{backgroundColor: "transparent"}}>
                        <div className="tab tab-D4">
                            <div className="tab-header">
                                {dataTab.map((i, ix) => (
                                    <div style={{ background: `${i.background}` }} onClick={() => setActiveaBettingD43D2D(ix)} className="tab-item tab-item-4D" key={ix}>
                                        {i.label}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="tab-overlow">
                            <Col xs="12" lg="12">                                    
                                {activeTab_D43D2D === 0 && (<BETBBTab_432D getBackgroundColor={getBackgroundColor} getReCeipt={getReCeipt} bgrColor={'Diskon'} isDisCount={true} arrayCreateQuickbet={arrayCreateQuickbet} market={market} periode={periode} gamesConfig={gamesConfig}/>)}
                                {activeTab_D43D2D === 1 && (<BETBBTab_432D getBackgroundColor={getBackgroundColor} getReCeipt={getReCeipt} bgrColor={'BetBB'} betType={'BetBB'} arrayCreateQuickbet={arrayCreateQuickbet} market={market} periode={periode} gamesConfig={gamesConfig}/>)}
                                {activeTab_D43D2D === 2 && (<BETBBTab_432D getBackgroundColor={getBackgroundColor} getReCeipt={getReCeipt} bgrColor={'BetFull'} betType={'BetFull'} arrayCreateQuickbet={arrayCreateQuickbet} market={market} periode={periode} gamesConfig={gamesConfig}/>)}
                                {activeTab_D43D2D === 3 && (<BETBBTab_432D getBackgroundColor={getBackgroundColor} getReCeipt={getReCeipt} bgrColor={'Prize'} betType={'Prize123'} arrayCreateQuickbet={arrayCreateQuickbet} market={market} periode={periode} gamesConfig={gamesConfig}/>)}
                            </Col>
                        </div>
                    </div>
                )}

                {betHistory.length === 0 && selectGame === 1 && (
                    <Col>
                        <div className="tab-overlow">
                            <div className="tab tab-D4">
                                <div className="tab-header">
                                    {dataTab.map((i, ix) => (
                                        <div style={{background: `${i.background}`}}
                                             onClick={() => setActiveaBetting(ix)} className="tab-item tab-item-4D"
                                             key={ix}>
                                            {i.label}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="tab-overlow">
                                <Col xs="12" lg="12">
                                    {activeBetting === 0 && (
                                        <BETDISKONTab getBackgroundColor={getBackgroundColor} getReCeipt={getReCeipt}
                                                      bgrColor={'Diskon'} isDisCount={true} market={market}
                                                      periode={periode} gamesConfig={gamesConfig}/>)}
                                    {activeBetting === 1 && (
                                        <BETDISKONTab getBackgroundColor={getBackgroundColor} getReCeipt={getReCeipt}
                                                      bgrColor={'BetBB'} betType={'BetBB'} market={market}
                                                      periode={periode} gamesConfig={gamesConfig}/>)}
                                    {activeBetting === 2 && (
                                        <BETDISKONTab getBackgroundColor={getBackgroundColor} getReCeipt={getReCeipt}
                                                      bgrColor={'BetFull'} betType={'BetFull'} market={market}
                                                      periode={periode} gamesConfig={gamesConfig}/>)}
                                    {activeBetting === 3 && (
                                        <BETDISKONTab getBackgroundColor={getBackgroundColor} getReCeipt={getReCeipt}
                                                      bgrColor={'Prize'} betType={'Prize123'} market={market}
                                                      periode={periode} gamesConfig={gamesConfig}/>)}
                                </Col>
                            </div>
                        </div>
                    </Col>
                )}
          
                {betHistory.length === 0 && selectGame === 2 && (
                    <Col>
                        <div className="tab-overlow">
                            <div className="tab">
                                <div className="tab-header">
                                    {dataTab.map((i, ix) => (
                                        <div style={{ background: `${i.background}` }} onClick={() => setActiveaBettingQB(ix)} className="tab-item tab-item-4D" key={ix}>
                                            {i.label}
                                        </div>
                                    ))}
                                </div>
                                <Col xs="12" lg="12">  
                                    {activeTab_QB === 0 && (<BETDISKONTab_BQ getBackgroundColor={getBackgroundColor} getReCeipt={getReCeipt}
                                                                             bgrColor={'Diskon'} isDisCount={true} arrayCreateQuickbet={arrayCreateQuickbet} market={market} periode={periode} gamesConfig={gamesConfig}/>)}
                                    {activeTab_QB === 1 && (<BETDISKONTab_BQ getBackgroundColor={getBackgroundColor} getReCeipt={getReCeipt}
                                                                         bgrColor={'BetBB'} betType={'BetBB'} arrayCreateQuickbet={arrayCreateQuickbet} market={market} periode={periode} gamesConfig={gamesConfig}/>)}
                                    {activeTab_QB === 2 && (<BETDISKONTab_BQ getBackgroundColor={getBackgroundColor} getReCeipt={getReCeipt}
                                                                           bgrColor={'BetFull'} betType={'BetFull'} arrayCreateQuickbet={arrayCreateQuickbet} market={market} periode={periode} gamesConfig={gamesConfig}/>)}
                                    {activeTab_QB === 3 && (<BETDISKONTab_BQ getBackgroundColor={getBackgroundColor} getReCeipt={getReCeipt}
                                                                            bgrColor={'Prize'} betType={'Prize123'} arrayCreateQuickbet={arrayCreateQuickbet} market={market} periode={periode} gamesConfig={gamesConfig}/>)}
                                </Col>
                            </div>
                        </div>
                    </Col>
                )}

                {betHistory.length === 0 && selectGame === 3 && (
                    <div className="tab-overlow">
                        <div className="tab">
                            <div className="tab-header">
                                {dataTab.map((i, ix) => (
                                    <div style={{ background: `${i.background}` }} onClick={() => setActiveTab_BB(ix)} className="tab-item tab-item-4D" key={ix}>
                                        {i.label}
                                    </div>
                                ))}
                            </div>                           
                                <Col xs="12" lg="12">        
                                    {activeTab_BB === 0 && (<BETDISKONTab_BB getBackgroundColor={getBackgroundColor} getReCeipt={getReCeipt} bgrColor={'Diskon'} isDisCount={true} arrayCreateQuickbet={arrayCreateQuickbet} market={market} periode={periode} gamesConfig={gamesConfig}/>)}
                                    {activeTab_BB === 1 && (<BETDISKONTab_BB getBackgroundColor={getBackgroundColor} getReCeipt={getReCeipt} bgrColor={'BetBB'} betType={'BetBB'} arrayCreateQuickbet={arrayCreateQuickbet} market={market} periode={periode} gamesConfig={gamesConfig}/>)}
                                    {activeTab_BB === 2 && (<BETDISKONTab_BB getBackgroundColor={getBackgroundColor} getReCeipt={getReCeipt} bgrColor={'BetFull'} betType={'BetFull'} arrayCreateQuickbet={arrayCreateQuickbet} market={market} periode={periode} gamesConfig={gamesConfig}/>)}
                                    {activeTab_BB === 3 && (<BETDISKONTab_BB getBackgroundColor={getBackgroundColor} getReCeipt={getReCeipt} bgrColor={'Prize'} betType={'Prize123'} arrayCreateQuickbet={arrayCreateQuickbet} market={market} periode={periode} gamesConfig={gamesConfig}/>)}
                                </Col>
                        </div>
                    </div>
                )}

            </Row>
            <DialogError/>
        </div>
    )
}

export default FourD