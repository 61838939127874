import { useState } from "react"
import '../index.css'
import Info from './InforUmum'
import Limit from './LimitBet'
import Promo from './Promo&Diskon'
import Kemen from './KemenAngan'
import Game from './Game'

const data = [
    {label: 'INFOR UMUM'},
    {label: 'LIMIT BET'},
    {label: 'PROMO & DISKON'},
    {label: 'KEMENANGAN'},
    {label: 'GAME'},
]
function DashBorad() {

    const [showInfo, setShowInfo] = useState(true)
    const [showLimitBet, setShowLimitBet] = useState(false)
    const [showPromo, setShowPromo] = useState(false)
    const [showGame, setShowGame] = useState(false)
    const [showKemenangan, setShowKemenangan] = useState()
    const [showIndex, setShowIndex] = useState(0)

    const handleAction = (index) => {
        
        setShowIndex(index)
        const tabStateMap = {
            'INFOR UMUM': {showInfo: true, showLimitBet: false, showPromo: false, showKemenangan: false, showGame: false},
            'LIMIT BET': {showInfo: false, showLimitBet: true, showPromo: false, showKemenangan: false, showGame: false},
            'PROMO & DISKON': {showInfo: false, showLimitBet: false, showPromo: true, showKemenangan: false, showGame: false},
            'KEMENANGAN': {showInfo: false, showLimitBet: false, showPromo: false, showKemenangan: true, showGame: false},
            'GAME': {showInfo: false, showLimitBet: false, showPromo: false, showKemenangan: false, showGame: true},
        }
        const { showInfo, showGame, showLimitBet, showKemenangan, showPromo } = tabStateMap[data[index]?.label || 'default']
            setShowGame(showGame)
            setShowLimitBet(showLimitBet)
            setShowInfo(showInfo)
            setShowKemenangan(showKemenangan)
            setShowPromo(showPromo)

    }

    return (
        <div className="dashboard-bg">
            <p className="dashboard-title" style={{color: "black"}}>DASHBOARD</p>

            <div className="dashboard-list-tab">
                {data.map((item, index) => (
                    <div  onClick={() => handleAction(index)} key={index}>
                        <button className='dashboard-item' style={{ background: showIndex === index ? 'red' : 'gray', color: showIndex === index ? 'white' : 'black'}}>{item.label}</button>   
                    </div>
                ))}
            </div>

            <div>{showInfo && <Info />}</div>

            <div>{showLimitBet && <Limit />}</div>

            <div>{showPromo && <Promo />}</div>

            <div>{showKemenangan && <Kemen />}</div>

            <div>{showGame && <Game />}</div>

        </div>
    )
}

export default DashBorad