export default {
  DASHBOARD: 'แผงควบคุม',
  TRANSACTIONS: 'ประวัติการทำธุรกรรม',
  DEPOSITS: 'ฝาก',
  WITHDRAW: 'ถอน',
  TRANSFERS: 'โอน',
  BETTING_HISTORIES: 'ประวัติการเดิมพัน',
  REPORTS: 'รายงาน',
  BONUS: 'โบนัส',
  BANKING: 'การธนาคาร',
  USER_MANAGEMENT: 'การจัดการผู้ใช้',
  USERS: 'ผู้ใช้',
  CREATE_PARTNER: 'สร้างพันธมิตร',
  CREATE_PLAYER: 'สร้างผู้เล่น',
  CREATE_AGENT: 'สร้างตัวแทน',
  CREATE_STAFF: 'สร้างพนักงาน',
  POSITION_TAKING: 'การรับตำแหน่ง',
  COMMISSIONS: 'ค่าคอมมิชชั่น',
  GAME_SETTING: 'การตั้งค่าเกม',
  PROFILE: 'ข้อมูลส่วนตัว',
  LOGOUT: 'ออกจากระบบ',
  SEARCH: 'ค้นหา',
  FROM: 'จาก',
  TO: 'ถึง',
  ACTION: 'หนังบู๊',
  DATE: 'วันที่',
  RECEIPT: 'ใบเสร็จรับเงิน',
  STATUS: 'สถานภาพ',
  ACCOUNT_NO: 'เลขที่บัญชี',
  ACCOUNT_NAME: 'ชื่อบัญชี',
  TO_BANK: 'ถึง ธนาคาร',
  FROM_BANK: 'จาก ธนาคาร',
  CURRENCY: 'เงินตรา',
  AMOUNT: 'ปริมาณ',
  USERNAME: 'ชื่อผู้ใช้',
  'NO.': 'No.',
  ACCEPT_DEPOSIT: 'เงินฝากคำขอของคุณได้รับการยอมรับ',
  REJECT_DEPOSIT: 'เงินฝากคำขอของคุณถูกปฏิเสธ',
  DEPOSIT_REQUEST: 'คำขอเงินฝาก',
  ID: 'ID',
  BANK_ACCOUNT_NAME: 'ชื่อบัญชีธนาคาร',
  BANK_ACCOUNT_NO: 'เลขที่บัญชีธนาคาร',
  UPDATE: 'ปรับปรุง',
  UPDATE_BY: 'ปรับปรุงด้วย',
  APPROVE: 'อนุมัติ',
  ACCEPT: 'ยอมรับ',
  REJECT: 'ปฏิเสธ',
  SUCCESS: 'ชัยชนะ',
  WARNING: 'คำเตือน',
  FAIL: 'ล้มเหลว',
  AGENT: 'ตัวแทน',
  BETID: 'เดิมพันId',
  BET: 'เดิมพัน',
  WINLOSE: 'แพ้ชนะ',
  WINAMOUNT: 'จำนวนเงินที่ชนะ',
  JACKPOT: 'เงินรางวัล',
  CREATED: 'ที่สร้างไว้',
  LOADING: 'กำลังโหลด',
  TOTAL: 'ทั้งหมด',
  TOTAL_BET: 'การเดิมพันทั้งหมด',
  TOTAL_WINLOSE: 'วินแพ้ทั้งหมด',
  TOTAL_TURNOVER: 'มูลค่าการซื้อขายรวม',
  BETTING_DETAIL: 'รายละเอียดการเดิมพัน',
  SELECT_AGENT: 'เลือกตัวแทน',
  SELECT_GAME: 'เลือกเกม',
  SALE: 'การขาย',
  DOWNLINE: 'ดาวน์ไลน์',
  DOWNLINE_ACCOUNT: 'บัญชีดาวน์ไลน์',
  TOTAL_DOWNLINE_WINLOSE: 'ชนะดาวน์ไลน์โดยรวม',
  TURNOVER: 'ผลประกอบการ',
  DOWNLINE_TURNOVER: 'อัตราการหมุนเวียนดาวน์ไลน์',
  MEMBER_TURNOVER: 'อัตราการหมุนเวียนของสมาชิก',
  ADMIN: 'ผู้ดูแลระบบ',
  PARTNER: 'หุ้นส่วน',
  PLAYER: 'ผู้เล่น',
  COMPANY_TOTAL: 'บริษัท ทั้งหมด',
  SHARE_PERCEN: 'หุ้น %',
  COMM_PERCEN: 'คณะกรรมาธิการ %',
  TOTAL_NET: 'รวมสุทธิ',
  NO_DATA: 'ไม่มีข้อมูล...',
  ADD: 'เพิ่ม',
  BANK_NAME: 'ชื่อธนาคาร',
  BANK_CONFIG: 'การตั้งค่าธนาคาร',
  SUBMIT: 'เสนอ',
  SELECT_ROLE: 'เลือกบทบาท',
  PHONE: 'โทรศัพท์',
  BALANCE: 'สมดุล',
  ROLE: 'บทบาท',
  ACTIVE: 'คล่องแคล่ว',
  INACTIVE: 'เฉื่อยชา',
  ENTER_PASS: 'ใส่รหัสผ่าน',
  UPDATE_USER: 'อัปเดตผู้ใช้',
  SELECT_STATUS: 'เลือกสถานะ',
  UPDATE_USER_SUCCESS: 'อัปเดตผู้ใช้สำเร็จ',
  YOU_REQUEST_SUBMITED_SUCCESS: 'ส่งคำขอของคุณเรียบร้อยแล้ว',
  PLEASE_CONTACT_ADMIN: 'กรุณาติดต่อผู้ดูแลระบบ',
  COMM_CANNOT_GREATE_THAN: 'คอมมิชชั่นต้องไม่มากกว่า PT ของผู้ปกครอง',
  UPDATE_PERCEN: 'ปรับปรุงเปอร์เซ็นต์',
  CREATE_AGENT_SUCCESS: 'สร้างตัวแทนที่ประสบความสำเร็จ',
  CREATE_PARTNER_SUCCESS: 'สร้างพันธมิตรที่ประสบความสำเร็จ',
  CREATE_PLAYER_SUCCESS: 'สร้างผู้เล่นที่ประสบความสำเร็จ',
  CREATE_STAFF_SUCCESS: 'สร้างพนักงานที่ประสบความสำเร็จ',
  CREATE_AGENT_ERROR: 'สร้างตัวแทนผิดพลาด',
  CREATE_PARTNER_ERROR: 'สร้างข้อผิดพลาดของพันธมิตร',
  CREATE_PLAYER_ERROR: 'สร้างข้อผิดพลาดของผู้เล่น',
  CREATE_STAFF_ERROR: 'สร้างข้อผิดพลาดของพนักงาน',
  ENTER_USERNAME: 'ป้อนชื่อผู้ใช้',
  ENTER_FULLNAME: 'ป้อนชื่อเต็ม',
  ENTER_PHONE: 'ใส่หมายเลขโทรศัพท์',
  CREATE_NOW: 'สร้างเลย',
  ERROR: 'ความผิดพลาด !!!',
  USER_INFO: 'ข้อมูลผู้ใช้',
  EMAIL: 'อีเมล์',
  OLD_PASS: 'รหัสผ่านเก่า',
  IPAY_QR_CODE: 'คิวอาร์โค้ด',
  REGISTER_PARTNER: 'ลงทะเบียนพันธมิตร',
  REGISTER_AGENT: 'ตัวแทนที่ลงทะเบียน',
  REGISTER_PLAYER: 'ลงทะเบียนผู้เล่น',
  LOGIN: 'เข้าสู่ระบบ',
  LOGIN_ERROR: 'ข้อผิดพลาดในการเข้าสู่ระบบ',
  NEW_PASS:'รหัสผ่านใหม่',
  SETTINGS:'การตั้งค่า',
  PERCEN: 'ร้อยละ',
  ACCOUNT: 'บัญชี',
  LAST_LOGIN: 'เข้าสู่ระบบล่าสุด',
  TOTAL_PARTNER_ACTIVE_SUSPENDED: 'พันธมิตร (ใช้งาน / หยุดชั่วคราว)',
  TOTAL_AGENT_ACTIVE_SUSPENDED: 'ตัวแทน (ใช้งาน / ถูกระงับ)',
  TOTAL_PLAYER_ACTIVE_SUSPENDED: 'ผู้เล่น (ใช้งาน / หยุดชั่วคราว)',
  ENTER_NEWWIN_TOKEN: 'ป้อน Newwin Token',
  ENTER_NEWWIN_URL: 'ป้อน Newwin Url',
  ANNOUNCEMENT: 'การประกาศ',
  GAME_MANAGEMENT: 'การจัดการเกม',
  PROMOTION: 'โปรโมชั่น',
  SETTING: 'การตั้งค่า',
  DOWNLINE_RECEIIVABLE: 'ลูกหนี้ออฟไลน์',
  EARN_COMM: 'รับค่าคอมมิชชั่น',
  PAY_COMM: 'จ่ายค่าคอมมิชชัน',
  UPLINE_PAYABLE: 'สั่งจ่ายออนไลน์',
  PLAYER_WINLOSE: 'ผู้เล่นชนะหรือแพ้',
  SYNC_REAL_REPORT: 'ซิงค์รายงานจริง',
  FULL_REPORTS: 'รายงานชนะหรือแพ้',
  FULL_REPORTS_PAYABLE: 'รายงานการระงับข้อพิพาท',
  PLAYER_COMM: 'ค่าคอมมิชชั่นผู้เล่น',
  PLAYER_TOTAL: 'ยอดรวมผู้เล่น',
  BARREL_REPORTS: 'รายงานบาร์เรล',
  EXPENSES: 'ค่าใช้จ่าย',
  CONFIG_SHARE: 'การจัดสรรหุ้น',
  CREATED_BY: 'สร้าง',
  UPDATED_AT: 'อัปเดตเสร็จสมบูรณ์',
  ENTER_SHARE_AMOUNT: 'ป้อนจำนวนหุ้น',
  PAYOUT: 'จ่าย',
  PROFIT: 'กำไร',
  GROUP_TOTAL: 'กลุ่ม',
  GROUP_PROFIT: 'ผลกำไรกลุ่ม',
  GROUP_MEMBER: 'สมาชิกกลุ่ม'
}