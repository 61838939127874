export default {
  DASHBOARD: 'Dashboard',
  OVERVIEW: 'Website Overview',
  PERIODE: 'PERIODE',
  TRANSACTIONS: 'Transactions',
  DEPOSITS: 'Deposits',
  WITHDRAW: 'Withdraw',
  TRANSFERS: 'Transfers',
  BETTING_HISTORIES: 'Beting histories',
  OUT_STANDING: 'Outstanding',
  REPORTS: 'Reports',

  BONUS: 'Bonus',
  BANKING: 'Banking',
  USER_MANAGEMENT: 'User Management',
  USERS: 'Users',
  CREATE_PARTNER: 'Create Partner',
  CREATE_PLAYER: 'Create Player',
  CREATE_AGENT: 'Create Agent',
  CREATE_STAFF: 'Create Staff',
  POSITION_TAKING: 'Position Taking',
  COMMISSIONS: 'Commissions',
  GAME_SETTING: 'Game setting',
  PROFILE: 'Profile',
  EDIT_PROFILE: 'Edit Profile',
  LOGOUT: 'Logout',
  SEARCH: 'Search',
  FROM: 'From Date',
  TO: 'To Date',
  ACTION: 'Action',
  DATE: 'Date',
  RECEIPT: 'Receipt',
  STATUS: 'Status',
  ACCOUNT_NO: 'Account no',
  ACCOUNT_NAME: 'Account Name',
  TO_BANK: 'To Bank',
  FROM_BANK: 'From Bank',
  CURRENCY: 'Currency',
  AMOUNT: 'Amount',
  USERNAME: 'Username',
  NUMBER: 'Number',
  'NO.': 'No.',
  ACCEPT_DEPOSIT: 'your request deposit was accepted',
  REJECT_DEPOSIT: 'your request deposit was rejected',
  DEPOSIT_REQUEST: 'Deposit Request',
  ACCEPT_WITHDRAW: 'your request withdraw was accepted',
  REJECT_WITHDRAW: 'your request withdraw was rejected',
  WITHDRAW_REQUEST: 'Withdraw Request',
  ID: 'ID',
  BANK_ACCOUNT_NAME: 'Bank Account Name',
  BANK_ACCOUNT_NO: 'Bank Account no',
  UPDATE: 'Update',
  UPDATE_BY: 'Update By',
  APPROVE: 'Approve',
  ACCEPT: 'accept',
  REJECT: 'Reject',
  SUCCESS: 'success',
  WARNING: 'warning',
  FAIL: 'fail',
  AGENT: 'Agent',
  BETID: 'BetId',
  BET: 'Bet',
  WINLOSE: 'Winlose',
  WINAMOUNT: 'WinAmount',
  JACKPOT: 'Jackpot',
  CREATED: 'Created',
  LOADING: 'Loading',
  TOTAL: 'Total',
  TOTAL_BET: 'Total Bet',
  TOTAL_WINLOSE: 'Total Winlose',
  TOTAL_TURNOVER: 'Total Turnover',
  BETTING_DETAIL: 'Betting Details',
  SELECT_AGENT: 'Select Agent',
  SELECT_GAME: 'Select Game',
  SELECT_MARKET: 'Select Market',
  SALE: 'Sale',
  DOWNLINE: 'Downline',
  DOWNLINE_ACCOUNT: 'Downline Account',
  TOTAL_DOWNLINE_WINLOSE: 'Total Downline Winlose',
  TURNOVER: 'Turnover',
  DOWNLINE_TURNOVER: 'Downline Turnover',
  MEMBER_TURNOVER: 'Member Turnover',
  ADMIN: 'Admin',
  PARTNER: 'Partner',
  PLAYER: 'Player',
  COMPANY_TOTAL: 'Company Total',
  SHARE_PERCEN: 'Share %',
  COMM_PERCEN: 'Comm %',
  TOTAL_NET: 'Total Net',
  NO_DATA: 'No data is available...',
  ADD: 'Add',
  BANK_NAME: 'Bank Name',
  BANK_CONFIG: 'Banking config',
  SUBMIT: 'Submit',
  SELECT_ROLE: 'Select Role',
  PHONE: 'Phone',
  BALANCE: 'Balance',
  ROLE: 'Role',
  ACTIVE: 'Active',
  INACTIVE: 'InActive',
  ENTER_PASS: 'Enter Password',
  ENTER_NEWWIN_TOKEN: 'Enter Newwin Token',
  ENTER_NEWWIN_URL: 'Enter Newwin Url',
  UPDATE_USER: 'Update User',
  SELECT_STATUS: 'Select status',
  UPDATE_USER_SUCCESS: 'Update User Successful',
  YOU_REQUEST_SUBMITED_SUCCESS: 'Your request submitted successfully',
  PLEASE_CONTACT_ADMIN: 'Please contact administrator',
  COMM_CANNOT_GREATE_THAN: 'Comm can not greate than parent\'s comm',
  UPDATE_PERCEN: 'Update Percenage',
  CREATE_AGENT_SUCCESS: 'Create Agent Successful',
  CREATE_PARTNER_SUCCESS: 'Create Partner Successful',
  CREATE_PLAYER_SUCCESS: 'Create Player Successful',
  CREATE_STAFF_SUCCESS: 'Create Staff Successful',
  CREATE_AGENT_ERROR: 'Create Agent Error',
  CREATE_PARTNER_ERROR: 'Create Partner Error',
  CREATE_PLAYER_ERROR: 'Create Player Error',
  CREATE_STAFF_ERROR: 'Create Staff Error',
  ENTER_USERNAME: 'Enter username',
  ENTER_FULLNAME: 'Enter Fullname',
  ENTER_PHONE: 'Enter Phone',
  CREATE_NOW: 'Create Now',
  ERROR: 'Error !!!',
  USER_INFO: 'User Info',
  EMAIL: 'Email',
  OLD_PASS: 'Old Password',
  IPAY_QR_CODE: 'QR CODE',
  REGISTER_PARTNER: 'REGISTER PARTNER',
  REGISTER_AGENT: 'REGISTER AGENT',
  REGISTER_PLAYER: 'REGISTER PLAYER',
  LOGIN: 'Login',
  SIGNIN: 'Sign In',
  LOGIN_ERROR: 'Login Error',
  NEW_PASS: 'New Password',
  SETTINGS: 'Settings',
  PT_CANNOT_GREATE_THAN: 'PT can not greate than parent\'s PT',
  PERCEN: 'Percentage',
  ACCOUNT: 'Account',
  LAST_LOGIN: 'Last login',
  TOTAL_PARTNER_ACTIVE_SUSPENDED: 'Total partner (Active/suspended)',
  TOTAL_AGENT_ACTIVE_SUSPENDED: 'Total agent (Active/suspended)',
  TOTAL_PLAYER_ACTIVE_SUSPENDED: 'Total player (Active/suspended)',
  PLATFORM: 'Platform',
  GAMES: 'Games',
  GAME_MANAGEMENT: 'Games management',
  SELECT_TYPE: 'Select Type',
  ANNOUNCEMENT: 'Announcement',
  FULLNAME: 'Fullname',
  PROMOTION: 'Promotion',
  SETTING: 'Setting',
  DOWNLINE_RECEIIVABLE: 'Downline Receivable',
  EARN_COMM: 'Earn comm',
  PAY_COMM: 'Pay comm',
  UPLINE_PAYABLE: 'Upline Payable',
  PLAYER_WINLOSE: 'Player Winlose',
  PLAYER_WIN: 'Player Win',
  WIN: 'Win',
  SYNC_REAL_REPORT: 'Sync Real Report',
  FULL_REPORTS: 'Winlose Report',
  FULL_REPORTS_PAYABLE: 'Settlement Report',
  PLAYER_COMM: 'Player Comm',
  PLAYER_TOTAL: 'Player Total',
  PAYMENT_METHOD: 'Payment Method',
  RABAT_BONUS: 'Rabat Bonus',
  PARENT: 'Parent',
  BONUS_AMOUNT: 'Bonus Amount',
  TOTAL_BONUS: 'Total Bonus',
  TRANSACTION_HISTORY: 'Transaction History',
  TYPE: 'Type',
  BEFORE: 'Before',
  AFTER: 'After',
  DEFAULT: 'Default',
  REFERENCE_NO: 'Reference No',
  TRANSFERS_PLAYER: 'Transfer Player',
  SENDER: 'Sender',
  RECEIVER: 'Receiver',
  NOTE: 'Note',
  UPDATED_BY: 'Updated By',
  USERS_ACTION: 'User Action',
  METHOD: 'Method',
  TARGET: 'Target',
  POSITION_TAKING_GROUP: 'Position Taking Group',
  BET_AMOUNT: 'Bet Amount',
  PLAYER_PROFIT: 'Player Profit',
  SHARE_WINLOSE: 'Share Profit',
  SHARE_COMM: 'Share Comm',
  SELF_WINLOSE: 'Self Winlose',
  NEW_REPORTS: 'New Report',
  EAT_TICKET_HISTORY: 'Eat Ticket History',
  EAT_TICKET_HISTORY_AGENT: 'Eat Ticket History Agent',
  BARREL_REPORTS: 'Barrel Report',
  EXPENSES: 'Expenses',
  CONFIG_SHARE: 'Config Share',
  CREATED_BY: 'Created By',
  UPDATED_AT: 'Updated At',
  ENTER_SHARE_AMOUNT: 'Enter share amount',
  PAYOUT: 'Payout',
  PROFIT: 'Profit',
  GROUP_TOTAL: 'Group Total',
  GROUP_PROFIT: 'Group Profit',
  GROUP_MEMBER: 'Group member',
  ENTER_PT: 'Enter PT',
  ENTER_POINT: 'Enter Point',
  POINT: 'Point',
  TRANSFER_POINT: 'Transfer Point',
}
