import '../../index.css'

const data = [
    {
        game: '4D',
        minium: '1000',
        keli: '1 : 1'
    },
    {
        game: '3D',
        minium: '1000',
        keli: '1 : 1'
    },
    {
        game: '2D',
        minium: '1000',
        keli: '1 : 1'
    },
    {
        game: '4D',
        minium: '1000',
        keli: '1 : 1'
    },
    {
        game: '2D Depan',
        minium: '1000',
        keli: '1 : 1'
    },
    {
        game: '2D Tengah',
        minium: '1000',
        keli: '1 : 1'
    },
    {
        game: 'Colok Naga',
        minium: '1000',
        keli: '1 : 1'
    },
    {
        game: 'Colok Bebas',
        minium: '1000',
        keli: '1 : 1'
    },
    {
        game: 'Macau',
        minium: '1000',
        keli: '1 : 1'
    },
    {
        game: 'Colok Naga',
        minium: '1000',
        keli: '1 : 1'
    },
    {
        game: 'Colok Jitu',
        minium: '1000',
        keli: '1 : 1'
    },
    {
        game: 'Tengah',
        minium: '1000',
        keli: '1 : 1'
    },
    {
        game: 'Dasar',
        minium: '1000',
        keli: '1 : 1'
    },
    {
        game: '50 - 50',
        minium: '1000',
        keli: '1 : 1'
    },
    {
        game: 'Shio',
        minium: '1000',
        keli: '1 : 1'
    },
    {
        game: 'Silang',
        minium: '1000',
        keli: '1 : 1'
    },
    {
        game: 'Kembang',
        minium: '1000',
        keli: '1 : 1'
    },
    {
        game: 'Kombinasi',
        minium: '1000',
        keli: '1 : 1'
    }
]
function LimitBet() {
    return (
        <div style={{marginTop: "5%", padding: "2%"}}>
            <table style={{color: "black"}} className="table-h5 fontWeight1000">
                <tr className="limit-tr01">
                    <th className="th-h5">Game</th>
                    <th className="th-h5">Minimum Bet</th>
                    <th className="th-h5">Kelipatan</th>
                </tr>

                {data.map((item, index) => (
                    <tr key={index} className="limit-tr02">
                        <td className="td-h5">{item.game}</td>
                        <td className="td-h5">{item.minium}</td>
                        <td className="td-h5">{item.keli}</td>
                    </tr>
                ))}
            </table>
        </div>
    )
}

export default LimitBet