import React, {useState} from 'react'
import {Button, Container, InputGroup, Col, Row} from 'reactstrap'
import {useForm} from 'react-final-form-hooks'
import ImageLogin from '../../assets/img/LoginPage.jpg'
import IconUsername from '../../assets/img/icons/IconUsername.png'
import IconPassword from '../../assets/img/icons/IconPassword.png'
import IconShowPassword from '../../assets/img/icons/IconShowPass.png'
import maintenanceImage from '../../assets/img/login/yellow-black-color-line-striped-260nw-2006486078.png'


import Joi from '@hapi/joi'
import http from '../../service/http'
import validate from '../../service/form/validation'
import formCreateInputs from '../../service/form/create'
import {useDialog, TextInput} from '../../Component'
import store from 'store'
import {useTranslation} from 'react-i18next'
import {InputAdornment} from '@material-ui/core'
import './login.css'
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from '../../Component/capcha'

function Login({history}) {

    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [config, setConfig] = useState({
        maintenance: 'no'
    });
    const handleShowPasswordClick = () => {
        setIsPasswordVisible(!isPasswordVisible)
    }


    const {t} = useTranslation()
    store.clearAll()

    const [ErroDialog, showErrorDialogWithMessage, showWithImage] = useDialog({
        title: t('LOGIN_ERROR'),
        type: 'error',
    })
    const fetchData = async () => {
        const sysconfig = await http.get({ path: 'config' })
        if(sysconfig.maintenance === 'yes') showWithImage(maintenanceImage)
        setConfig(sysconfig)
    }
    const onSubmit = async (payload) => {
        try {
            const authrs = await http.post({path: 'authenticate', payload})
            localStorage.setItem('loginTime', authrs.user.timestamp)
            http.setJwtToken(authrs.token)
            store.set('token', authrs.token)
            const user = await http.get({path: 'me'})
            store.set('user', user)
            // console.log(authrs.user.timestamp)
            history.push('/')
        } catch (error) {
            // console.log(error.message, error.message.includes('system temporarily closes'))
            if(error.message.includes('system temporarily closes')) {
                showWithImage(maintenanceImage)
            }
            else showErrorDialogWithMessage(error.message)
        }
    }

    const schema = Joi.object().keys({
        username: Joi.string().required(),
        password: Joi.string().required(),
    })

    const {form, handleSubmit} = useForm({
        onSubmit,
        validate: validate(schema),
    })

    const [username, password] = formCreateInputs(['username', 'password'], form)

    const onChange = (event) => {
        document.getElementById('loginBtn').disabled = false
        const inputCode = event.target.value
        // console.log(inputCode, validateCaptcha(inputCode, false)==true)
        if (inputCode.length === 4 && validateCaptcha(inputCode, false)==true) {
            document.getElementById('loginBtn').disabled = false
        }

        else {
            document.getElementById('loginBtn').disabled = true
        }

    }

    React.useEffect(() => {
        store.set('token', null)
        store.set('user', null)
        document.getElementById('loginBtn').disabled = true
        loadCaptchaEnginge(4, 'gray', 'black', 'numbers')
        fetchData()
    }, [])

    return (
        <Row>
            <Col xs="12" lg="12" style={{backgroundImage: `url(${ImageLogin})`}}
                 className='wrapper-login background-css'>
                <Container className='container-login'>
                    <form onSubmit={handleSubmit}>
                        <div style={{display: 'flex', flexDirection: "column", alignItems: "center"}}>
                            <h1 className='title-Sign'>{t('SIGNIN')}</h1>
                            <div className='form-group'>
                                <InputGroup style={{width: '100%', backgroundColor: '#f2f2f2', borderRadius: 12}}>
                                    {/* <TextInput
                  className="input-account"
                  input={username}
                  placeholder={t('Username')}
                  InputProps={{
                    
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonOutlineOutlinedIcon style={{ color: '#e2ac0a' }}/>
                      </InputAdornment>
                    ),
                  }}
                /> */}

                                    <TextInput
                                        className="input-account"
                                        input={username}
                                        placeholder={t('Username')}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment style={{marginLeft: "10px"}} position="start">
                                                    <img src={IconUsername} alt="Username icon"
                                                         style={{width: '24px', height: '24px', marginRight: "100px"}}/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />

                                </InputGroup>
                                <InputGroup style={{width: '100%', backgroundColor: '#f2f2f2', borderRadius: 12}}>
                                    <TextInput
                                        className="input-account"
                                        input={password}
                                        type={isPasswordVisible ? 'text' : 'password'}
                                        placeholder={t('Password')}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment style={{marginLeft: "10px"}} position="start">
                                                    <img className='iconUsername' src={IconPassword}
                                                         alt="Username icon"/>
                                                    <img className='iconShowPass' onClick={handleShowPasswordClick}
                                                         src={IconShowPassword} alt="Username icon"/>
                                                    {/* <LockOutlinedIcon style={{ color: '#e2ac0a' }} /> */}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </InputGroup>
                                {/* <Row style={{ width: "100%" }}>
                <Col style={{ padding: "12px 0" }}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LfrA5QpAAAAAHoj0FpgFIa8e6kWmdjTvPhwBZ4b"
                    onChange={onChange}
                  />
                </Col>
              </Row> */}

                                <Row style={{width: '100%'}}>

                                    <Col style={{padding: 2}} xs={6} classname="login-capcha">
                                        <LoadCanvasTemplateNoReload />
                                    </Col>

                                    <Col style={{padding: 2}} xs={6}>
                                        <input style={{fontWeight: "bold", margin: 'auto', width: '100%'}} id='inputShowCode'
                                               sitekey="6LfrA5QpAAAAAHoj0FpgFIa8e6kWmdjTvPhwBZ4b"
                                               onChange={onChange} className='inputCode' placeholder='Code'/>
                                    </Col>

                                </Row>

                                <Button type="submit" color="primary" id={'loginBtn'} disabled={config.maintenance === 'yes'}
                                        className='button-SignIn'>{t('SIGNIN')}</Button>

                                <div>
                                    <div style={{display: 'flex', color: "white"}}>
                                        <span>Forget</span>
                                        <p style={{marginLeft: "10px", color: "#FFD700", fontWeight: "bold"}}>
                                            <a style={{borderBottom: "2px solid #FFD700"}}>USERNAME</a>
                                        </p>
                                        <sapn style={{marginLeft: "10px"}}>or</sapn>
                                        <p style={{marginLeft: "10px", color: "#FFD700", fontWeight: "bold"}}>
                                            <a style={{borderBottom: "2px solid #FFD700"}}>PASSWORD</a>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </Container>
                <ErroDialog/>
            </Col>
        </Row>
    )
}

export default Login
