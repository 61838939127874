

import numeral from "numeral";
const data = [
    {
        num: 1,
        checkbox01: true,
        checkbox02: false,
        tepi: 'Tepi',
        Tenga: "Tengah",
        amount: 10.000,
        disc: 500,
        total: 9.500
    },
    {
        num: 2,
        checkbox01: false,
        checkbox02: true,
        tepi: 'Tepi',
        Tenga: "Tengah",
        amount: 10.000,
        disc: 500,
        total: 9.500
    },
    {
        num: 3,
        checkbox01: false,
        checkbox02: true,
        tepi: 'Tepi',
        Tenga: "Tengah",
        amount: 10.000,
        disc: 500,
        total: 9.500
    },
    {
        num: 4,
        checkbox01: false,
        checkbox02: false,
        tepi: 'Tepi',
        Tenga: "Tengah",
        amount: 0,
        disc: 0,
        total: 0
    },
    {
        num: 5,
        checkbox01: true,
        checkbox02: false,
        tepi: 'Tepi',
        Tenga: "Tengah",
        amount: 10.000,
        disc: 500,
        total: 9.500
    },
    {
        num: 6,
        checkbox01: true,
        checkbox02: false,
        tepi: 'Tepi',
        Tenga: "Tengah",
        amount: 10.000,
        disc: 500,
        total: 9.500
    },
    {
        num: 7,
        checkbox01: false,
        checkbox02: true,
        tepi: 'Tepi',
        Tenga: "Tengah",
        amount: 10.000,
        disc: 500,
        total: 9.500
    },
    {
        num: 8,
        checkbox01: false,
        checkbox02: true,
        tepi: 'Tepi',
        Tenga: "Tengah",
        amount: 10.000,
        disc: 500,
        total: 9.500
    },
    {
        num: 9,
        checkbox01: false,
        checkbox02: false,
        tepi: 'Tepi',
        Tenga: "Tengah",
        amount: 0,
        disc: 0,
        total: 0 
    },
    {
        num: 10,
        checkbox01: false,
        checkbox02: false,
        tepi: 'Tepi',
        Tenga: "Tengah",
        amount: 0,
        disc: 0,
        total: 0
    }

]

function Tengah() {
    const totals = {
        amount: data.reduce((acc, curr) => acc + curr.amount, 0),
        disc: data.reduce((acc, curr) => acc + curr.disc, 0),
        total: data.reduce((acc, curr) => acc + curr.total, 0)
    }

    
    return (
        <div style={{marginTop: "5%"}}>

            <table style={{color: "black"}} className="table-h5 f10">
                <tr className="limit-tr01">
                    <th style={{width: "5%"}} className="th-h5">No</th>
                    <th style={{width: "35%"}} colspan="4" className="th-h5">Select</th>
                    <th style={{width: "15%"}} className="th-h5">Amount</th>
                    <th style={{width: "10%"}} className="th-h5">Disc(%)</th>
                    <th style={{width: "15%"}} className="th-h5">Total</th>
                </tr>

                {data.map((item, index) => (
                    <tr key={index}>
                        <td className="td-h5">{item.num}</td>
                        <td style={{width: "3%"}} className="td-h5">
                            <input type="checkbox" className="gray-checkbox" checked={item.checkbox01} />
                        </td>
                        <td style={{width: "10%"}} className="td-h5">{item.tepi}</td>
                        <td style={{width: "3%"}} className="td-h5">
                            <input type="checkbox" className="gray-checkbox" checked={item.checkbox02} />
                        </td>
                        <td style={{width: "10%"}} className="td-h5"> {item.Tenga}</td>
                        <td className="td-h5"> {numeral(item.amount).format('0.000')}</td>
                        <td className="td-h5"> {numeral(item.disc).format('0.000')}</td>
                        <td className="td-h5"> {numeral(item.total).format('0.000')}</td>
                    </tr>              
                ))}

                <tr>
                    <td className="td-h5"></td>
                    <td colspan="4" className="td-h5 fontWeight1000">Total</td>
                    <td className="td-h5 fontWeight1000">{numeral(totals.amount).format('0.000')}</td>
                    <td className="td-h5 fontWeight1000">{numeral(totals.disc).format('0.000')}</td>
                    <td className="td-h5 fontWeight1000">{numeral(totals.total).format('0.000')}</td>
                </tr>
            </table>

           
        </div>
    )
}

export default Tengah;