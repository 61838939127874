import React, {Suspense, useEffect, useState} from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import store from 'store';
import {
  AppBreadcrumb,
  AppHeader,
  AppFooter,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
import routes from '../../routes';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';

import navigation from '../../_nav';
import http from '../../service/http';
import { useTranslation } from 'react-i18next'
import '../styled.css'
import maintenanceImage from "../../assets/img/login/yellow-black-color-line-striped-260nw-2006486078.png";
import {useDialog} from "../../Component";
import queryString from "query-string";

function DefaultLayout(props) {
  const { t } = useTranslation()
  const auth = store.get('token');
  const user = store.get('user');
  const [userInfo, setUserInfo] = useState(user);
  const [config, setConfig] = useState({
    maintenance: 'no'
  });
  const [ErroDialog, showErrorDialogWithMessage, showWithImage] = useDialog({
    title: t('LOGIN_ERROR'),
    type: 'error',
  })
  const fetchData = async (t=null) => {
    const sysconfig = await http.get({ path: 'config' })
    // console.log(sysconfig, t, window.location.href)
    if(sysconfig.maintenance === 'yes') {
      if(t && !window.location.href.includes('/login')) window.location.href = '/login?t='+t
      else showWithImage(maintenanceImage)
    }
    setConfig(sysconfig)
  }
  useEffect(() => {
    fetchData()
    // showErrorDialogWithMessage('asdasdasdsadsa d dsad sad d asd sad sad sa sa dsa dsa d sad sad sad sad sad sa ds ads ds ad')
  },[])
  if (!auth || !user) return (
    <Redirect from="/" to="/login" />
  )
  http.setJwtToken(auth);
  function signOut(e) {
    e.preventDefault()
    props.history.push('/login')
  }
  function gotoProfile(e) {
    e.preventDefault()
    props.history.push('/profile')
  }
  const refreshUser = async () => {
    const user = await http.get({ path: 'me' })
    store.set('user', user)
    setUserInfo(user)
  }

  return (
    <div className="app" style={{ background: "#000C37", color: "#fff" }}>
      <div class="app-container">
        <AppHeader fixed>
          <Suspense>
            {(user || userInfo) && <DefaultHeader {...props} refreshNews={fetchData} refreshUser={refreshUser} user={userInfo ? { ...userInfo } : { ...user }} t={t} onLogout={e => signOut(e)} gotoProfile={e => gotoProfile(e)} />}
          </Suspense>
          <div className='tabBar'>
            <div className='app-tabbar-item'>
              <p className='app-tabBar-text'>
                <marquee>{t('NEWS: ')}{config.news}</marquee>
              </p>
            </div>
          </div>
        </AppHeader>
        <div className="app-body" style={{ padding: "0 16px" }}>
          {/* <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={navigation(pers, role, t)} {...props} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar> */}
          <main className="main">
            {/* <AppBreadcrumb appRoutes={routes} /> */}
            <div>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                      <route.component {...props} sysConfig={config} refreshUser={refreshUser} {...user} />
                    )} />
                  )
                    : (null);
                },
                )}
              </Switch>
            </div>
          </main>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
      <ErroDialog/>
    </div>
  );
}

export default DefaultLayout;
