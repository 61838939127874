import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Route, BrowserRouter as Router } from "react-router-dom"
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { DefaultLayout } from './containers';
import Login from './pages/Login'
import React from "react";
function App() {
  const theme = createMuiTheme({
    typography: {
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        // 'AIFont'
      ].join(','),
    },
    palette: {
      primary: {
        main: '#14805E'
      }
    }
  },
  )
  return (
    <MuiThemeProvider theme={theme}>

      <Router>
        <div>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route path="/" name="Home" component={DefaultLayout} />
        </div>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
