import "../index.css"
import BackIcon from '../../../assets/img/icons/back-button-icon.png'
import http from "../../../service/http";
import {useEffect, useState} from "react";
function Tools(props) {
    const [mimpis, setMipis] = useState([])
    const [query, setQuery] = useState({
        game: '2D',
        ket: '',
        angka: '',
    })
    const fetchData = async () => {
        const params = {
            game: query.game
        }
        if(query.angka !== '') params.angka = query.angka
        if(query.ket !== '') params.ket = query.ket
        try {
            const data = await http.get({ path: 'mimpis', params})
            setMipis(data)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    };
    useEffect(() => {
        fetchData();
    }, [])
    return (
        <div className="bg-tools">

            <div className="tools-div-Title">
                <img className="tools-Img-Back" src={BackIcon} onClick={props.history.goBack}></img>
                <p className="tools-Title-Text" style={{color: "black"}}>Tools</p>
            </div>

            <div className="tools-div-02">
                <button className="tools-div-02-Button-01">Dream Book</button>
                <button className="tools-div-02-Button-02" onClick={fetchData}>Filter</button>
            </div>

            <div className="tools-div-03">
                <div>
                    <span style={{color: "black"}}>Game</span>
                    <select className="tools-div-03-Select" style={{color: "black"}} onChange={(e) => setQuery({...query, game: e.target.value})}>
                        <option value="2D">2D</option>
                        <option value="3D">3D</option>
                    </select>
                </div>
                <div className="tools-div-input01" style={{color: "black"}}>
                    <span>Angka</span>
                    <input className="tools-div-03-Input01" value={query.angka} onChange={(e) => setQuery({...query, angka: e.target.value})}/>
                </div>
                <div className="tools-div-input02" style={{color: "black"}}>
                    <span>Keteramgan</span>
                    <input className="tools-div-03-Input02" value={query.ket} onChange={(e) => setQuery({...query, ket: e.target.value})}/>
                </div>
            </div>

            <div style={{marginTop: "20px"}}>
                <table className="table-h5 f12">
                    <tr style={{color: "white", backgroundColor: "red"}}>
                        <th className="th-h5" style={{width: "5%"}}>No</th>
                        <th className="th-h5" style={{width: "5%"}}>Game</th>
                        <th className="th-h5" style={{width: "70%"}}>Keterangan</th>
                        <th className="th-h5" style={{width: "20%"}}>Angka</th>
                    </tr>

                    {mimpis.map((m, i) => (
                        <tr style={{color: "black"}} key={i}>
                            <td className="td-h5">{i + 1}</td>
                            <td className="td-h5">{m.game}</td>
                            <td className="td-h5 mempis-ket">{m.ket}</td>
                            <td className="td-h5">{m.angka}</td>
                        </tr>
                    ))}
                </table>
            </div>

        </div>
    )
}

export default Tools;