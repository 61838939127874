import React, { useState, useEffect } from 'react';
import http from "../../../../../../service/http";
import {useDialog, useLoading} from "../../../../../../Component";
import LoadingUi from '../../../../../../Component/Loading/ui'
import {Button, Modal, ModalBody, ModalFooter, Table} from "reactstrap";
import numeral from "numeral";
function BETDISKONTabQB({betType, getBackgroundColor, getReCeipt, bgrColor, isDisCount, arrayCreateQuickbet, market, periode}) {
    const [loading, withLoading] = useLoading()
    const [Dialog, showDialogWithMessage] = useDialog({
        title: 'SUCCESS'
    })
    const [DialogError, showDialogErrorWithMessage] = useDialog({
        title: 'ERROR',
        type: 'error'
    })
    const [betTypes, setBetTypes] = useState({
        '4D': '',
        '3D': '',
        '2D': '',
    });
    const [content, setContent] = useState('');
    const [dataReview, setDataReview] = useState(null)
    const [totalBet, setTotalbet] = useState(0);
    const [count, setCount] = useState(0);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [type, setType] = useState(1);
    const [menang, setMenang] = useState({
        d4: {},
        d3: {},
        d2t: {},
        d2d: {},
    });
    let localbets = []
    const handleBlur = (e) => {
        const ta = document.getElementById('ta');
        const lineNo = ta.value.substr(0, ta.selectionStart).split(/\r?\n|\r/).length;
        const lineText = ta.value.split(/\r?\n|\r/)[lineNo - 1];
        // console.log(lineText, isNaN(Number(lineText)) && !lineText.toLowerCase().match(/b/g))
        if(isNaN(Number(lineText)) && !lineText.toLowerCase().match(/b/g)) setContent('')
        if(!isNaN(Number(lineText)) && lineText.length > 4) setContent(lineText.slice(0,4))
        if(type > 1 && !isNaN(Number(lineText)) && lineText.length > 2) setContent(lineText.slice(0,2))
    }
    const handleKeyDown = (event) => {
        const ta = document.getElementById('ta');
        const lineNo = ta.value.substr(0, ta.selectionStart).split(/\r?\n|\r/).length;
        const lineText = ta.value.split(/\r?\n|\r/)[lineNo - 1];
        let max = 4
        let maxbb = 6
        if(type > 1) {
            max = 2
            maxbb = 4
        }
        // alert(event.keyCode)
        // alert(lineText)
        // alert(type)
        // alert(event.key)
        // alert(![8, 13, 37].includes(event.keyCode))
        if(type > 1 && !isNaN(Number(lineText)) && lineText.length > 1 && ![8, 13, 37].includes(event.keyCode)) alert('2D only input 2 digital')
        if(type > 1 && !isNaN(Number(lineText)) && lineText.length > 1 && ![8, 13, 37].includes(event.keyCode)) event.preventDefault();
        if (
            (((lineText.toLowerCase().match(/b/g)?.length === 1 && !isNaN(Number(event.key))) ||
            (lineText.toLowerCase().includes('bb') && isNaN(Number(event.key))) ||
            (lineText.length > 0 && !isNaN(Number(lineText)) && [66].includes(event.keyCode))) && ![8, 13, 37].includes(event.keyCode)) ||
            (((!lineText.toLowerCase().includes('bb') && lineText.length >= 2 && type > 1) ||
                (lineText.toLowerCase().includes('bb') && lineText.length >= 9 && type > 1) ||
                (!lineText.toLowerCase().includes('bb') && lineText.length >= max) ||
                (lineText.toLowerCase().includes('bb') && lineText.length >= 11) ||
                    (!(event.keyCode >= 49 && event.keyCode <= 57) &&
                !(event.keyCode >= 97 && event.keyCode <= 105))) && ![66, 8, 13, 37].includes(event.keyCode))
        ) {
            event.preventDefault();
        }
        // alert((((!lineText.toLowerCase().includes('bb') && lineText.length >= 2 && type > 1) ||
        //     (lineText.toLowerCase().includes('bb') && lineText.length >= 9 && type > 1) ||
        //     (!lineText.toLowerCase().includes('bb') && lineText.length >= max) ||
        //     (lineText.toLowerCase().includes('bb') && lineText.length >= 11) ||
        //     !(event.keyCode >= 49 && event.keyCode <= 57)) && ![8, 13, 37].includes(event.keyCode)))

        if(event.keyCode === 13) {
            let min  = 4
            const inputNumbers = content.split('\n').filter(x => x.trim() !== '')
            let isLess = false
            if(betTypes['3D'] > 0) min = 3
            if(betTypes['2D'] > 0) min = 2
            inputNumbers.map(i => {
                if(i.length < min) isLess = true
            })
            if(isLess) event.preventDefault();
        }
    };

    const handleChange = (event) => {
        setContent(event.target.value);
    };
    const handleSubmit = async () => {
        let _totalBet = 0
        const inputNumbers = content.split('\n').filter(x => x.trim() !== '')

        const payload = {
            betTypes: [],
            period: periode.length > 0 ? periode[0].no + 1 : 1,
            code: market.code,
            pid: market._id
        }

        inputNumbers.map(nn => {
            const n = nn.toLowerCase().replaceAll('b', '')
            if(n.length >= 4 && betTypes['4D'] > 0) {
                const arr = nn.toLowerCase().includes('bb') ? arrayCreateQuickbet(n.split(''), 4) : [n]
                // console.log(arr)
                arr.map(a => {
                    payload.betTypes.push({
                        number: a.toString().replaceAll(',', ''),
                        betTypes: '4D',
                        amount: betTypes['4D'] * 1000

                    })
                    _totalBet += betTypes['4D'] * 1000
                })

            }

            if(n.length >= 2 && betTypes['2D'] > 0) {
                let arr = []
                if(nn.toLowerCase().includes('bb')) {
                    arr = n.length > 2 ? arrayCreateQuickbet(n.split(''), 2) : [n]
                }
                else {
                    if(n.length > 2) {
                        if(n.length === 3) {
                            if (type === 1) {
                                // payload.betTypes.push({
                                //     number: n.substring(0, 2),
                                //     betTypes: '2D Tengah',
                                //     amount: betTypes['2D'] * 1000
                                //
                                // })
                                payload.betTypes.push({
                                    number: n.substring(1, 3),
                                    betTypes: '2D Belakang',
                                    amount: betTypes['2D'] * 1000

                                })
                                _totalBet += betTypes['2D'] * 1000
                            } else if( type === 2) {
                                payload.betTypes.push({
                                    number: n.substring(0, 2),
                                    betTypes: '2D Tengah',
                                    amount: betTypes['2D'] * 1000

                                })
                                _totalBet += betTypes['2D'] * 1000
                            }
                        }
                        else if(n.length === 4) {
                            if (type === 1) {
                                // payload.betTypes.push({
                                //     number: n.substring(0, 2),
                                //     betTypes: '2D Depan',
                                //     amount: betTypes['2D'] * 1000
                                //
                                // })
                                // payload.betTypes.push({
                                //     number: n.substring(1, 3),
                                //     betTypes: '2D Tengah',
                                //     amount: betTypes['2D'] * 1000
                                //
                                // })
                                payload.betTypes.push({
                                    number: n.substring(2, 4),
                                    betTypes: '2D Belakang',
                                    amount: betTypes['2D'] * 1000

                                })
                                _totalBet += betTypes['2D'] * 1000
                            } else if (type === 2) {
                                payload.betTypes.push({
                                    number: n.substring(1, 3),
                                    betTypes: '2D Tengah',
                                    amount: betTypes['2D'] * 1000

                                })
                                _totalBet += betTypes['2D'] * 1000
                            } else if (type === 3) {
                                payload.betTypes.push({
                                    number: n.substring(0, 2),
                                    betTypes: '2D Depan',
                                    amount: betTypes['2D'] * 1000

                                })
                                _totalBet += betTypes['2D'] * 1000
                            }
                        }
                    }
                    else arr = [n]
                }
                let betTypess = '2D Belakang'
                if(type === 2) betTypess = '2D Tengah'
                else if(type === 3) betTypess = '2D Depan'

                arr.map(a => {
                    payload.betTypes.push({
                        number: a.toString().replaceAll(',', ''),
                        betTypes: betTypess,
                        amount: betTypes['2D'] * 1000

                    })
                    _totalBet += betTypes['2D'] * 1000
                })
            }
            if(n.length >= 3 && betTypes['3D'] > 0) {
                let arr
                if(n.length > 3) arr = nn.toLowerCase().includes('bb') ? [...new Set(arrayCreateQuickbet(n.split(''), 3).map(a => a.toString().replaceAll(',', '')))] : [n.substring(n.length-3, n.length)]
                else {
                    arr = nn.toLowerCase().includes('bb') ? [...new Set(arrayCreateQuickbet(n.split(''), 3).map(a => a.toString().replaceAll(',', '')))] : [n]
                }
                arr.map(a => {
                    payload.betTypes.push({
                        number: a,
                        betTypes: '3D',
                        amount: betTypes['3D'] * 1000

                    })
                    _totalBet += betTypes['3D'] * 1000
                })
            }
        })

        setTotalbet(_totalBet)
        let isLowerMinbet = 0
        const betTypesSum = {}
        payload.betTypes = payload.betTypes.map(n => {
            let id_game
            if(type === 1) {
                n.betTypes = (isDisCount ? '' : betType + '-') + (n.number.length === 2 ? n.betTypes : (n.number.length === 3 ? '3D' : '4D'))
                id_game =  n.betTypes.includes('2D Belakang') ? 3 : (n.betTypes.includes('3D') ? 2 : 1)
            }
            else if(type === 3) {
                n.betTypes = (isDisCount ? '' : betType + '-') + '2D Depan'
                id_game = 4
            }
            else if(type === 2) {
                n.betTypes = (isDisCount ? '' : betType + '-') + '2D Tengah'
                id_game = 5
            }
            const minBet = n.number.length === 2 ? menang.d2d : (n.number.length === 3 ? menang.d3 : menang.d4)
            if(n.amount < minBet.minBetLimit) isLowerMinbet = minBet.minBetLimit

            if(!betTypesSum[n.betTypes]) betTypesSum[n.betTypes] = {}
            if(!betTypesSum[n.betTypes][n.number]) betTypesSum[n.betTypes][n.number] = 0
            betTypesSum[n.betTypes][n.number] += n.amount
            n.id_game = id_game
            return n
        })
        if(isLowerMinbet > 0) showDialogErrorWithMessage('Min bet limit: ' + isLowerMinbet)
        else if(payload.betTypes.length > 0) {

            setDataReview(payload)
            setCount(count+1)

            toggle()
        }
    }
    const runReceipt = () => {
        getReCeipt(localbets)
    }
    const submit = async () => {
        toggle()
        if(dataReview.betTypes.length > 0) {
            try {
                const betrs = await withLoading(() => http.post({path: 'bet', payload: JSON.stringify(dataReview)}))
                const txid = betrs.toPage.split('&proses=')
                const betH = await withLoading(() => http.get({path: 'users/bets/' + txid[1]}))
                if (betrs.message && betrs.message !== '') {
                    if (betH.length > 0) {
                        localbets = betH
                        showDialogErrorWithMessage(betrs.message, runReceipt)
                    }
                    else showDialogErrorWithMessage(betrs.message)
                }
                else {
                    if (betH.length > 0) getReCeipt(betH)
                    else showDialogErrorWithMessage('No bet accepted')
                }

            } catch (error) {
                showDialogErrorWithMessage(error.message)
            }
        }
    }
    const setTypeBet = (value) => {
        setType(value)
        setContent('')
        if(value > 1) {
            setBetTypes({...betTypes, '4D': '', '3D': ''})
        }
    }
    const handleInputChange = (event, t, tt) => {
        let value = event.target.value * 1;
        // const minBetLimit = menang[t].minBetLimit
        // if(value < minBetLimit) value = minBetLimit
        if(event.target.value === '' || value > 0) setBetTypes({...betTypes, [tt]: event.target.value})
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await http.get({
                    path: 'users/menang/' + market._id,
                })
                const filteredResponse = response.filter(item => [1, 2, 4, 5].includes(item.id_game) && (isDisCount ? !item.ket.includes('-') : item.ket.includes(betType)))
                const d4 = filteredResponse.find(x => x.id_game === 1)
                // console.log(d4, betType)

                const d3 = filteredResponse.find(x => x.id_game === 2)
                const d2t = filteredResponse.find(x => x.id_game === 5)
                const d2d = filteredResponse.find(x => x.id_game === 4)
                setMenang({
                    d4, d3, d2t, d2d
                })
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }
        fetchData()
    }, [])
    return (
        <div className="tab-body-4D-QuickBet" style={{backgroundColor: getBackgroundColor(bgrColor)}}>
            {loading && <LoadingUi/>}
            <div style={{padding: "12px 0"}}>
                <p className="tab-text">Quick Bet - Bet Pannel IDR 1.000 = 1</p>
                <p style={{textAlign: 'end'}} className="tab-text">Total Bet: {totalBet}</p>
            </div>

            <div className="table-display" style={{flexDirection: "column", gap: "12px", padding: "20px 0"}}>
                <div className="table-display">
                    <p className="tab-text font-12">Posisi</p>
                    <select className="table-select select-4D-QuickBet font-12" onChange={(e) => setTypeBet(Number(e.target.value))}>
                        <option value={1}>4D, 3D, 2D BELAKANG</option>
                        <option value={2}>2D TENGAH</option>
                        <option value={3}>2D DEPAN</option>
                    </select>
                </div>

                <div className="table-display table-display-BetFull">
                    <div className="table-display">
                        <p className="tab-text font-12">Beli 4D</p>
                        <input type="number" min={menang.d4.minBetLimit}
                               disabled={type > 1}
                               value={betTypes['4D']}
                               onChange={(e) => handleInputChange(e, 'd4', '4D')}
                               className="table-input table-input-BetFull" alt="input"/>
                    </div>
                    <div className="table-display">
                        <p className="tab-text font-12">3D</p>
                        <input type="number" min={menang.d3.minBetLimit}
                               disabled={type > 1}
                               value={betTypes['3D']}
                               onChange={(e) => handleInputChange(e, 'd3', '3D')}
                               className="table-input table-input-BetFull" alt="input"/>
                    </div>
                    <div className="table-display">
                        <p className="tab-text font-12">2D</p>
                        <input type="number" min={menang.d2d.minBetLimit}
                               value={betTypes['2D']}
                               onChange={(e) => handleInputChange(e, 'd2d', '2D')}
                               className="table-input table-input-BetFull" alt="input"/>

                        <span className='qB-1000'>1000</span>
                    </div>
                </div>
                <div className="table-display">
                    <p className="tab-text">Nomor</p>
                </div>
            </div>

            {/* Textarea chỉ cho phép nhập số */}
            <textarea
                id={'ta'}
                className="content-BetFull"
                value={content}
                inputMode="numeric"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                rows="10"
            ></textarea>

            <div className="table-group-btn">
                <button className="table-btn font-10" onClick={handleSubmit}>SUBMIT</button>
                <button style={{background: "#D0453B"}} className="table-btn font-10">HAPUS</button>
            </div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalBody>
                    <Table>
                        <thead className='tr-number-Digital'>
                        <tr>
                            <th style={{border: 'none'}}>No.</th>
                            <th style={{border: 'none'}}>Bet Type</th>
                            <th style={{border: 'none'}}>Number</th>
                            <th style={{border: 'none'}}>Amount</th>
                        </tr>

                        </thead>

                        <tbody>
                        {dataReview?.betTypes.filter(x => x.betTypes.includes('4D')).map((b, i) =>
                            <tr key={b.number}>
                                <td style={{border: 'none'}}>{i + 1}</td>
                                <td style={{border: 'none'}}>{b.betTypes}</td>
                                <td style={{border: 'none'}}>{b.number}</td>
                                <td style={{border: 'none'}}>{numeral(b.amount).format('0,0')}</td>
                            </tr>
                        )}
                        {dataReview?.betTypes.filter(x => x.betTypes.includes('3D')).map((b, i) =>
                            <tr key={b.number}>
                                <td style={{border: 'none'}}>{i + 1}</td>
                                <td style={{border: 'none'}}>{b.betTypes}</td>
                                <td style={{border: 'none'}}>{b.number}</td>
                                <td style={{border: 'none'}}>{numeral(b.amount).format('0,0')}</td>
                            </tr>
                        )}
                        {dataReview?.betTypes.filter(x => x.betTypes.includes('2D')).map((b, i) =>
                            <tr key={b.number}>
                                <td style={{border: 'none'}}>{i + 1}</td>
                                <td style={{border: 'none'}}>{b.betTypes}</td>
                                <td style={{border: 'none'}}>{b.number}</td>
                                <td style={{border: 'none'}}>{numeral(b.amount).format('0,0')}</td>
                            </tr>
                        )}
                        <tr>
                            <th style={{border: 'none', textAlign: 'center'}} colSpan={3}>Total Bet:</th>
                            <th style={{border: 'none'}}>{numeral(totalBet).format('0,0')}</th>

                        </tr>
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter style={{textAlign: 'center', display: 'block'}}>
                    <Button color="success" onClick={submit}>
                        Submit
                    </Button>{' '}
                    <Button color="danger" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <Dialog/>
            <DialogError/>
        </div>
    );
}

export default BETDISKONTabQB;
