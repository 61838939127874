import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import store from 'store'
import config from './config'
import en from './assets/langs/en'
import idn from './assets/langs/in'
import th from './assets/langs/th'
import ma from './assets/langs/Myanmar'
import my from './assets/langs/Malay'
import cn from './assets/langs/cn'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: en
  },
  cn: {
    translation: cn
  },
  idn: {
    translation: idn
  },
  th: {
    translation: th
  },
  ma: {
    translation: ma
  },
  my: {
    translation: my
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: store.get('lang') || config.lang,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

  export default i18n