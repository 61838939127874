import React, { useState, useEffect } from 'react';
import http from "../../../../../../service/http";
import {useDialog, useLoading} from "../../../../../../Component";
import LoadingUi from '../../../../../../Component/Loading/ui'
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import numeral from "numeral";
function BETDISKONTab_BB({getBackgroundColor, getReCeipt, bgrColor, isDisCount, betType, arrayCreateQuickbet, market, periode}) {
    const [loading, withLoading] = useLoading()
    const [Dialog, showDialogWithMessage] = useDialog({
        title: 'SUCCESS'
    })
    const [DialogError, showDialogErrorWithMessage] = useDialog({
        title: 'ERROR',
        type: 'error'
    })

    const [dataReview, setDataReview] = useState(null)
    const [totalBet, setTotalbet] = useState(0);
    const [numberInput, setNumberInput] = useState('')
    const [menangs, setmenangs] = useState([])
    const [menang, setMenang] = useState({
        1: {},
        2: {},
        3: {},
        4: {},
        5: {},
    })
    const [betAmounts, setBetAmounts] = useState({
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
    })

    const [modal, setModal] = useState(false);
    let localbets = []
    const runReceipt = () => {
        getReCeipt(localbets)
    }
    const toggle = () => setModal(!modal);
    const handleSubmit = async () => {
        let _totalBet = 0
        const payload = {
            betTypes: [],
            period: periode.length > 0 ? periode[0].no + 1 : 1,
            code: market.code,
            pid: market._id
        }
            if(numberInput.length >= 4 && betAmounts[1] > 0) {
                const arr = arrayCreateQuickbet(numberInput.split(''), 4)
                arr.map(a => {
                    payload.betTypes.push({
                        number: a.toString().replaceAll(',', ''),
                        betTypes: '4D',
                        id_game: 1,
                        amount: betAmounts[1] * 1000

                    })
                    _totalBet += betAmounts[1] * 1000
                })
            }
            if(numberInput.length >= 2) {
                const arr = arrayCreateQuickbet(numberInput.split(''), 2)
                if(betAmounts[3] > 0) {
                    arr.map(a => {
                        payload.betTypes.push({
                            number: a.toString().replaceAll(',', ''),
                            betTypes: '2D Belakang',
                            id_game: 3,
                            amount: betAmounts[3] * 1000

                        })
                        _totalBet += betAmounts[3] * 1000
                    })
                }
                if(betAmounts[4] > 0) {
                    arr.map(a => {
                        payload.betTypes.push({
                            number: a.toString().replaceAll(',', ''),
                            betTypes: '2D Depan',
                            id_game: 4,
                            amount: betAmounts[4] * 1000

                        })
                        _totalBet += betAmounts[4] * 1000

                    })
                }
                if(betAmounts[5] > 0) {
                    arr.map(a => {
                        payload.betTypes.push({
                            number: a.toString().replaceAll(',', ''),
                            betTypes: '2D Tengah',
                            id_game: 5,
                            amount: betAmounts[4] * 1000

                        })
                        _totalBet += betAmounts[4] * 1000

                    })
                }
            }
            if(numberInput.length >= 3 && betAmounts[2] > 0) {
                const arr = arrayCreateQuickbet(numberInput.split(''), 3)
                arr.map(a => {
                    payload.betTypes.push({
                        number: a.toString().replaceAll(',', ''),
                        betTypes: '3D',
                        id_game: 2,
                        amount: betAmounts[2] * 1000

                    })
                    _totalBet += betAmounts[2] * 1000

                })
            }
            // console.log(payload.betTypes)
        setTotalbet(_totalBet)
        if(payload.betTypes.length > 0) {
            payload.betTypes = payload.betTypes.map(p => {
                p.betTypes = (isDisCount ? '' : betType + '-') + p.betTypes
                return p
            })
            setDataReview(payload)
            toggle()

        }
    }
    const submit = async () => {
        toggle()
        if(dataReview?.betTypes.length > 0) {
            try {
                const betrs = await withLoading(() => http.post({path: 'bet', payload: JSON.stringify(dataReview)}))
                const txid = betrs.toPage.split('&proses=')
                const betH = await withLoading(() => http.get({path: 'users/bets/' + txid[1]}))
                if (betrs.message && betrs.message !== '') {
                    if (betH.length > 0) {
                        localbets = betH
                        showDialogErrorWithMessage(betrs.message, runReceipt)
                    }
                    else showDialogErrorWithMessage(betrs.message)
                }
                else {
                    getReCeipt(betH)
                }

            } catch (error) {
                if(error.message === 'Failed to fetch') {
                    const betH = await withLoading(() => http.get({path: 'users/bets/-1'}))
                    getReCeipt(betH)
                }
                else showDialogErrorWithMessage(error.message)
            }
        }
    }
    const updateNumberInput = (e) => {
        const value = e.target.value
        setNumberInput(value)
    }
    const handleBlur = (e) => {
        if(numberInput.length > 9) setNumberInput(numberInput.slice(0, 9))
        if(isNaN(Number(numberInput))) setNumberInput('')
    }
    const handleKeyDown = (event) => {
        if(event.target.value.length > 8) alert('only 9 digital')
        // alert(event.keyCode)
        // alert(event.target.value.length)

        // if(![8, 13, 37].includes(event.keyCode) && !(event.keyCode >= 49 && event.keyCode <= 57)) alert('only digital')
        if (![8, 13, 37].includes(event.keyCode) && (event.target.value.length > 8 || (!(event.keyCode >= 49 && event.keyCode <= 57) && !(event.keyCode >= 97 && event.keyCode <= 105)))) {
            event.preventDefault();
        }
    }
    const fetchData = async () => {
        try {
            const response = await http.get({
                path: 'users/menang/' + market._id,
            })
            const filteredResponse = response.filter(item => [1,2,3,4,5].includes(item.id_game) && (isDisCount ? !item.ket.includes('-') : item.ket.includes(betType)))
            setmenangs(filteredResponse)
            const d4 = filteredResponse.find(x => x.id_game === 1)
            const d3 = filteredResponse.find(x => x.id_game === 2)
            const d2 = filteredResponse.find(x => x.id_game === 3)
            const d2Depan = filteredResponse.find(x => x.id_game === 4)
            const d2Tengah = filteredResponse.find(x => x.id_game === 5)
            setMenang({
                1: d4,
                2: d3,
                3: d2,
                4: d2Depan,
                5: d2Tengah,
            })
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }
    useEffect(() => {
        fetchData()
    },[])
    const handleInputChange2 = (event, index) => {
        let value = event.target.value * 1;
        // const minBetLimit = menang[index].minBetLimit / 1000
        // if(value < minBetLimit) value = minBetLimit
        if(event.target.value === '' || value > 0) {
            const betAmountss = {...betAmounts}
            betAmountss[index] = event.target.value
            setBetAmounts(betAmountss)
        }
    }
    return (
        <div className="tab-body" style={{backgroundColor: getBackgroundColor(bgrColor)}}>
            {loading && <LoadingUi/>}
            <div style={{padding: "12px 0"}}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <p className="tab-text font-12">BB Bet Pannel IDR 1.000 = 1</p>
                    <p className="tab-text font-12">Input nomor 4 digit / 9 digit</p>
                </div>
                <p className='font-12' style={{display: "flex", justifyContent: "end"}}>Total Bet: 0</p>
            </div>

            <div className="table-display-BB-C" style={{flexDirection: "column", gap: "12px", padding: "20px 0"}}>
                <div className="table-display-BB-C">
                    <p className="tab-text tab-text-4D-BBC">Nomor</p>
                    <input className="table-select table-select-4D-BBC" placeholder='4 digit / 9 digit'
                           value={numberInput}
                           inputMode="numeric"
                           onChange={(e) => updateNumberInput(e)}
                           onKeyDown={handleKeyDown}
                           onBlur={handleBlur}
                    />
                </div>

                <div>

                    <div className="table-display-BB-C">
                        <div className="table-display-BB-C-1">
                            <div className='tab-text-Col1-BBC'>
                                <p className="tab-text font-12">Beli</p>
                                <p className="tab-text font-12 mgr17-BBC">4D</p>
                            </div>
                            <input type="number" min={menang[1].minBetLimit / 1000} value={betAmounts[1]}
                                   onChange={(event) => handleInputChange2(event, 1)}
                                   className="table-input tab-text-BB-C" alt="input"/>
                        </div>
                        <div className="table-display-BB-C-2">
                            <p className="tab-text tab-text-Col2-BBC tab-text-Col2-BBC-3D font-12">3D</p>
                            <input type="number" min={menang[2].minBetLimit / 1000} value={betAmounts[2]}
                                   onChange={(event) => handleInputChange2(event, 2)}
                                   className="table-input tab-text-BB-C" alt="input"/>
                        </div>
                        <div className="table-display-BB-C-3">
                            <div className='tab-text-Col2-BBC'>
                                <p className="tab-text font-12">2D</p>
                                <p className="tab-text font-12 mgr17-BBC">Depan</p>
                            </div>
                            <input type="number" min={menang[4].minBetLimit / 1000} value={betAmounts[4]}
                                   onChange={(event) => handleInputChange2(event, 4)}
                                   className="table-input tab-text-BB-C" alt="input"/>
                        </div>
                    </div>

                    <div style={{marginTop: "30px"}} className="table-display-BB-C">
                        <div className="table-display-BB-C-1">
                            <div className='tab-text-Col1-BBC'>
                                <p className="tab-text font-12">2D</p>
                                <p className="mgr17-BBC tab-text font-12">Tengah</p>
                            </div>
                            <input type="number" min={menang[5].minBetLimit / 1000} value={betAmounts[5]}
                                   onChange={(event) => handleInputChange2(event, 5)}
                                   className="table-input tab-text-BB-C" alt="input"/>
                        </div>
                        <div className="table-display-BB-C-2">
                            <div className='tab-text-Col2-BBC'>
                                <p style={{margin: '0 20px 0 0'}} className="tab-text tab-text-2D-under font-12">2D</p>
                                <p className="mgr17-BBC tab-text font-12">Belakang</p>
                            </div>
                            <input type="number" min={menang[3].minBetLimit / 1000} value={betAmounts[3]}
                                   onChange={(event) => handleInputChange2(event, 3)}
                                   className="table-input hhgg tab-text-BB-C" alt="input"/>
                        </div>
                        <div className="table-display-BB-C-3">
                            <div className='tab-text-Col2-BBC'>
                                <p className="tab-text font-12">x1000</p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>


            <div className="table-group-btn">
                <button className="table-btn font-10" onClick={handleSubmit}>Review</button>
            </div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalBody>
                    <Table>
                        <thead className='tr-number-Digital'>
                        <tr>
                            <th style={{border: 'none'}}>No.</th>
                            <th style={{border: 'none'}}>Bet Type</th>
                            <th style={{border: 'none'}}>Number</th>
                            <th style={{border: 'none'}}>Amount</th>
                        </tr>

                        </thead>

                        <tbody>
                        {dataReview?.betTypes.filter(x => x.betTypes === '4D').map((b, i) =>
                            <tr key={b.number}>
                                <td style={{border: 'none'}}>{i + 1}</td>
                                <td style={{border: 'none'}}>{b.betTypes}</td>
                                <td style={{border: 'none'}}>{b.number}</td>
                                <td style={{border: 'none'}}>{numeral(b.amount).format('0,0')}</td>
                            </tr>
                        )}
                        {dataReview?.betTypes.filter(x => x.betTypes === '3D').map((b, i) =>
                            <tr key={b.number}>
                                <td style={{border: 'none'}}>{i + 1}</td>
                                <td style={{border: 'none'}}>{b.betTypes}</td>
                                <td style={{border: 'none'}}>{b.number}</td>
                                <td style={{border: 'none'}}>{numeral(b.amount).format('0,0')}</td>
                            </tr>
                        )}
                        {dataReview?.betTypes.filter(x => x.betTypes.includes('2D')).map((b, i) =>
                            <tr key={b.number}>
                                <td style={{border: 'none'}}>{i + 1}</td>
                                <td style={{border: 'none'}}>{b.betTypes}</td>
                                <td style={{border: 'none'}}>{b.number}</td>
                                <td style={{border: 'none'}}>{numeral(b.amount).format('0,0')}</td>
                            </tr>
                        )}
                        <tr>
                            <th style={{border: 'none', textAlign: 'center'}} colSpan={3}>Total Bet:</th>
                            <th style={{border: 'none'}}>{numeral(totalBet).format('0,0')}</th>

                        </tr>
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter style={{textAlign: 'center', display: 'block'}}>
                    <Button color="success" onClick={submit}>
                        Submit
                    </Button>{' '}
                    <Button color="danger" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>

            <Dialog/>
            <DialogError/>
        </div>
    );
}

export default BETDISKONTab_BB;

 
