import '../../index.css'
import http from "../../../../service/http";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
function Result({setPage}) {
    const [market, setMarket] = useState(null)
    const [markets, setMarkets] = useState([])
    const [data, setData] = useState([])

    const selectMarket = (id) => {
        const m = markets.find(x => x._id === id)
        setMarket(m)
    }
    const fetchDataResult = async () => {
        try {
            const data = await http.get({ path: 'periodes/' + market._id });
            setData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const fetchData = async () => {
        try {
            const data = await http.get({ path: 'pasarans' });
            setMarkets(data);
            setMarket(data[0])
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        if(market) fetchDataResult();
    }, [market])
    useEffect(() => {
        fetchData();
    }, [])
    return (

        <div style={{marginTop: "20px"}}>
            <Row>
                <Col xs={8}>
                    <div style={{display: 'flex', marginBottom: 20}}>
                        <div className="history-button">
                            <button className='history-list-button' style={{
                                background: 'red',
                                color: 'white'
                            }}>RESULT
                            </button>

                        </div>
                        <div className="history-button">
                            <button onClick={() => setPage(1)} className='history-list-button' style={{
                                background: 'gray',
                                color: '#000'
                            }}>INVOICE
                            </button>

                        </div>
                        <div className="history-button">
                            <button onClick={() => setPage(2)} className='history-list-button' style={{
                                background: 'gray',
                                color: '#000'
                            }}>TRANSACTION HISTORY
                            </button>

                        </div>
                    </div>
                </Col>
                <Col xs={4} style={{textAlign: 'right'}}>
                    {market && <select className='result-select f9'
                            value={market._id} onChange={e => selectMarket(e.target.value)}>
                        {markets.map(m => <option value={m._id}>{m.code}</option>)}
                    </select>}
                </Col>
            </Row>
            <table className="table-h5 f12">
                <tr style={{color: "white", backgroundColor: "red"}}>
                    <th className="th-h5" style={{width: "25%"}}>DAY</th>
                    <th className="th-h5" style={{width: "25%"}}>DATE</th>
                    <th className="th-h5" style={{width: "25%"}}>PERIODE</th>
                    <th className="th-h5" style={{width: "25%"}}>RESULT</th>
                </tr>

                {data.map((item, index) => (
                    <tr key={index} style={{color: "black"}}>
                        <td className="td-h5">{moment(item.tgl).format('dddd')}</td>
                        <td className="td-h5">{item.tgl}</td>
                        <td className="td-h5">{item.no + ' - ' + item.code_pasaran}</td>
                        <td className="td-h5"><b>{item.keluaran}</b></td>
                    </tr>
                ))}
            </table>
        </div>
    )
}

export default Result;