import './report.css'
import React, {useEffect, useState} from "react";
import http from "../../service/http";
import DatePicker from "react-datepicker";
import {Link} from 'react-router-dom';
import BettingDetila from './BettingDetila'

import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import LoadingUi from "../../Component/Loading/ui";
import {useDialog, useLoading} from "../../Component";
import numeral from 'numeral'
import ImgBack from '../../assets/img/icons/back.png'

import {Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

const BettingReport = (props) => {
    const [markets, setMarkets] = useState([])
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [history, sethistory] = useState([])
    const [gameCode, setGameCode] = useState('Game Code')
    const [period, setPeriod] = useState('')
    const [bets, setBets] = useState([])

    const [total, setTotal] = useState({
        bayar: 0,
        jumlah: 0,
        winlose: 0
    })

    const [loading, withLoading] = useLoading()
    const [DialogError, showDialogErrorWithMessage] = useDialog({
        title: 'ERROR',
        type: 'error'
    })
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = (e) => {
        setGameCode(e.target.value)
        setDropdownOpen((prevState) => !prevState);
    }
    const updatePidDetail = async (pasaran, id_game) => {
        const params = {
            fromDate: moment(startDate).format('YYYY-MM-DD 00:00:00'),
            toDate: moment(endDate).format('YYYY-MM-DD 23:59:59'),
            pid: pasaran.pid,
            id_game
        }
        if (period !== '') params.periode = period
        try {
            const betrs = await withLoading(() => http.get({path: 'report/bets', params}))
            console.log(betrs)
            setBets(betrs)

        } catch (error) {
            showDialogErrorWithMessage(error.message)

        }
    }

    const submit = async () => {
        // console.log("Handle Submit Betting Report")
        setBets([])
        const params = {
            fromDate: moment(startDate).format('YYYY-MM-DD 00:00:00'),
            toDate: moment(endDate).format('YYYY-MM-DD 23:59:59')
        }
        if (period !== '') params.periode = period
        if (gameCode !== 'Game Code') {
            console.log(gameCode) //SY or HK trong API https://api.hksydney.com/report/betlist
            const p = markets.find(x => x.code === gameCode)
            if (p) params.pid = p._id

            try {
                const betrs = await withLoading(() => http.get({path: 'report/betlist', params}))
                // console.log(bets)
                sethistory(betrs)
                if (betrs.length > 0) {
                    if (betrs.length === 1) setTotal({
                        bayar: betrs[0].bayar,
                        jumlah: betrs[0].jumlah,
                        winlose: betrs[0].winlose,
                    })
                    else setTotal(betrs.reduce((a, b) => {
                        return {
                            bayar: a.bayar + b.bayar,
                            jumlah: a.jumlah + b.jumlah,
                            winlose: Number(a.winlose) + Number(b.winlose),
                        }
                    }))
                } else {
                    setTotal({
                        bayar: 0,
                        jumlah: 0,
                        winlose: 0
                    })
                }
            } catch (error) {
                showDialogErrorWithMessage(error.message)

            }
        }

    }
    const onchangePeriode = (e) => {

        let value = Number(e.target.value)
        if (e.target.value === '' || value > 0) {
            setPeriod(e.target.value)
        }
    }

    const fetchData = async () => {

        try {
            const data = await http.get({path: 'pasarans'})
            setMarkets(data)

        } catch (error) {
            console.error('Error fetching data:', error)
        }
    };
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className="report-wrapper">
            {loading && <LoadingUi/>}
            {bets.length === 0 && <div className="report-container">
                <div style={{display: "flex", justifyContent: "start", width: "100%"}}>
                    <img style={{cursor: "pointer"}} className="bettingRe-IconBack" src={ImgBack}
                         onClick={props.history.goBack}/>
                    <p className='bettingRe-title' style={{alignSelf: "center"}}>Report</p>
                </div>
                <Row className="showRa showRa-report" style={{width: '100%', margin: '20px 0'}}>
                    <Col
                        sm="5"
                        xs="8"
                        style={{padding: 0}}
                    >
                        <div className="inputBettingRe" style={{
                            display: 'flex',
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "10px",
                            width: "100%"
                        }}>
                            <DatePicker className={'report-input f9 report-input-mobile'} dateFormat={'dd/MM/yyyy'}
                                        selected={startDate} onChange={(date) => setStartDate(date)}/>
                            <DatePicker className={'report-input f9 report-input-mobile'} dateFormat={'dd/MM/yyyy'}
                                        selected={endDate} onChange={(date) => setEndDate(date)}/>
                        </div>
                    </Col>
                    <Col
                        style={{display: 'flex', padding: 0, margin: '10px 0'}}
                        sm="5"
                        xs="8"
                    >
                        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={'down'}
                                  className="report-input-mobile report-select-mobile" style={{}}>
                            <DropdownToggle className='selectGameBettingRp' caret style={{padding: 0}}><span
                                style={{color: gameCode === 'Game Code' ? 'gray' : '#000'}}>{gameCode}</span></DropdownToggle>
                            <DropdownMenu>
                                {gameCode !== 'Game Code' && <DropdownItem value={'Game Code'}>Game Code</DropdownItem>}
                                {markets.map(m => <DropdownItem key={m.code} value={m.code}>{m.code}</DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>

                        <input className='report-input f9 report-input-mobile'
                               placeholder="Periode"
                               type="number"
                               inputMode="numeric"
                               value={period}
                               style={{width: '40%'}}
                               onChange={(e) => onchangePeriode(e)}/>
                    </Col>

                    <Col
                        sm="2"
                        xs={4}
                        style={{padding: 0}}
                    >
                        <button style={{float: 'right'}} className='report-btn-search' onClick={submit}>Search</button>
                    </Col>
                </Row>
                <Row className="anDi" style={{width: '100%', margin: '20px 0' }}>
                    <Col
                        style={{display: 'flex'}}
                        sm="5"
                        xs="12"
                    >
                        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={'down'}
                                  className="report-input-mobile" style={{marginRight: 20}}>
                            <DropdownToggle className='selectGameBettingRp' caret><span
                                style={{color: gameCode === 'Game Code' ? 'gray' : '#000'}}>{gameCode}</span></DropdownToggle>
                            <DropdownMenu>
                                {gameCode !== 'Game Code' && <DropdownItem value={'Game Code'}>Game Code</DropdownItem>}
                                {markets.map(m => <DropdownItem key={m.code} value={m.code}>{m.code}</DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>

                        <input className='report-input f9 report-input-mobile'
                               placeholder="Periode"
                               type="number"
                               inputMode="numeric"
                               value={period}
                               onChange={(e) => onchangePeriode(e)}/>
                    </Col>
                    <Col
                        sm="5"
                        xs="12"
                    >
                        <div className="inputBettingRe" style={{
                            display: 'flex',
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "12px",
                            width: "440px"
                        }}>
                            <DatePicker className={'report-input f9 report-input-mobile'} dateFormat={'dd/MM/yyyy'}
                                        selected={startDate} onChange={(date) => setStartDate(date)}/>
                            <p style={{margin: '0', fontSize: 'inherit'}}>To</p>
                            <DatePicker className={'report-input f9 report-input-mobile'} dateFormat={'dd/MM/yyyy'}
                                        selected={endDate} onChange={(date) => setEndDate(date)}/>
                        </div>
                    </Col>
                    <Col
                        sm="2"
                    >
                        <button className='report-btn-search' onClick={submit}>Search</button>
                    </Col>
                </Row>
                <table id="report" style={{width: "100%", background: "#1C4587", textAlign: 'center'}}>
                    <tr className='f9' style={{fontSize: 25, fontWeight: 'bold'}}>
                        <th style={{borderRight: 0, paddingLeft: "5%"}} colSpan={2}>Total
                            Bayar: {numeral(total.bayar).format('0,0')}</th>
                        <th style={{borderRight: 0, borderLeft: 0}} colSpan={3}>Total
                            Win: {numeral(total.jumlah).format('0,0')}</th>
                        <th style={{borderLeft: 0}} colSpan={2}>Total
                            Profit: <span>{numeral(total.winlose).format('0,0')}</span></th>
                    </tr>
                    <tr className='f9'>
                        <th style={{width: "5%"}}>No</th>
                        <th style={{width: "15%"}}>Bet Type</th>
                        <th style={{width: "15%"}}>Beli</th>
                        <th style={{width: "15%"}}>Bayar</th>
                        <th style={{width: "15%"}}>Win</th>
                        <th style={{width: "15%"}}>Total Winlose</th>
                    </tr>
                    {history.map((h, i) => <tr key={h.games[0].game + h._id.pid}>
                        <td className='f9'>{i + 1}</td>

                        <td style={{cursor: "pointer"}} className='f9'>
                            <span onClick={() => updatePidDetail(h._id, h.games[0].id_game)}>{h.games[0].game}</span>
                        </td>

                        <td className='f9'>{numeral(h.taruhan).format('0,0')}</td>
                        <td className='f9'>{numeral(h.bayar).format('0,0')}</td>
                        <td className='f9'>{numeral(h.jumlah).format('0,0')}</td>
                        <td className='f9'><span
                            style={{color: Number(h.winlose) > 0 ? '#0df04c' : '#d7868e'}}>{numeral(h.winlose).format('0,0')}</span>
                        </td>
                    </tr>)}
                </table>
            </div>}
            {bets.length > 0 && <BettingDetila bets={bets} setBets={setBets}/>}
            <DialogError/>
        </div>
    )
}

export default BettingReport;