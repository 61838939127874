import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import './style.css'

function ResponsiveDialog(props) {
  const { message, title, btnLabel, type } = props
  const [options, upadteOptions] = React.useState({message, image: '', title, btnLabel})

  function showWithMessage(msg, callback) {

    upadteOptions({
      ...options,
      message: msg,
      image: '',
      callback
    })
  }
  function showWithImage(image, callback) {
    upadteOptions({
      ...options,
      message: '',
      image,
      callback
    })
  }

  function showWithConfig(configs, callback) {
    upadteOptions({
      ...options,
      ...configs,
      callback
    })
  }

  const [open, setOpen] = React.useState(false)

  function handleClose() {
    setOpen(false)
  }

  React.useEffect(() => {
// console.log(options)
    const opened = ((options.message && options.message?.length) || (options.image && options.image?.length > 0))
    setOpen(opened)
  }, [options])

  return [() =>
    <div>
      <Dialog
        open={Boolean(open)}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
            style={{minWidth: 300}}
            className={type === 'error' ? 'error' : 'success'} id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent style={{padding: 0}}>
          {options.image.length > 0 && <DialogContentText>
            <img src={options.image} alt=""/>
          </DialogContentText>}
          {options.image.length === 0 && <DialogContentText style={{padding: 10}}>

            {options.message?.includes('<br>') ? <>{options.message.split('<br>').map(m => <span>{m}<br/></span>)}</>: options.message}
          </DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={options.callback || handleClose} color="primary">
            {btnLabel || 'Okay'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ,
  showWithMessage, showWithImage, showWithConfig]
}

export default ResponsiveDialog
