import '../../games.css'
import {useDialog, useLoading} from "../../../../Component";
import React, {useEffect, useState} from "react";
import http from "../../../../service/http";
import LoadingUi from "../../../../Component/Loading/ui";
import numeral from "numeral";

const tebaks = ['Ganjil', 'Genap', 'Besar', 'Kecil']
const Kombinasi = ({market, periode, betHistory, getReCeipt}) => {
    const init = [
        { tebak1: "", tebak2: "", kei: '', amount: '', posisi: '' },
        { tebak1: "", tebak2: "", kei: '', amount: '', posisi: '' },
        { tebak1: "", tebak2: "", kei: '', amount: '', posisi: '' },
        { tebak1: "", tebak2: "", kei: '', amount: '', posisi: '' },
        { tebak1: "", tebak2: "", kei: '', amount: '', posisi: '' },
    ]
    const init2 = [
        { tebak1: "", tebak2: "", kei: '', amount: '', posisi: '' },
        { tebak1: "", tebak2: "", kei: '', amount: '', posisi: '' },
        { tebak1: "", tebak2: "", kei: '', amount: '', posisi: '' },
        { tebak1: "", tebak2: "", kei: '', amount: '', posisi: '' },
        { tebak1: "", tebak2: "", kei: '', amount: '', posisi: '' },
    ]
    const init3 = [
        { tebak1: "", tebak2: "", kei: '', amount: '', posisi: '' },
        { tebak1: "", tebak2: "", kei: '', amount: '', posisi: '' },
        { tebak1: "", tebak2: "", kei: '', amount: '', posisi: '' },
        { tebak1: "", tebak2: "", kei: '', amount: '', posisi: '' },
        { tebak1: "", tebak2: "", kei: '', amount: '', posisi: '' },
    ]
    const [loading, withLoading] = useLoading()
    const [DialogError, showDialogErrorWithMessage] = useDialog({
        title: 'ERROR',
        type: 'error'
    })
    const [totalBet, setTotalBet] = useState(0)

    const [autobet, setAutoBet] = useState(['', '', ''])
    const [bets, setBets] = useState(init)
    const [bets2, setBets2] = useState(init2)

    const [bets3, setBets3] = useState(init3)
    const calTotalBet = (data) => {
        const arr = data.filter(x => x.tebak1 !== '' && x.tebak2 !== '')
        if(arr.length > 0) setTotalBet(arr.reduce((a, b) => {
            return {
                amount: Number(a.amount) + Number(b.amount)
            }
        }).amount)
    }
    const onChangeAutoBet = (e) => {
        if(e.target.value === '' || Number(e.target.value) > 0) {
            const _bets = bets.map(b => {
                b.amount = e.target.value
                return b
            })
            setBets(_bets)
            calTotalBet(_bets.concat(bets2).concat(bets3))
            const _autobet = [...autobet]
            _autobet[0] = e.target.value
            setAutoBet(_autobet)
        }
    }
    const onChangeAutoBet2 = (e) => {
        if(e.target.value === '' || Number(e.target.value) > 0) {
            const _bets = bets2.map(b => {
                b.amount = e.target.value
                return b
            })
            setBets2(_bets)
            calTotalBet(_bets.concat(bets).concat(bets3))
            const _autobet = [...autobet]
            _autobet[1] = e.target.value
            setAutoBet(_autobet)
        }

    }
    const onChangeAutoBet3 = (e) => {
        if(e.target.value === '' || Number(e.target.value) > 0) {
            const _bets = bets3.map(b => {
                b.amount = e.target.value
                return b
            })
            setBets3(_bets)
            calTotalBet(_bets.concat(bets).concat(bets2))
            const _autobet = [...autobet]
            _autobet[2] = e.target.value
            setAutoBet(_autobet)
        }

    }
    const onChangeAmount = (e, index) => {
        const value = Number(e.target.value)
        if((value >= 0) || e.target.value === '') {
            const _bets = [...bets]

            // console.log(e.target.value, numbers, numbers.includes(e.target.value))
            _bets[index].amount = e.target.value
            calTotalBet(_bets.concat(bets2).concat(bets3))
            setBets(_bets)
        }
    }
    const onChangeAmount2 = (e, index) => {
        const value = Number(e.target.value)
        if((value >= 0) || e.target.value === '') {
            const _bets = [...bets2]

            // console.log(e.target.value, numbers, numbers.includes(e.target.value))
            _bets[index].amount = e.target.value
            calTotalBet(_bets.concat(bets).concat(bets3))
            setBets2(_bets)
        }
    }
    const onChangeAmount3 = (e, index) => {
        const value = Number(e.target.value)
        if((value >= 0) || e.target.value === '') {
            const _bets = [...bets3]

            // console.log(e.target.value, numbers, numbers.includes(e.target.value))
            _bets[index].amount = e.target.value
            calTotalBet(_bets.concat(bets).concat(bets2))
            setBets3(_bets)
        }
    }
    const submit = async () => {
        setBets(init)
        setBets2(init2)
        setBets3(init3)
        const payload = {
            betTypes: [],
            period: periode.length > 0 ? periode[0].no + 1 : 1,
            code: market.code,
            pid: market._id,
            gameId: 29
        }

        bets.filter(x => x.tebak1 !== '' && x.tebak2 !== '' && x.amount > 0).map(b => {
            payload.betTypes.push({
                number: b.tebak1 + ', ' + b.tebak2,
                amount: b.amount * 1000
            })
        })
        bets2.filter(x => x.tebak1 !== '' && x.tebak2 !== '' && x.amount > 0).map(b => {
            payload.betTypes.push({
                number: b.tebak1 + ', ' + b.tebak2,
                amount: b.amount * 1000
            })
        })
        bets3.filter(x => x.tebak1 !== '' && x.tebak2 !== '' && x.amount > 0).map(b => {
            payload.betTypes.push({
                number: b.tebak1 + ', ' + b.tebak2,
                amount: b.amount * 1000
            })
        })

        try {
            if(payload.betTypes.length === 0) showDialogErrorWithMessage('No bet record !')
            else {
                const betrs = await withLoading(() => http.post({path: 'bet2', payload: JSON.stringify(payload)}))
                if (betrs.message && betrs.message !== '') {
                    showDialogErrorWithMessage(betrs.message)
                    if (betrs.toPage) {
                        const txid = betrs.toPage.split('&proses=')
                        if (txid[1]) {
                            const betH = await withLoading(() => http.get({path: 'users/bets/' + txid[1]}))
                            if(betH.length > 0)getReCeipt(betH)
                            else showDialogErrorWithMessage('No Bet accepted')
                        }
                    }
                } else {
                    const txid = betrs.toPage.split('&proses=')
                    const betH = await withLoading(() => http.get({path: 'users/bets/' + txid[1]}))
                    getReCeipt(betH)
                }
            }
        } catch (error) {
            showDialogErrorWithMessage(error.message)

        }

    }
    const updateTebak = (e, index, key) => {
        const _tebak = [...bets]
        _tebak[index][key] = e.target.value
        setBets(_tebak)
    }
    const updateTebak2 = (e, index, key) => {
        const _tebak = [...bets2]
        _tebak[index][key] = e.target.value
        setBets2(_tebak)
    }
    const updateTebak3 = (e, index, key) => {
        const _tebak = [...bets3]
        _tebak[index][key] = e.target.value
        setBets3(_tebak)
    }
    return (
        <>{betHistory.length === 0 && <div>
            {loading && <LoadingUi/>}
            <div className="tab-overlow tab-overlow-KB">
                <div className="tab">
                    <div className='tab-styled-box'>
                        <div>
                            <p className="tab-title font-12">Kombinasi Bet Panel IDR 1.000 = 1</p>
                            <p style={{marginRight: 10}} className="tab-title tab-title-Total font-12">Total
                                Bet: {numeral(totalBet * 1000).format('0,0')}</p>
                        </div>

                        <table className='table'>
                            <tr className="table-thead table-thead-Kom">
                                <th className='table-thead-col01 font-12'>No.</th>
                                <th className='table-thead-col02 font-12'>Tebak As</th>
                                <th className='table-thead-col03 font-12'>Tebak Kop</th>
                                <th className='table-thead-col04 font-12'>Taruhan
                                    <input className='table-input-none table-input-none-KB-Auto'
                                           inputMode="numeric"
                                           value={autobet[0]} placeholder='auto bet'
                                           onChange={onChangeAutoBet}/>
                                    x1000
                                </th>
                            </tr>
                            {bets.map((i, ix) => (
                                <tr className="table-item">
                                    <th className='table-item-col-KB-01 font-10'>{ix + 1}</th>
                                    <select className='table-select table-select-KB-02 font-10' value={i.tebak1}
                                            onChange={e => updateTebak(e, ix, 'tebak1')}>
                                        <option className='font-10'></option>
                                        {tebaks.map(t => <option className='font-10'
                                                                 value={'As ' + t}>{'As ' + t}</option>)}
                                    </select>
                                    <select className='table-select table-select-KB-03 font-10' value={i.tebak2}
                                            onChange={e => updateTebak(e, ix, 'tebak2')}>
                                        <option className='font-10'></option>
                                        {tebaks.map(t => <option className='font-10'
                                                                 value={'Kop ' + t}>{'Kop ' + t}</option>)}
                                    </select>
                                    <div className="table-display table-select-KB-04">
                                        <input className='table-input-none table-input-none-KBS font-10'
                                               placeholder='X1000'
                                               inputMode="numeric"
                                               value={i.amount}
                                               onChange={(e) => onChangeAmount(e, ix)}/>
                                    </div>
                                </tr>
                            ))}

                        </table>


                        <table style={{marginTop: "8px"}} className='table'>
                            <tr className="table-thead table-thead-Kom">
                                <th className='table-thead-col01 font-12'>No.</th>
                                <th className='table-thead-col02 font-12'>Tebak Kepala</th>
                                <th className='table-thead-col03 font-12'>Tebak Ekor</th>
                                <th className='table-thead-col04 font-12'>Taruhan
                                    <input className='table-input-none table-input-none-KB-Auto'
                                           inputMode="numeric"
                                           value={autobet[1]} placeholder='auto bet'
                                           onChange={onChangeAutoBet2}/>
                                    x1000
                                </th>
                            </tr>
                            {bets2.map((i, ix) => (
                                <tr className="table-item">
                                    <th className='table-item-col-KB-01 font-10'>{ix + 1}</th>
                                    <select className='table-select table-select-KB-02 font-10' value={i.tebak1}
                                            onChange={e => updateTebak2(e, ix, 'tebak1')}>
                                        <option className='font-10'></option>
                                        {tebaks.map(t => <option className='font-10'
                                                                 value={'Kepala ' + t}>{'Kepala ' + t}</option>)}
                                    </select>
                                    <select className='table-select table-select-KB-03 font-10' value={i.tebak2}
                                            onChange={e => updateTebak2(e, ix, 'tebak2')}>
                                        <option className='font-10'></option>
                                        {tebaks.map(t => <option className='font-10'
                                                                 value={'Ekor ' + t}>{'Ekor ' + t}</option>)}
                                    </select>
                                    <div className="table-display table-select-KB-04">
                                        <input className='table-input-none table-input-none-KBS font-10'
                                               placeholder='X1000'
                                               inputMode="numeric"
                                               value={i.amount}
                                               onChange={(e) => onChangeAmount2(e, ix)}/>
                                    </div>
                                </tr>
                            ))}

                        </table>


                        <table style={{marginTop: "8px"}} className='table'>
                            <tr className="table-thead table-thead-Kom">
                                <th className='table-thead-col01 font-12'>No.</th>
                                <th className='table-thead-col02 font-12'>Tebak 1</th>
                                <th className='table-thead-col03 font-12'>Tebak 2</th>
                                <th className='table-thead-col04 font-12'>Taruhan
                                    <input className='table-input-none table-input-none-KB-Auto'
                                           inputMode="numeric"
                                           value={autobet[2]} placeholder='auto bet'
                                           onChange={onChangeAutoBet3}/>
                                    x1000
                                </th>
                            </tr>
                            {bets3.map((i, ix) => (
                                <tr className="table-item">
                                    <th className='table-item-col-KB-01 font-10'>{ix + 1}</th>
                                    <select className='table-select table-select-KB-02 font-10' value={i.tebak1}
                                            onChange={e => updateTebak3(e, ix, 'tebak1')}>
                                        <option className='font-10'></option>
                                        {tebaks.map(t => <>
                                                <option className='font-10' value={'As ' + t}>{'As ' + t}</option>
                                                <option className='font-10' value={'Kop ' + t}>{'Kop ' + t}</option>
                                            </>
                                        )}
                                    </select>
                                    <select className='table-select table-select-KB-03 font-10' value={i.tebak2}
                                            onChange={e => updateTebak3(e, ix, 'tebak2')}>
                                        <option className='font-10'></option>
                                        {tebaks.map(t => <>
                                                <option className='font-10' value={'Kepala ' + t}>{'Kepala ' + t}</option>
                                                <option className='font-10' value={'Ekor ' + t}>{'Ekor ' + t}</option>
                                            </>
                                        )}
                                    </select>
                                    <div className="table-display table-select-KB-04">
                                        <input className='table-input-none table-input-none-KBS font-10'
                                               placeholder='X1000'
                                               inputMode="numeric"
                                               value={i.amount}
                                               onChange={(e) => onChangeAmount3(e, ix)}/>
                                    </div>
                                </tr>
                            ))}

                        </table>
                        <div className="table-group-btn">
                            <button className="table-btn table-btn-BTN" onClick={submit}>Kirim</button>
                            <button style={{background: "#D0453B"}} className="table-btn table-btn-BTN">Hapus
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <DialogError/>
        </div>}
        </>
    )
}
export default Kombinasi