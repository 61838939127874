import '../../index.css'

const data = [
    
    {
        nation: "Singapore",
        openTime: "23:45:00",
        closeTime: "17:10:00",
        link: "www.facebook.com",
        status: "Offline"
    },
    {
        nation: "Singapore",
        openTime: "23:45:00",
        closeTime: "17:10:00",
        link: "www.facebook.com",
        status: "Offline"

    },
    {
        nation: "Singapore",
        openTime: "23:45:00",
        closeTime: "17:10:00",
        link: "www.facebook.com",
        status: "Online"
    }
]

function InforUmUm() {

    return (
        <div style={{marginTop: "5%", padding: "2%"}}>

            {data.map((item, index) => (

                <table className="table-h5" style={{color: "black"}} key={index}>
                    <tr className="limit-tr01">
                        <th className="th-h5">Parasan</th>
                        <th className="th-h5">Result</th>
                        <th className="th-h5">Open</th>
                        <th className="th-h5">Close</th>
                    </tr>

                    <tr>
                        <td className="td-h5 fontWeight1000">{item.nation}</td>
                        <td className="td-h5">{item.link}</td>

                        <td colspan="2" className={`td-h5 fontWeight1000 
                        ${item.status === "Online" ? "dashboard-IF-status-on" : "dashboard-IF-status-off"}`}>
                            {item.status}
                        </td>
                    </tr>

                    <tr>
                        <td className="td-h5">Open Time</td>
                        <td className="td-h5">{item.openTime}</td>
                        <td colspan="2" rowspan="2" className="td-h5 dashboard-IF-Enter">Enter</td>
                    </tr>
    
                    <tr>
                        <td className="td-h5">{item.closeTime}</td>
                        <td className="td-h5">17:10:00</td>
                    </tr>
                </table>
 
            ))}
        </div>
    )
}

export default InforUmUm