import { Container, Row, Col, Input, Button } from 'reactstrap';
import React, {useState} from "react";
import {useDialog, useLoading} from "../../../../Component";
import http from "../../../../service/http";
import LoadingUi from "../../../../Component/Loading/ui";
import numeral from "numeral";

const initbets = [
    { tebakan: "", tebak2: '', amount: '' },
    { tebakan: "", tebak2: '', amount: '' },
    { tebakan: "", tebak2: '', amount: '' },
    { tebakan: "", tebak2: '', amount: '' },
    { tebakan: "", tebak2: '', amount: '' },
    { tebakan: "", tebak2: '', amount: '' },
    { tebakan: "", tebak2: '', amount: '' },
    { tebakan: "", tebak2: '', amount: '' },
    { tebakan: "", tebak2: '', amount: '' },
    { tebakan: "", tebak2: '', amount: '' },
]
const Macau = ({market, periode, betHistory, getReCeipt}) => {
    const [totalBet, setTotalBet] = useState(0)
    const [autobet, setAutoBet] = useState('')
    const [bets, setBets] = useState(initbets)
    let localbets = []
    const runReceipt = () => {
        setBets([
            { tebakan: "", tebak2: '', amount: '' },
            { tebakan: "", tebak2: '', amount: '' },
            { tebakan: "", tebak2: '', amount: '' },
            { tebakan: "", tebak2: '', amount: '' },
            { tebakan: "", tebak2: '', amount: '' },
            { tebakan: "", tebak2: '', amount: '' },
            { tebakan: "", tebak2: '', amount: '' },
            { tebakan: "", tebak2: '', amount: '' },
            { tebakan: "", tebak2: '', amount: '' },
            { tebakan: "", tebak2: '', amount: '' },
        ])
        getReCeipt(localbets)
    }
    const [loading, withLoading] = useLoading()
    const [DialogError, showDialogErrorWithMessage] = useDialog({
        title: 'ERROR',
        type: 'error'
    })
    const calTotalBet = (data) => {
        const arr = data.filter(x => x.tebakan !== '' && x.tebak2 !== '')
        if(arr.length > 0) setTotalBet(arr.reduce((a, b) => {
            return {
                amount: Number(a.amount) + Number(b.amount)
            }
        }).amount)
    }
    const onChangeTebak = (e, index, key, idNext) => {
        const value = Number(e.target.value)
        if((e.target.value.length === 1 && value >= 0) || e.target.value === '') {
            const _bets = [...bets]

            const numbers = [_bets[index].tebakan, _bets[index].tebak2, _bets[index].tebak3]
            // console.log(e.target.value, numbers, numbers.includes(e.target.value))
            if(e.target.value !== '' && numbers.includes(e.target.value)) return
            _bets[index][key] = e.target.value
            calTotalBet(_bets)
            setBets(_bets)
            if(value > 0) {
                const ee = document.getElementById(idNext)
                ee.focus()
            }
        }
    }
    const onChangeAmount = (e, index) => {
        const value = Number(e.target.value)
        if((value >= 0) || e.target.value === '') {
            const _bets = [...bets]

            // console.log(e.target.value, numbers, numbers.includes(e.target.value))
            _bets[index].amount = e.target.value
            calTotalBet(_bets)
            setBets(_bets)
        }
    }
    const onChangeAutoBet = (e) => {
        if(e.target.value === '' || Number(e.target.value) > 0) {
            const _bets = bets.map(b => {
                b.amount = e.target.value
                return b
            })
            setBets(_bets)
            calTotalBet(_bets)
            setAutoBet(e.target.value)
        }
    }
    const submit = async () => {
        const payload = {
            betTypes: [],
            period: periode.length > 0 ? periode[0].no + 1 : 1,
            code: market.code,
            pid: market._id,
            gameId: 20
        }

        bets.filter(x => x.tebakan !== '' && x.tebak2 !== '' && x.amount > 0).map(b => {
            payload.betTypes.push({
                number: b.tebakan + b.tebak2,
                amount: b.amount * 1000
            })
        })

        try {
            if(payload.betTypes.length === 0) showDialogErrorWithMessage('No bet record !')
            else {
                const betrs = await withLoading(() => http.post({path: 'bet2', payload: JSON.stringify(payload)}))
                const txid = betrs.toPage.split('&proses=')
                const betH = await withLoading(() => http.get({path: 'users/bets/' + txid[1]}))
                if (betrs.message && betrs.message !== '') {
                    if (betH.length > 0) {
                        localbets = betH
                        showDialogErrorWithMessage(betrs.message, runReceipt)
                    }
                    else showDialogErrorWithMessage(betrs.message)

                } else {
                    setBets([
                        { tebakan: "", tebak2: '', amount: '' },
                        { tebakan: "", tebak2: '', amount: '' },
                        { tebakan: "", tebak2: '', amount: '' },
                        { tebakan: "", tebak2: '', amount: '' },
                        { tebakan: "", tebak2: '', amount: '' },
                        { tebakan: "", tebak2: '', amount: '' },
                        { tebakan: "", tebak2: '', amount: '' },
                        { tebakan: "", tebak2: '', amount: '' },
                        { tebakan: "", tebak2: '', amount: '' },
                        { tebakan: "", tebak2: '', amount: '' },
                    ])
                    getReCeipt(betH)
                }
            }
        } catch (error) {
            showDialogErrorWithMessage(error.message)

        }

    }
    return (

        <>{betHistory.length === 0 && <Row>
            {loading && <LoadingUi/>}
            <Col xs="12" lg="12">
                    <div className="tab-overlow tab-overlow-CN">
                        <div className="tab">
                            <div className='tab-styled-box'>
                                <div>
                                    <p className="tab-text font-12">Macau Bet Pannel IDR 1.000 = 1</p>
                                    <Col xs="11" lg="12">
                                        <p style={{marginRight: 10}} className="tab-text tab-title-Total font-12">Total Bet: {numeral(totalBet * 1000).format('0,0')}</p>
                                    </Col>
                                </div>

                                <div>
                                    <div className="table-thead">
                                        <div className="table-thead-M">

                                            <span className="col-M-01-1 font-12">No.</span>

                                            <div className='div-Tabakan-M-01'>
                                                <span className="font-12">Tebakan</span>
                                                <span className='col-CN-num font-12'>1</span>
                                            </div>

                                            <div className='div-Tabakan-M-02'>
                                                <span className="font-12">Tebakan</span>
                                                <span className='col-CN-num font-12'>2</span>
                                            </div>
                                        </div>

                                        <Col xs="12" lg="12" className="col-M-02">
                                            <span className='div-col-Right-CN font-12'>Taruhan
                                                <Input className='table-input-none table-input-none-CN font-10' placeholder='auto bet'
                                                       value={autobet}
                                                       inputMode="numeric"
                                                       onChange={onChangeAutoBet} />
                                                <span className='font-10'>x1000</span>

                                            </span>
                                        </Col>
                                    </div>

                                    <div>
                                        {bets.map((i, ix) => (
                                            <div className="table-item-M" key={ix}>
                                                <p className='tab-text item01-M font-12'>{ix + 1}</p>
                                                <Input className='item02-M font-10' placeholder='' inputMode="numeric"
                                                       value={i.tebakan}
                                                       onChange={(e) => onChangeTebak(e, ix, 'tebakan', 'tebak2' + ix)}/>
                                                <Input className='item03-M font-10' placeholder='' inputMode="numeric"
                                                       value={i.tebak2}
                                                       id={'tebak2' + ix}
                                                       onChange={(e) => onChangeTebak(e, ix, 'tebak2', 'amount' + ix)}/>
                                                
                                                <Col className="col-CN-02-M">
                                                    <Input className='table-input table-input-CN' placeholder=''
                                                           inputMode="numeric"
                                                           value={i.amount}
                                                           id={'amount' + ix}
                                                           onChange={(e) => onChangeAmount(e, ix)}/>
                                                    <p className='tab-text tab-text-1000-M'>X1000</p>
                                                </Col>
                                            </div>
                                        ))}
                                    </div>


                                    <div className="table-group-btn">
                                        <Button className="table-btn table-btn-BTN" onClick={submit}>Kirim</Button>
                                        <Button style={{ background: "#D0453B" }} className="table-btn table-btn-BTN">Hapus</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
            </Col>
            <DialogError/>
        </Row>}
        </>

    )
}

export default Macau;

