import { Col } from 'reactstrap';
import React, {useEffect, useState} from "react";
import http from "../../../../../service/http";
import LoadingUi from "../../../../../Component/Loading/ui";
import {useDialog, useLoading} from "../../../../../Component";

const GanjilGenap = ({dataTab, market, periode, betHistory, getReCeipt}) => {
    console.log("dataTab of GanjilGenap:", dataTab)
    console.log("dataTab.posisi of GanjilGenap:", dataTab.posisi)
    console.log("dataTab.tebak of GanjilGenap:", dataTab.tebak)
    const [loading, withLoading] = useLoading()
    const [DialogError, showDialogErrorWithMessage] = useDialog({
        title: 'ERROR',
        type: 'error'
    })
    const [totalBet, setTotalBet] = useState(0)
    const [autobet, setAutoBet] = useState('')
    const [bets, setBets] = useState([
    ])
    let localbets = []
    const runReceipt = () => {
        getReCeipt(localbets)
    }
    const calTotalBet = (data) => {
        const arr = data.filter(x => x.tebakan !== '')
        if(arr.length > 0) setTotalBet(arr.reduce((a, b) => {
            return {
                amount: Number(a.amount) + Number(b.amount)
            }
        }).amount)
    }
    const onChangeAutoBet = (e) => {
        if(e.target.value === '' || Number(e.target.value) > 0) {
            const _bets = bets.map(b => {
                b.amount = e.target.value
                return b
            })
            setBets(_bets)
            calTotalBet(_bets)
            setAutoBet(e.target.value)
        }

    }
    const onChangeAmount = (e, index) => {
        const value = Number(e.target.value)
        if((value >= 0) || e.target.value === '') {
            const _bets = [...bets]

            // console.log(e.target.value, numbers, numbers.includes(e.target.value))
            _bets[index].amount = e.target.value
            calTotalBet(_bets)
            setBets(_bets)
        }
    }
    const submit = async () => {
        const payload = {
            betTypes: [],
            period: periode.length > 0 ? periode[0].no + 1 : 1,
            code: market.code,
            pid: market._id,
            gameId: 25
        }

        bets.filter(x => x.tebakan !== '' && x.amount > 0).map(b => {
            payload.betTypes.push({
                number: b.number,
                amount: b.amount * 1000,
                posisi: b.posisi
            })
        })

        try {
            if(payload.betTypes.length === 0) showDialogErrorWithMessage('No bet record !')
            else {
                const betrs = await withLoading(() => http.post({path: 'bet3', payload: JSON.stringify(payload)}))
                const txid = betrs.toPage.split('&proses=')
                const betH = await withLoading(() => http.get({path: 'users/bets/' + txid[1]}))
                if (betrs.message && betrs.message !== '') {
                    if (betH.length > 0) {
                        localbets = betH
                        showDialogErrorWithMessage(betrs.message, runReceipt)
                    }
                    else showDialogErrorWithMessage(betrs.message)
                } else {
                    getReCeipt(betH)
                }
            }
        } catch (error) {
            showDialogErrorWithMessage(error.message)

        }

    }
    useEffect(() => {
        const _bets = []
        dataTab.posisi.map(p => {

            dataTab.tebak.map(t => {
                const bet = {
                    amount: '',
                    number: t,
                    posisi: p
                }

                bet.number = t
                _bets.push(bet)
            })
            return p
        })
        setBets(_bets)

    },[])
    return (
        <>{betHistory.length === 0 && <div style={{background: dataTab.background}} className="tab-overlow">
            {loading && <LoadingUi/>}
                <Col xs="12" lg="12">
                        <tr className="table-thead font-10 table-thead-5050">
                            <th className="col-5050-01">Posisi</th>
                            <th className="col-5050-02" >Tebakan</th>
                            <th className="col-5050-03" >Kei(%)</th>
                            <th className="col-5050-04" >Taruhan
                                <input className='table-input-none table-input-none-5050 font-10'
                                       inputMode="numeric"
                                       value={autobet.amount} placeholder='auto bet'
                                       onChange={onChangeAutoBet}/>
                                <span className="">x1000</span>
                            </th>
                        </tr>
                    {dataTab.posisi.map((p, i) =>
                        <table key={p.label + i} className="table table-5050 font-10" style={{ borderCollapse: 'collapse'}}>

                                <tr style={{borderBottom: '2px solid white'}}>
                                    <th rowSpan="2" className="tab-text-5050-01">{p}</th>
                                    <th className="tab-text-5050-02">{dataTab.tebak[0]}</th>
                                    <th className="tab-text-5050-03">{dataTab.menangs[i*2]?.nilai}</th>
                                    <th className="table-display tab-text-5050-04">
                                        <input className="table-input table-input-5050"
                                               inputMode="numeric"
                                               value={bets[i*2]?.amount}
                                               onChange={(e) => onChangeAmount(e, i*2)}/>
                                        <p className="tab-text cl-1000-5050 font-10">X1000</p>
                                    </th>
                                </tr>

                                <tr>
                                    <th className="tr-5050-02-01">{dataTab.tebak[1]}</th>
                                    <th className="tr-5050-02-02">{dataTab.menangs[i*2+1]?.nilai}</th>
                                    <th className="table-display tab-text-5050-04">
                                        <input className="table-input table-input-5050"
                                               inputMode="numeric"
                                               value={bets[i*2+1]?.amount}
                                               onChange={(e) => onChangeAmount(e, i*2+1)}/>
                                        <p className="tab-text cl-1000-5050 font-10">X1000</p>
                                    </th>
                                </tr>
                        </table>
                    )}

                        <div className="table-group-btn">
                            <button className="table-btn table-btn-BTN" onClick={submit}>Kirim</button>
                            <button style={{ background: "#D0453B" }} className="table-btn table-btn-BTN">Hapus</button>
                        </div>
                </Col>
            <DialogError/>
        </div>}
        </>
        
    )
}

export default GanjilGenap