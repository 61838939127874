const data = [
    {
        num: 1,
        tebak: 9033,
        amount: 1000,
        disc: 600,
        total: 340
    },
    {
        num: 2,
        tebak: 4497,
        amount: 1000,
        disc: 600,
        total: 340
    },
    {
        num: 3,
        tebak: 8811,
        amount: 1000,
        disc: 600,
        total: 340
    },
    {
        num: 4,
        tebak: 1320,
        amount: 1000,
        disc: 600,
        total: 340
    },
    {
        num: 5,
        tebak: 1674,
        amount: 1000,
        disc: 600,
        total: 340
    },
    {
        num: 6,
        tebak: 7283,
        amount: 1000,
        disc: 600,
        total: 340
    },
    {
        num: 7,
        tebak: 9090,
        amount: 1000,
        disc: 600,
        total: 340
    },
    {
        num: 8,
        tebak: 7878,
        amount: 1000,
        disc: 600,
        total: 340
    },
    {
        num: 9,
        tebak: 6008,
        amount: 1000,
        disc: 600,
        total: 340
    },
    {
        num: 10,
        tebak: 1399,
        amount: 1000,
        disc: 600,
        total: 340
    },
    {
        num: 11,
        tebak: 1313,
        amount: 1000,
        disc: 600,
        total: 340
    },
    {
        num: 12,
        tebak: 1212,
        amount: 1000,
        disc: 600,
        total: 340
    },
    {
        num: 13,
        tebak: 5185,
        amount: 1000,
        disc: 600,
        total: 340
    },
    {
        num: 14,
        tebak: 6675,
        amount: 1000,
        disc: 600,
        total: 340
    },
    {
        num: 15,
        tebak: 1348,
        amount: 1000,
        disc: 600,
        total: 340
    },
    {
        num: 16,
        tebak: 7461,
        amount: 1000,
        disc: 600,
        total: 340
    },
    {
        num: 17,
        tebak: 1880,
        amount: 1000,
        disc: 600,
        total: 340
    },
    {
        num: 18,
        tebak: 4789,
        amount: 1000,
        disc: 600,
        total: 340
    },
    {
        num: 19,
        tebak: 6660,
        amount: 1000,
        disc: 600,
        total: 340
    },
    {
        num: 20,
        tebak: 8695,
        amount: 1000,
        disc: 600,
        total: 340
    }
]

function D43D2D() {
   
    return (
        <div style={{marginTop: "5%"}}>
            <table className="table-h5">
                <tr className="limit-tr01">
                    <th style={{width: "5%"}} className="th-h5">No</th>
                    <th style={{width: "25%"}} className="th-h5">Tebak 1</th>
                    <th style={{width: "25%"}} className="th-h5">Amount</th>
                    <th style={{width: "20%"}} className="th-h5">Disc(%)</th>
                    <th style={{width: "20%"}} className="th-h5">Total</th>
                </tr>
                {data.map((item, index) => (
                    <tr key={index} style={{color: "black"}}>
                        <td className="td-h5">{item.num}</td>
                        <td className="td-h5 fontWeight1000">{item.tebak}</td>
                        <td className="td-h5">{item.amount}</td>
                        <td className="td-h5">{item.disc}</td>
                        <td className="td-h5">{item.total}</td>
                    </tr>              
                ))}
            </table>

           
        </div>
    )
}

export default D43D2D;