import React, {useEffect, useState} from 'react';
import {Col} from 'reactstrap';
import '../../games.css';
import LoadingUi from "../../../../Component/Loading/ui";
import {useDialog, useLoading} from "../../../../Component";
import numeral from "numeral";
import http from "../../../../service/http";

const D2Tengah = ({getBackgroundColor, betHistory, bgrColor, market, periode, isDisCount, betType, getReCeipt}) => {
    const [loading, withLoading] = useLoading()
    const [DialogError, showDialogErrorWithMessage] = useDialog({
        title: 'ERROR',
        type: 'error'
    })
    const [totalBet, setTotalBet] = useState(0)
    const [autobet, setAutoBet] = useState('')
    const [bets, setBets] = useState([
        {tebak: "", tebak2: "", kei: '', amount: '', discount: '', bayar: ''},
        {tebak: "", tebak2: "", kei: '', amount: '', discount: '', bayar: ''},
        {tebak: "", tebak2: "", kei: '', amount: '', discount: '', bayar: ''},
        {tebak: "", tebak2: "", kei: '', amount: '', discount: '', bayar: ''},
        {tebak: "", tebak2: "", kei: '', amount: '', discount: '', bayar: ''},
        {tebak: "", tebak2: "", kei: '', amount: '', discount: '', bayar: ''},
        {tebak: "", tebak2: "", kei: '', amount: '', discount: '', bayar: ''},
        {tebak: "", tebak2: "", kei: '', amount: '', discount: '', bayar: ''},
        {tebak: "", tebak2: "", kei: '', amount: '', discount: '', bayar: ''},
        {tebak: "", tebak2: "", kei: '', amount: '', discount: '', bayar: ''},
    ])
    let localbets = []
    const runReceipt = () => {
        getReCeipt(localbets)
    }
    const calTotalBet = (data) => {
        const arr = data.filter(x => x.tebak !== '' && x.tebak2 !== '')
        if (arr.length > 0) setTotalBet(arr.reduce((a, b) => {
            return {
                amount: Number(a.amount) + Number(b.amount)
            }
        }).amount)
    }
    const onChangeAutoBet = (e) => {
        const value = Number(e.target.value)
        if (e.target.value === '' || value > 0) {
            const _bets = bets.map(b => {
                b.amount = e.target.value
                b.discount = value * 1000 * b.kei / 100
                b.bayar = value * 1000 - b.discount
                return b
            })
            setBets(_bets)
            calTotalBet(_bets)
            const _autobet = [...autobet]
            _autobet[0] = e.target.value
            setAutoBet(_autobet)
        }

    }
    const onChangeTebak = (e, index, key, idNext) => {
        const value = Number(e.target.value)
        if ((e.target.value.length === 1 && value >= 0) || e.target.value === '') {
            const _bets = [...bets]

            _bets[index][key] = e.target.value
            calTotalBet(_bets)
            setBets(_bets)
            if (value >= 0) {
                const ee = document.getElementById(idNext)
                ee.focus()
            }
        }
    }
    const onChangeAmount = (e, index) => {
        const value = Number(e.target.value)
        if ((value >= 0) || e.target.value === '') {
            const _bets = [...bets]

            // console.log(e.target.value, numbers, numbers.includes(e.target.value))
            _bets[index].amount = e.target.value
            _bets[index].discount = value * 1000 * _bets[index].kei / 100
            _bets[index].bayar = value * 1000 - _bets[index].discount
            calTotalBet(_bets)
            setBets(_bets)
        }
    }
    const submit = async () => {
        const payload = {
            betTypes: [],
            period: periode.length > 0 ? periode[0].no + 1 : 1,
            code: market.code,
            pid: market._id
        }

        bets.filter(x => x.tebak !== '' && x.tebak2 !== '' && x.amount > 0).map(n => {
            const betTypes = (isDisCount ? '' : betType + '-') + '2D Tengah'
            payload.betTypes.push({
                number: n.tebak + n.tebak2,
                betTypes,
                id_game: 5,
                amount: n.amount * 1000
            })
        })
        try {
            if (payload.betTypes.length === 0) showDialogErrorWithMessage('No bet record !')
            else {
                const betrs = await withLoading(() => http.post({path: 'bet', payload: JSON.stringify(payload)}))
                const txid = betrs.toPage.split('&proses=')
                const betH = await withLoading(() => http.get({path: 'users/bets/' + txid[1]}))
                if (betrs.message && betrs.message !== '') {
                    if (betH.length > 0) {
                        localbets = betH
                        showDialogErrorWithMessage(betrs.message, runReceipt)
                    } else showDialogErrorWithMessage(betrs.message)
                } else {

                    if (betH.length > 0) getReCeipt(betH)
                    else showDialogErrorWithMessage('No Bet accepted')
                }
            }
        } catch (error) {
            showDialogErrorWithMessage(error.message)

        }

    }
    const fetchData = async () => {
        try {
            const response = await http.get({
                path: 'users/menang/' + market._id,
                params: {gameId: 5}
            })
            setBets(bets.map(b => {
                const menang = response.find(x => isDisCount ? !x.ket.includes('-') : x.ket.includes(betType))
                b.kei = menang.discount
                return b
            }))

        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }
    useEffect(() => {
        fetchData()

    }, [])
    return (
        <>{betHistory.length === 0 && <Col style={{width: '100%'}}>
            {loading && <LoadingUi/>}

            <div className="tab-overlow tab-overlow-2Depan" style={{backgroundColor: getBackgroundColor(bgrColor)}}>

                <div className="tab" style={{margin: 0, backgroundColor: 'inherit'}}>
                    <div className='tab-styled-box'>
                        <div>
                            <p className="tab-title">2D Tengah Bet Pannel IDR 1.000 = 1</p>
                            <p style={{marginRight: 10}} className="tab-title tab-title-Total">Total
                                Bet: {numeral(totalBet * 1000).format('0,0')}</p>
                        </div>

                        <table className='table table-4d'>
                            <thead>
                            <tr>
                                <th className="table-thaed table-thaed-2D-th01">No.</th>
                                <th colSpan={4}>Nomor</th>
                                <th>
                                    <span style={{color: "white", minWidth: 92}} className='bg-2D'>Taruhan (X1000)</span>
                                    <input style={{width: '100%'}} className='table-input' inputMode="numeric" value={autobet}
                                           placeholder='auto bet' onChange={onChangeAutoBet}/>
                                </th>
                                <th className="table-thaed table-thaed-2D-th04">Diskon</th>
                                <th className="table-thaed table-thaed-2D-th05">Beli</th>
                            </tr>
                            </thead>
                            <tbody>
                            {bets.map((i, ix) => (
                                <tr key={ix}>
                                    <td style={{minWidth: 30}} className='tab-text-2D-numb'>{ix + 1}</td>
                                    <td>
                                        <input style={{width: '100%'}} className="table-input table-input-2D-01 tengah-nomor" placeholder='X' disabled/>
                                    </td>
                                    <td>
                                        <input style={{width: '100%'}} className="table-input table-input-2D-01 tengah-nomor"
                                               inputMode="numeric"
                                               value={i.tebak}
                                               onChange={(e) => onChangeTebak(e, ix, 'tebak', 'tebak2' + ix)}/>

                                    </td>
                                    <td>
                                        <input style={{width: '100%'}} className="table-input table-input-2D-01 tengah-nomor"
                                               inputMode="numeric"
                                               value={i.tebak2}
                                               id={'tebak2' + ix}
                                               onChange={(e) => onChangeTebak(e, ix, 'tebak2', 'amount' + ix)}/>

                                    </td>
                                    <td>
                                        <input style={{width: '100%'}} className="table-input table-input-2D-01 tengah-nomor" placeholder='X' disabled/>
                                    </td>
                                    <td>
                                        <input className='table-input table-input-2Ddepen'
                                               style={{width: '100%'}}
                                               inputMode="numeric"
                                               value={i.amount}
                                               id={'amount' + ix}
                                               onChange={(e) => onChangeAmount(e, ix)}/>
                                    </td>
                                    <td>
                                        <input style={{width: '100%'}} className='table-input table-input-2Ddepen font-10' disabled
                                               value={numeral(i.discount).format('0,0')}/>
                                    </td>
                                    <td>
                                        <input style={{width: '100%'}} className='table-input table-input-2Ddepen font-10' disabled
                                               value={numeral(i.bayar).format('0,0')}/>
                                    </td>

                                </tr>
                            ))}
                            </tbody>

                        </table>

                        <div className="table-group-btn">
                            <button className="table-btn" onClick={submit}>Kirim</button>
                            <button style={{background: "#D0453B"}} className="table-btn">Hapus</button>
                        </div>

                    </div>
                </div>
            </div>
            <DialogError/>
        </Col>}
        </>
    )
}

export default D2Tengah;
