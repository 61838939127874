import React, {useEffect, useState} from 'react';
import {Col, Table, Row} from 'reactstrap';
import http from '../../../../../../service/http';
import {useDialog, useLoading} from "../../../../../../Component";
import LoadingUi from "../../../../../../Component/Loading/ui";
import numeral from "numeral";

const BETDISKONTab432D = ({
                              getBackgroundColor,
                              getReCeipt,
                              bgrColor,
                              isDisCount,
                              betType,
                              arrayCreateQuickbet,
                              market,
                              periode,
                              gamesConfig
                          }) => {
    const [loading, withLoading] = useLoading()
    const [totalBet, setTotalbet] = useState(0)
    const [userInputs, setUserInputs] = useState([])
    const [bets, setBets] = useState([])
    const [menangs, setmenangs] = useState([])
    const [selectedOption, setSelectedOption] = useState(10)
    const newArray = Array.from({length: selectedOption}, (_, index) => index + 1)
    const [betAmounts, setBetAmounts] = useState([])
    const [user, setUser] = useState({})

    //State Focus 4D, 3D, 2D
    const [inputActive, setInputActive] = useState({'4D': false, '3D': false, '2D': false})
    const [autoBets, setAutoBets] = useState({'4D': '', '3D': '', '2D': ''})

    const [Dialog, showDialogWithMessage] = useDialog({
        title: 'SUCCESS'
    })

    const [DialogError, showDialogErrorWithMessage] = useDialog({
        title: 'ERROR',
        type: 'error'
    })
    let localbets = []
    const runReceipt = () => {
        getReCeipt(localbets)
    }
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value)
        const _betAmounts = []
        for (let i = 0; i < event.target.value; i++) {
            _betAmounts.push({
                d4: '',
                d3: '',
                d2: ''
            })
        }
        setBetAmounts(_betAmounts)
    }

    const getbetsByInput = (numbers, pos) => {
        const n = numbers.length
        const arr = []
        if (n >= 2 && n <= 4) {
            for (let i = 0; i <= n - 2; i++) {
                const number = numbers.slice((n - i) * -1)
                const taruhan = betAmounts[pos]['d' + number.length]
                const idGame = number.length === 2 ? 3 : (number.length === 3 ? 2 : 1)
                console.log(menangs, idGame)
                const dis = menangs.find(x => x.id_game === idGame)
                const discount = taruhan * dis.discount / 100
                arr.push({
                    number: number,
                    taruhan,
                    discount: discount.toFixed(2),
                    payar: Number(taruhan - discount).toFixed(2)
                })
            }
        }
        return arr
    }
    const getbetsByInputAndAmount = (arrr, amount = null, index = null) => {
        const number = arrr[index].number
        if (number.length >= 2 && number.length <= 4) {
            const taruhan = amount
            const idGame = number.length === 2 ? 3 : (number.length === 3 ? 2 : 1)
            const dis = menangs.find(x => x.id_game === idGame)
            const discount = taruhan * dis.discount / 100
            arrr[index].taruhan = amount
            arrr[index].discount = discount.toFixed(2)
            arrr[index].payar = Number(taruhan - discount).toFixed(2)
        }
        return arrr
    }

    const getTotal = (arr) => {
        if (arr.length === 0) return {
            taruhan: 0,
            discount: 0,
            payar: 0
        }
        return arr.length === 1 ? arr[0] : arr.reduce((a, b) => {
            return {
                taruhan: a.taruhan + b.taruhan,
                discount: (Number(a.discount) + Number(b.discount)).toFixed(2),
                payar: (Number(a.payar) + Number(b.payar)).toFixed(2),
                number: a.number,
            }
        })
    }

    const getTotalBet = (arr) => {
        // console.log(arr, betAmounts)
        setTotalbet(getTotal(arr.filter(x => x)).taruhan)
    }

    const handleInputChange = (event, index) => {
        const inputValue = event.target.value
        if (!/^\d*$/.test(inputValue) || inputValue.length > 4) {
            return;
        }

        const newInputs = [...userInputs]
        newInputs[index] = inputValue
        const newbets = getbetsByInput(inputValue, index)
        setUserInputs(newInputs)

        const length = inputValue.length
        const ec = document.querySelectorAll('.bet-input' + index)
        // console.log(ec)

        // Tạm thời vô hiệu hóa tất cả các input
        ec.forEach(input => {
            input.disabled = true
        })

        const betss = [...bets]
        let disableGames = ''
        if(gamesConfig.games) {
            if (!gamesConfig.games['4D']) disableGames += '4D'
            if (!gamesConfig.games['3D']) disableGames += ' 3D'
            if (!gamesConfig.games['2D']) disableGames += ' 2D'
        }
        if (newbets.length > 0) {
            betss[index] = {
                bets: newbets,
                ...getTotal(newbets)
            }
            if (length === 4) {
                if (disableGames === '') ec.forEach(input => {
                    input.disabled = false
                })
                else {
                    if(!disableGames.includes('4D')) ec[0].disabled = false
                    if(!disableGames.includes('3D')) ec[1].disabled = false
                    if(!disableGames.includes('2D')) ec[2].disabled = false
                }

            } else if (length === 3) {
                if(!disableGames.includes('3D')) ec[1].disabled = false
                if(!disableGames.includes('2D')) ec[2].disabled = false

            } else if (length === 2) {
                if(!disableGames.includes('2D')) ec[2].disabled = false

            } else {
                // Disabled khi thanh Input rỗng
                ec.forEach(input => {
                    input.disabled = true;
                });
            }
        } else {
            betss[index] = {
                bets: [],
                taruhan: 0,
                discount: 0,
                payar: 0,
                number: inputValue
            }
        }
        setBets(betss)
        const e = document.getElementById('bb')
        const ee = document.getElementById('bb' + index)
        if (e.checked) onBBAll()
        else if (ee.checked) onBB('bb' + index, index)
        else getTotalBet(betss)
    }
    const getbetsByInputBB = (numbers, pos) => {
        const n = numbers.length
        const arr = []
        for (let i = 0; i < n; i++) {
            const number = numbers[i].toString().replaceAll(',', '')
            const taruhan = betAmounts[pos]['d' + number.length]
            const idGame = number.length === 2 ? 3 : (number.length === 3 ? 2 : 1)
            const dis = menangs.find(x => x.id_game === idGame)
            const discount = taruhan * dis.discount / 100
            arr.push({
                number: number,
                taruhan,
                discount: discount.toFixed(2),
                payar: Number(taruhan - discount).toFixed(2)
            })
        }
        return arr
    }
    const updateBetList = (arr, index) => {
        const newbets = getbetsByInputBB(arr, index)

        const betss = [...bets]
        if (newbets.length > 0) {
            betss[index] = {
                bets: newbets,
                ...getTotal(newbets)
            }
            // console.log(betss)
        } else {
            betss[index] = {
                bets: [],
                taruhan: 0,
                discount: 0,
                payar: 0,
                number: betss[index].number
            }
        }
        return betss

    }
    const calBB = (array, index) => {
        let betbb = []
        for (let i = 2; i < 5; i++) {
            if (betAmounts[index]['d' + i] > 0 && array.length >= i) betbb = betbb.concat(arrayCreateQuickbet(array, i))
        }
        const rs = [...new Set(betbb.map(r => r.toString()))]

        return rs

    }
    const calBets = (inputValue, index) => {
        const newbets = getbetsByInput(inputValue, index)
        // console.log('calBets', inputValue, newbets)
        const betbb = newbets.map(n => n.number.split(''))
        return betbb
    }
    const onBB = (id, index) => {
        const e = document.getElementById(id)
        const number = document.getElementById('number' + index).value
        if (number.length > 1) {
            let betbb
            if (e.checked) {
                // calBB()
                betbb = calBB(number.split(''), index)
            } else betbb = calBets(number, index)
            const betss = updateBetList(betbb, index)
            // console.log(betss)
            getTotalBet(betss)
            setBets(betss)
        }

    }
    const onBBAll = () => {
        const e = document.getElementById('bb')
        let betss = []

        newArray.map((n, i) => {
            let betbb = []
            const ee = document.getElementById('bb' + i)
            const number = document.getElementById('number' + i).value
            ee.checked = e.checked

            if (e.checked) {
                // calBB()
                betbb = calBB(number.split(''), i)
            } else betbb = calBets(number, i)

            if (betbb[i]) {
                const updatebets = updateBetList(betbb, i)
                betss[i] = updatebets[i]
            }
        })
        // if(betss[0])
        getTotalBet(betss)
        setBets(betss)

    }
    const handleInputAutoBetChange = (event, index) => {
        const value = event.target.value * 1;
        if (value > 0 || event.target.value === '') {
            let disableGames = ''
            if(user.games) {
                if(!user.games['4D']) disableGames += '4D'
                if(!user.games['3D']) disableGames += ' 3D'
                if(!user.games['2D']) disableGames += ' 2D'
            }

            if(!disableGames.includes(index+'D')) setBetAmounts(betAmounts.map(b => {
                b['d' + index] = value * 1000
                return b
            }))
            onBBAll()
            setAutoBets({...autoBets, [index + 'D']: event.target.value})
        }
    }
    const handleInputChange2 = (event, pos, index) => {

        const value = event.target.value * 1000;
        // const minBetLimit = index === 4 ? menang4D.minBetLimit : (index === 3 ? menang3D.minBetLimit : menang2D.minBetLimit)
        if (value > 0 || event.target.value === '') {
            const betAmountss = [...betAmounts]
            betAmountss[pos]['d' + index] = value || event.target.value
            setBetAmounts(betAmountss)
            const ee = document.getElementById('bb' + pos)
            if (ee.checked) {
                onBB('bb' + pos, pos)
            } else {

                const betss = bets.map((bb, bbi) => {
                    if (bbi === pos) {
                        let i = 0
                        bb.bets.map((b, ii) => {
                            if (b.number.length == index) i = ii
                        })
                        // console.log(i, value, bb)
                        const newbets = getbetsByInputAndAmount(bb.bets, value, i)
                        bb = {
                            bets: newbets,
                            ...getTotal(newbets)
                        }
                        // console.log(bb)

                    }
                    return bb
                })

                setBets(betss)
                getTotalBet(betss)
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const me = await http.get({path: 'me'})
                let disableGames = ''

                    setUser(me)

                    const response = await http.get({
                        path: 'users/menang/' + market._id,
                    })
                // const gamesConfig = response.find(x => x.ket === 'games')
                // if(gamesConfig) {
                //     if (!me.games['4D']) disableGames += '4D'
                //
                //     if (!me.games['3D']) disableGames += ' 3D'
                //     if (!me.games['2D']) disableGames += ' 2D'
                // }
                // if (disableGames !== '') showDialogErrorWithMessage(disableGames + ' locked, please contact upline')
                    const filteredResponse = response.filter(item => [1, 2, 3].includes(item.id_game))
                    // console.log(filteredResponse, betType)
                    setmenangs(filteredResponse)

            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        fetchData()
        setBetAmounts(newArray.map(n => ({
            d4: '',
            d3: '',
            d2: ''
        })))
    }, [])
    const submit = async () => {
        const betTypesSum = {}
        const payload = {
            betTypes: [],
            period: periode.length > 0 ? periode[0].no + 1 : 1,
            code: market.code,
            pid: market._id
        }
        // console.log(bets)
        bets.filter(x => x).map(bb => {
            bb.bets.filter(x => x.taruhan > 0).map(n => {
                const betTypes = (isDisCount ? '' : betType + '-') + (n.number.length === 2 ? '2D Belakang' : (n.number.length === 3 ? '3D' : '4D'))
                const id_game = n.number.length === 2 ? 3 : (n.number.length === 3 ? 2 : 1)
                payload.betTypes.push({
                    number: n.number,
                    betTypes,
                    id_game,
                    amount: n.taruhan
                })
                if (!betTypesSum[betTypes]) betTypesSum[betTypes] = {}
                if (!betTypesSum[betTypes][n.number]) betTypesSum[betTypes][n.number] = 0
                betTypesSum[betTypes][n.number] += n.taruhan
            })
        })
        try {
            if (payload.betTypes.length === 0) showDialogErrorWithMessage('No bet record !')
            else {
                payload.betTypesSum = betTypesSum
                const betrs = await withLoading(() => http.post({path: 'bet', payload: JSON.stringify(payload)}))
                const txid = betrs.toPage.split('&proses=')
                const betH = await withLoading(() => http.get({path: 'users/bets/' + txid[1]}))
                if (betrs.message && betrs.message !== '') {
                    if (betH.length > 0) {
                        localbets = betH
                        showDialogErrorWithMessage(betrs.message, runReceipt)
                    } else showDialogErrorWithMessage(betrs.message)
                } else {
                    showDialogWithMessage('YOU_REQUEST_SUBMITED_SUCCESS')
                    getReCeipt(betH)
                }
            }
        } catch (error) {
            showDialogErrorWithMessage(error.message)

        }

    }

    return (
        <Col xs="12" lg="12">
            {loading && <LoadingUi/>}
            <div className="tab-body-4D-432D" style={{backgroundColor: getBackgroundColor(bgrColor)}}>

                <div>
                    <p className="tab-text-432D font-12">4D, 3D, 2D Bet Pannel IDR 1.000 = 1</p>
                    <p style={{justifyContent: "flex-end"}} className="tab-text-432D font-12">Total
                        Bet: {numeral(totalBet).format('0,0')}</p>

                    <div className="table-display-432D">
                        <p className="">Baris:</p>
                        <select value={selectedOption} onChange={handleSelectChange} className="select-Number-432D">
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={40}>40</option>
                            <option value={50}>50</option>
                        </select>
                    </div>
                </div>
                <Table className={'table-4d'}>
                    <thead>
                    <tr>
                        <th>No</th>
                        <th>Nomor</th>
                        <th colSpan={3} style={{textAlign: 'center', minWidth: 110}}>Taruhan (x1000)</th>
                        <th className={'bayar'}>Diskon</th>
                        <th className={'bayar'}>Bayar</th>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td colSpan={3} scope="row" className={'d4-bet-amount'}>
                            <input className="table-input-none button-Autobet"
                                   value={autoBets['4D']}
                                   onChange={e => handleInputAutoBetChange(e, 4)}
                                   placeholder="auto bet" type="number"/>
                                <input
                                className="table-input-none button-Autobet button-Autobet02"
                                value={autoBets['3D']}
                                onChange={e => handleInputAutoBetChange(e, 3)}
                                placeholder="auto bet" type="number"/>
                            <input
                                className="table-input-none button-Autobet button-Autobet03"
                                value={autoBets['2D']}
                                onChange={e => handleInputAutoBetChange(e, 2)}
                                placeholder="auto bet" type="number"/>
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                                <div style={{float: 'right'}}>
                                    <p style={{marginLeft: 0}} className="tab-text-432D">BB</p>
                                    <input style={{marginLeft: 0}} type="checkbox" id='bb' onChange={onBBAll}/>
                                </div>

                        </td>
                    </tr>
                    {newArray.map((value, index) => (
                        <tr key={index}>
                                <td>{value}</td>
                            <td>
                                <input id={'number' + index} className="table-input table-input-Prize123"
                                       placeholder="" onChange={(event) => handleInputChange(event, index)}
                                       value={userInputs[index] || ''}/>
                            </td>
                            <td>
                                <input style={{color: "white", width: '100%'}} className={'table-input-none bet-input bet-input' + index}
                                       placeholder="4D"
                                       disabled={!inputActive['4D']}
                                       value={betAmounts[index]?.d4 !== '' ? betAmounts[index]?.d4 / 1000 : ''}
                                       id={index+'4'}
                                       type="number" onChange={(event) => handleInputChange2(event, index, 4)}/>

                            </td>
                            <td>
                                <input style={{color: "white", width: '100%'}} className={'table-input-none bet-input bet-input' + index}
                                       placeholder="3D"
                                       disabled={!inputActive['3D']}
                                       value={betAmounts[index]?.d3 !== '' ? betAmounts[index]?.d3 / 1000 : ''}
                                       id={index+'3'}
                                       type="number" onChange={(event) => handleInputChange2(event, index, 3)}/>

                            </td>
                            <td>
                                <input style={{color: "white", width: '100%'}} className={'table-input-none bet-input bet-input' + index}
                                       placeholder="2D"
                                       disabled={!inputActive['2D']}
                                       value={betAmounts[index]?.d2 !== '' ? betAmounts[index]?.d2 / 1000 : ''}
                                       id={index+'2'}
                                       type="number" onChange={(event) => handleInputChange2(event, index, 2)}/>

                            </td>
                            <td>
                                <input style={{color: "white", width: '100%',
                                    background: bets[index]?.discount > 1 ? 'inherit' : '#c7b7b7',
                                    border: '1px solid #fff',
                                }}
                                       className={`bayar table-input-none ${bets[index]?.discount > 1 ? '' : 'table-input-none-Number'}`}
                                       value={bets[index]?.discount ? numeral(bets[index].discount).format('0,0.00') : 0}
                                       disabled
                                />
                            </td>

                            <td>
                                <input style={{color: "white", width: '100%',
                                    background: bets[index]?.discount > 1 ? 'inherit' : '#c7b7b7',
                                    border: '1px solid #fff'
                                }}
                                       className={`bayar table-input-none ${bets[index]?.discount > 1 ? '' : 'table-input-none-Number'}`}
                                       value={bets[index]?.discount ? numeral(bets[index].payar).format('0,0.00') : 0}
                                       disabled
                                />
                            </td>
                            <td>
                                <input type="checkbox" id={'bb' + index}
                                       onChange={() => onBB('bb' + index, index)}/>
                            </td>
                        </tr>
                        ))}
                    <tr>
                        <td colSpan={8}>
                            <button style={{margin: 'auto'}} className='button-Submit-4D' onClick={submit}>Submit</button>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
            <Dialog/>
            <DialogError/>
        </Col>
    )
}

export default BETDISKONTab432D

