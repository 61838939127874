
import numeral from "numeral";
const data = [
    {
        num: 1,
        tebak1: 2,
        posisi: "AS",
        amount: 10.000,
        disc: 650,
        total: 9.350
    },
    {
        num: 2,
        tebak1: 9,
        posisi: "AS",
        amount: 10.000,
        disc: 650,
        total: 9.350
    },
    {
        num: 3,
        tebak1: 9,
        posisi: "AS",
        amount: 10.000,
        disc: 650,
        total: 9.350
    },
    {
        num: 4,
        tebak1: 6,
        posisi: "Kepala",
        amount: 10.000,
        disc: 650,
        total: 9.350
    },
    {
        num: 5,
        tebak1: 1,
        posisi: "Ekor",
        amount: 10.000,
        disc: 650,
        total: 9.350
    },
    {
        num: 6,
        tebak1: 2,
        posisi: "Kepala",
        amount: 10.000,
        disc: 650,
        total: 9.350
    },
    {
        num: 7,
        tebak1: 0,
        posisi: "Kepala",
        amount: 10.000,
        disc: 650,
        total: 9.350
    },
    {
        num: 8,
        tebak1: 2,
        posisi: "Ekor",
        amount: 10.000,
        disc: 650,
        total: 9.350
    },
    {
        num: 9,
        tebak1: 4,
        posisi: "Kop",
        amount: 10.000,
        disc: 650,
        total: 9.350
    },
    {
        num: 10,
        tebak1: 9,
        posisi: "Kepala",
        amount: 10.000,
        disc: 650,
        total: 9.350
    }
]

function ColokJitu() {
    const totals = {
        amount: data.reduce((acc, curr) => acc + curr.amount, 0),
        disc: data.reduce((acc, curr) => acc + curr.disc, 0),
        total: data.reduce((acc, curr) => acc + curr.total, 0)
    };
    return (
        <div style={{marginTop: "5%"}}>

            <table style={{color: "black"}} className="table-h5 f10">
                <tr className="limit-tr01">
                    <th style={{width: "5%"}} className="th-h5">No</th>
                    <th style={{width: "15%"}} className="th-h5">Tebak 1</th>
                    <th style={{width: "35%"}} className="th-h5">Posisi</th>
                    <th style={{width: "15%"}} className="th-h5">Amount</th>
                    <th style={{width: "15%"}} className="th-h5">Disc(%)</th>
                    <th style={{width: "15%"}} className="th-h5">Total</th>
                </tr>

                {data.map((item, index) => (
                    <tr key={index}>
                        <td className="td-h5">{item.num}</td>
                        <td className="td-h5 fontWeight1000">{item.tebak1}</td>
                        <td className="td-h5 colokJitu-col03">{item.posisi}</td>
                        <td className="td-h5"> {numeral(item.amount).format('0.000')}</td>
                        <td className="td-h5"> {numeral(item.disc).format('0.000')}</td>
                        <td className="td-h5"> {numeral(item.total).format('0.000')}</td>
                    </tr>              
                ))}

                <tr>
                    <td className="td-h5"></td>
                    <td colspan="2" className="td-h5 fontWeight1000">Total</td>
                    <td className="td-h5 fontWeight1000">{numeral(totals.amount).format('0.000')}</td>
                    <td className="td-h5 fontWeight1000">{numeral(totals.disc).format('0.000')}</td>
                    <td className="td-h5 fontWeight1000">{numeral(totals.total).format('0.000')}</td>
                </tr>
            </table>

           
        </div>
    )
}

export default ColokJitu;