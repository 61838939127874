
import '../../index.css'

const data4D = [
    {
        row01: "4D",
        row02: "3000 (X)",
        row03: "3D",
        row04: "400 (X)",
        row05: "",
        row06: "",
        row07: "",
        row08: "",
    }
]

const data2DPosisi = [
    {
        row01: "2D Depan",
        row02: "60 (X)",
        row03: "2D Tengah",
        row04: "60 (X)",
        row05: "2D Belakang",
        row06: "60 (X)",
        row07: "",
        row08: "",
    }
]

const data5050 = [
    {
        row01: "As Ganjil",
        row02: "-1%",
        row03: "As Genap",
        row04: "-1%",
        row05: "As Besar",
        row06: "-1%",
        row07: "As Kecil",
        row08: "-1%",
    },
    {
        row01: "Kop Ganjil",
        row02: "-1%",
        row03: "Kop Genap",
        row04: "-1%",
        row05: "Kop Besar",
        row06: "-1%",
        row07: "Kop Kecil",
        row08: "-1%",
    },
    {
        row01: "Kepala Ganjil",
        row02: "-1%",
        row03: "Kepala Genap",
        row04: "-1%",
        row05: "Kepala Besar",
        row06: "-1%",
        row07: "Kepala Kecil",
        row08: "-1%",
    },
    {
        row01: "Ekor Ganjil",
        row02: "-1%",
        row03: "Ekor Genap",
        row04: "-1%",
        row05: "Ekor Besar",
        row06: "-1%",
        row07: "Ekor Kecil",
        row08: "-1%",
    }
]

const dataKombinasiColokAngka = [
    {
        row01: "2 Kombinas",
        row02: "2.5 (X)",
        row03: "Colok Angka",
        row04: "1.55 (X)",
        row05: "",
        row06: "",
        row07: "",
        row08: "",
    }
]

const dataMacau = [
    {
        row01: "Macau(2)",
        row02: "6 (X)",
        row03: "Macau(3)",
        row04: "10 (X)",
        row05: "Macau",
        row06: "6 (X)",
        row07: "",
        row08: "",
    }
]

const dataColokNaga = [
    {
        row01: "Colok Naga (3)",
        row02: "15 (X)",
        row03: "Colok Naga (4)",
        row04: "30 (X)",
        row05: "",
        row06: "",
        row07: "",
        row08: "",
    }
]

const dataColokJitu = [
    {
        row01: "As",
        row02: "7 (X)",
        row03: "Kop",
        row04: "7 (x)",
        row05: "Kepala",
        row06: "8 (X)",
        row07: "Ekor",
        row08: "8 (X)",
    }
]

const dataTenghaTepiShio = [
    {
        row01: "Tepi",
        row02: "-2.50%",
        row03: "Tengah",
        row04: "-2.50%",
        row05: "Shio",
        row06: "9 (X)",
        row07: "",
        row08: "",
    }
]

const dataDasar = [
    {
        row01: "Ganjil",
        row02: "-5.00%",
        row03: "Genap",
        row04: "-5.00%",
        row05: "Beasr",
        row06: "-5.00%",
        row07: "Kecil",
        row08: "-5.00%",
    }
]

const dataShilangHomo = [
    {
        row01: "Silang Depan",
        row02: "-2.50%",
        row03: "Silang Tengah",
        row04: "-2.50%",
        row05: "Silang Belakang",
        row06: "-2.50%",
        row07: "",
        row08: "",
    },
    {
        row01: "Homo Depan",
        row02: "-2.50%",
        row03: "Homo Tengah",
        row04: "-2.50%",
        row05: "Homo Belakang",
        row06: "-2.50%",
        row07: "",
        row08: "",
    }
]


function KemenAngan() {
    return (
        <div style={{marginTop: "5%", padding: "2%"}}>

            {data4D.map((item, index) => (
                <table key={index} className='table-h5'>
                    <tr className="limit-tr01">
                        <th colspan="8" className="th-h5">4D</th>
                    </tr>
                    
                    <tr style={{color: "black"}}>
                        <td style={{width: "10%"}} className="td-h5">{item.row01}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row02}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row03}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row04}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row05}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row06}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row07}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row08}</td>                      
                    </tr>
                </table>
            ))}

            {data2DPosisi.map((item, index) => (
                <table key={index} className='table-h5'>
                    <tr className="limit-tr01">
                        <th colspan="8" className="th-h5">2D Posisi</th>
                    </tr>
                    
                    <tr style={{color: "black"}}>
                        <td style={{width: "10%"}} className="td-h5">{item.row01}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row02}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row03}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row04}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row05}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row06}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row07}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row08}</td>                    
                    </tr>
                </table>
            ))}

            <table className='table-h5'>
                <tr className="limit-tr01">
                    <th colspan="8" className="th-h5">50 - 50</th>
                </tr>
                {data5050.map((item, index) => (
                    <tr key={index} style={{color: "black"}}>
                        <td style={{width: "10%"}} className="td-h5">{item.row01}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row02}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row03}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row04}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row05}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row06}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row07}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row08}</td>                    
                    </tr>
                ))}
            </table>

            {dataKombinasiColokAngka.map((item, index) => (
                <table key={index} className='table-h5'>
                    <tr className="limit-tr01">
                        <th colspan="8" className="th-h5">Kombinasi / Colok Angka</th>
                    </tr>
                    
                    <tr style={{color: "black"}}>
                        <td style={{width: "10%"}} className="td-h5">{item.row01}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row02}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row03}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row04}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row05}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row06}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row07}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row08}</td>                    
                    </tr>
                </table>
            ))}

            {dataMacau.map((item, index) => (
                <table key={index} className='table-h5'>
                    <tr className="limit-tr01">
                        <th colspan="8" className="th-h5">Macau</th>
                    </tr>
                    
                    <tr style={{color: "black"}}>
                        <td style={{width: "10%"}} className="td-h5">{item.row01}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row02}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row03}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row04}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row05}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row06}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row07}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row08}</td>                    
                    </tr>
                </table>
            ))}

            {dataColokNaga.map((item, index) => (
                <table key={index} className='table-h5'>
                    <tr className="limit-tr01">
                        <th colspan="8" className="th-h5">Colok Naga</th>
                    </tr>
                    
                    <tr style={{color: "black"}}>
                        <td style={{width: "10%"}} className="td-h5">{item.row01}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row02}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row03}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row04}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row05}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row06}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row07}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row08}</td>                    
                    </tr>
                </table>
            ))}

            {dataColokJitu.map((item, index) => (
                <table key={index} className='table-h5'>
                    <tr className="limit-tr01">
                        <th colspan="8" className="th-h5">Colok Jitu</th>
                    </tr>
                    
                    <tr style={{color: "black"}}>
                        <td style={{width: "10%"}} className="td-h5">{item.row01}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row02}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row03}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row04}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row05}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row06}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row07}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row08}</td>                    
                    </tr>
                </table>
            ))}

            {dataTenghaTepiShio.map((item, index) => (
                <table key={index} className='table-h5'>
                    <tr className="limit-tr01">
                        <th colspan="8" className="th-h5">Tengah Tepi / Shio</th>
                    </tr>
                    
                    <tr style={{color: "black"}}>
                        <td style={{width: "10%"}} className="td-h5">{item.row01}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row02}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row03}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row04}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row05}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row06}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row07}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row08}</td>                    
                    </tr>
                </table>
            ))}

            {dataDasar.map((item, index) => (
                <table key={index} className='table-h5'>
                    <tr className="limit-tr01">
                        <th colspan="8" className="th-h5">Dasar</th>
                    </tr>
                    
                    <tr style={{color: "black"}}>
                        <td style={{width: "10%"}} className="td-h5">{item.row01}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row02}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row03}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row04}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row05}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row06}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row07}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row08}</td>                    
                    </tr>
                </table>
            ))}

            {dataShilangHomo.map((item, index) => (
                <table key={index} className='table-h5'>
                    <tr className="limit-tr01">
                        <th colspan="8" className="th-h5">Silang Homo</th>
                    </tr>
                    
                    <tr style={{color: "black"}}>
                        <td style={{width: "10%"}} className="td-h5">{item.row01}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row02}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row03}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row04}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row05}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row06}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row07}</td>
                        <td style={{width: "10%"}} className="td-h5">{item.row08}</td>                    
                    </tr>
                </table>
            ))}

        </div>
    )
}

export default KemenAngan