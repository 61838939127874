import React, {useState, useEffect} from 'react';
import {Col, Row, Table} from "reactstrap";
import './home.css';
import http from '../../service/http';

const Home = ({sysConfig, location}) => {
    const [marketData, setMarketData] = useState([]);
    const [page, setPage] = useState(0)
    const fetchData = async () => {
        try {
            const data = await http.get({path: 'pasarans'});
            setMarketData(data
                .filter(da => da.status2 === 'active') // Filter out items with null banner
                .map(da => {
                    // da.banner = markets[da.code] ? markets[da.code].banner : ''
                    return da
                })
            );
            console.log("Call API Pasarans Success")
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {

// console.log('sysConfig', sysConfig)
        if(sysConfig.maintenance !== 'yes') fetchData();
        else setMarketData([])
    }, [sysConfig]);

    const linkTo = (market) => {
        if (market.status === "online") {
            localStorage.setItem('market', JSON.stringify(market));
            window.location = '/games' + location.search;
        } else {
            console.log("Đang Ofline không qua page được");
        }
    };


    return (
        <>
            <div className="wrapper-home">
                {marketData.map((item, index) => (
                    <div className='home-item' key={index}>
                        <div onClick={() => linkTo(item)} style={{cursor: 'pointer'}}>
                            <div className='home-img'>
                                <img className='home-styled-img' src={item.image} alt=''/>
                                <div className='home-detail'>
                                    <p className='home-text-link'>{item.link}</p>
                                    <p className='home-text-time'>Live Draw Time {item.time}</p>
                                </div>
                            </div>
                        </div>

                        <div className='home-btn-group'>
                            <button className='home-btn'
                                    style={{background: item.status === "online" ? "green" : "#C20F0F"}}>
                                {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                            </button>
                            <button className='home-btn' style={{
                                background: item.status === "online" ? "white" : "white",
                                color: item.status === "online" ? "green" : "red"
                            }}>
                                {item.status === "online" ? item.tutup : "Live"}
                            </button>
                        </div>

                    </div>
                ))}
            </div>
            {/*<Row className="showRa" style={{textAlign: "center"}}>*/}
            {/*    <Col style={{marginBottom: 20}}>*/}
            {/*        <div>DASHBOARD</div>*/}
            {/*    </Col>*/}
            {/*        <Col style={{marginBottom: 20, height: 30}}>*/}
            {/*            <div style={{display: 'flex'}}>*/}
            {/*                <div className="history-button">*/}
            {/*                    <button onClick={() => setPage(0)} className='dashboard-list-button' style={{*/}
            {/*                        background: page === 0 ? 'red' : 'gray', float: 'left'*/}
            {/*                    }}>INFO UMUM*/}
            {/*                    </button>*/}

            {/*                </div>*/}
            {/*                <div className="history-button">*/}
            {/*                    <button onClick={() => setPage(1)} className='dashboard-list-button' style={{*/}
            {/*                        background: page === 1 ? 'red' : 'gray', float: 'left'*/}
            {/*                    }}>LIMIT BET*/}
            {/*                    </button>*/}

            {/*                </div>*/}
            {/*                <div className="history-button">*/}
            {/*                    <button onClick={() => setPage(2)} className='dashboard-list-button' style={{*/}
            {/*                        background: page === 2 ? 'red' : 'gray', float: 'left'*/}
            {/*                    }}>PROMO & DISKON*/}
            {/*                    </button>*/}

            {/*                </div>*/}
            {/*                <div className="history-button">*/}
            {/*                    <button onClick={() => setPage(3)} className='dashboard-list-button' style={{*/}
            {/*                        background: page === 3 ? 'red' : 'gray',*/}
            {/*                    }}>KEMENANGAN*/}
            {/*                    </button>*/}

            {/*                </div>*/}
            {/*                <div className="history-button">*/}
            {/*                    <button onClick={() => setPage(4)} className='dashboard-list-button' style={{*/}
            {/*                        background: page === 4 ? 'red' : 'gray',*/}
            {/*                    }}>GAME*/}
            {/*                    </button>*/}

            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </Col>*/}

            {/*    {page === 0 && <InfoUmum linkTo={linkTo} marketData={marketData}/>}*/}
            {/*    {page === 1 && <LimitBet linkTo={linkTo} marketData={marketData}/>}*/}
            {/*</Row>*/}
        </>
    );
};

export default Home;
