import numeral from "numeral";
const data = [
    {
        num: 1,
        tebak1: 8,
        tebak2: 3,
        amount: 9.000,
        disc: 5.9400,
        total: 3.060
    },
    {
        num: 2,
        tebak1: 7,
        tebak2: 5,
        amount: 9.000,
        disc: 5.9400,
        total: 3.060
    },
    {
        num: 3,
        tebak1: 0,
        tebak2: 4,
        amount: 9.000,
        disc: 5.9400,
        total: 3.060
    },
    {
        num: 4,
        tebak1: 1,
        tebak2: 6,
        amount: 9.000,
        disc: 5.9400,
        total: 3.060
    },
    {
        num: 5,
        tebak1: 6,
        tebak2: 3,
        amount: 9.000,
        disc: 5.9400,
        total: 3.060
    },
    {
        num: 6,
        tebak1: 4,
        tebak2: 9,
        amount: 9.000,
        disc: 5.9400,
        total: 3.060
    },
    {
        num: 7,
        tebak1: 9,
        tebak2: 4,
        amount: 9.000,
        disc: 5.9400,
        total: 3.060
    },
    {
        num: 8,
        tebak1: 5,
        tebak2: 5,
        amount: 9.000,
        disc: 5.9400,
        total: 3.060
    },
    {
        num: 9,
        tebak1: 2,
        tebak2: 6,
        amount: 9.000,
        disc: 5.9400,
        total: 3.060
    },
    {
        num: 10,
        tebak1: 6,
        tebak2: 1,
        amount: 9.000,
        disc: 5.9400,
        total: 3.060
    },
    {
        num: 11,
        tebak1: 0,
        tebak2: 1,
        amount: 9.000,
        disc: 5.9400,
        total: 3.060
    },
    {
        num: 12,
        tebak1: 5,
        tebak2: 9,
        amount: 9.000,
        disc: 5.9400,
        total: 3.060
    },
    {
        num: 13,
        tebak1: 0,
        tebak2: 3,
        amount: 9.000,
        disc: 5.9400,
        total: 3.060
    },
    {
        num: 14,
        tebak1: 5,
        tebak2: 8,
        amount: 9.000,
        disc: 5.9400,
        total: 3.060
    },
    {
        num: 15,
        tebak1: 6,
        tebak2: 6,
        amount: 9.000,
        disc: 5.9400,
        total: 3.060
    },
    {
        num: 16,
        tebak1: 8,
        tebak2: 0,
        amount: 9.000,
        disc: 5.9400,
        total: 3.060
    },
    {
        num: 17,
        tebak1: 6,
        tebak2: 3,
        amount: 9.000,
        disc: 5.9400,
        total: 3.060
    },
    {
        num: 18,
        tebak1: 9,
        tebak2: 9,
        amount: 9.000,
        disc: 5.9400,
        total: 3.060
    },
    {
        num: 19,
        tebak1: 8,
        tebak2: 0,
        amount: 9.000,
        disc: 5.9400,
        total: 3.060
    },
    {
        num: 20,
        tebak1: 5,
        tebak2: 5,
        amount: 9.000,
        disc: 5.9400,
        total: 3.060
    }
]

function Macau() {
    const totals = {
        amount: data.reduce((acc, curr) => acc + curr.amount, 0),
        disc: data.reduce((acc, curr) => acc + curr.disc, 0),
        total: data.reduce((acc, curr) => acc + curr.total, 0)
    };
    return (
        <div style={{marginTop: "5%"}}>

            <table style={{color: "black"}} className="table-h5 f10">
                <tr className="limit-tr01">
                    <th style={{width: "5%"}} className="th-h5">No</th>
                    <th style={{width: "15%"}} className="th-h5">Tebak 1</th>
                    <th style={{width: "15%"}} className="th-h5">Tebak 2</th>
                    <th style={{width: "25%"}} className="th-h5">Amount</th>
                    <th style={{width: "17.25%"}} className="th-h5">Disc(%)</th>
                    <th style={{width: "17.25%"}} className="th-h5">Total</th>
                </tr>

                {data.map((item, index) => (
                    <tr key={index}>
                        <td className="td-h5">{item.num}</td>
                        <td className="td-h5 fontWeight1000">{item.tebak1}</td>
                        <td className="td-h5 fontWeight1000">{item.tebak2}</td>
                        <td className="td-h5"> {numeral(item.amount).format('0.000')}</td>
                        <td className="td-h5"> {numeral(item.disc).format('0.000')}</td>
                        <td className="td-h5"> {numeral(item.total).format('0.000')}</td>
                    </tr>              
                ))}

                <tr>
                    <td className="td-h5"></td>
                    <td colspan="2" className="td-h5 fontWeight1000">Total</td>
                    <td className="td-h5 fontWeight1000">{numeral(totals.amount).format('0.000')}</td>
                    <td className="td-h5 fontWeight1000">{numeral(totals.disc).format('0.000')}</td>
                    <td className="td-h5 fontWeight1000">{numeral(totals.total).format('0.000')}</td>
                </tr>
            </table>

           
        </div>
    )
}

export default Macau;