import React from 'react';
import Home from './pages/Home';
import Games from './pages/Game';
import Tools from './pages/H5/Tools';
import History02 from './pages/H5/History02';
import Dashboard from './pages/H5/Dashboard';
import BetType from './pages/H5/BetType';




import BettingDetila from './pages/BettingReport/BettingDetila';

import BettingReport from './pages/BettingReport';
import Result from './pages/Result';
import TransationHistory from './pages/TransationHistory';

function Loading() {
  return <div>Loading...</div>;
}

const routes = [
  { path: '/', exact: true, name: 'Home', component: Home },
  { path: '/games', exact: true, name: 'Games Types', component: Games },
  { path: '/results', exact: true, name: 'Result', component: Result },
  { path: '/report', exact: true, name: 'Betting Report', component: BettingReport },
  { path: '/history', exact: true, name: 'Transation History', component: TransationHistory },
  { path: '/Tools', exact: true, name: 'Tools', component: Tools },
  { path: '/History02', exact: true, name: 'History02', component: History02 },
  { path: '/Dashboard', exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/BetType', exact: true, name: 'BetType', component: BetType },
  { path: '/BettingDetila', exact: true, name: 'BetType', component: BettingDetila },





];

export default routes;