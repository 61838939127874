import { useState } from 'react'
import '../index.css'
import BackIcon from '../../../assets/img/icons/back-button-icon.png'
import Result from './Result'
import TransationHistory from './TransationHistory'
import Invoice from './Invoice'

function History02() {

    const [page, setPage] = useState(0)

    return (
        <div className="bg-tools">

            <div className="history-Tile">
                <img className="history-Img-Back" src={BackIcon}></img>
                <p className="history-Title-Text" style={{color: "black"}}>HISTORY</p>
            </div>

            <div>
                {page === 0 && <Result setPage={setPage}/>}
                {page === 1 && <Invoice setPage={setPage}/>}
                {page === 2 && <TransationHistory setPage={setPage}/>}
            </div>

        </div>
    )
}

export default History02;