/* eslint-disable no-restricted-globals */
import config from '../config'
import queryString from 'query-string'
import store from 'store'

function handleErrors(response) {
  if ([200, 201].includes(response.status)) {
    return response
  }else if ([524].includes(response.status)) {
    return {message: 'Time out!'}
  }
  if ([401, 403].includes(response.status)) {
    store.clearAll()
    location.href = '/login'
  }

  throw response

}

export default {
  token: undefined,

  headers: {
    'Content-Type': 'application/json'
  },

  setJwtToken(token) {
    this.token = token
    this.establishHeaderRequest()
  },

  establishHeaderRequest() {
    this.headers = {
      'content-type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    }
  },

  get({
        path = '',
        params = {}
      }) {
    // console.log(path, params)
    return fetch(`${config.baseUrl}/${path}?${queryString.stringify(params)}`, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: this.headers,
      redirect: "follow",
      referrer: "no-referrer",
    })
        .then(handleErrors)
        .then(response => response.json())
        .catch(async err => {throw await err.json()})
  },

  put({
        path = '',
        payload
      }) {
    return fetch(`${config.baseUrl  }/${  path}`, {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: this.headers,
      redirect: "follow",
      referrer: "no-referrer",
      body: JSON.stringify(payload),
    })
        .then(handleErrors)
        .then(response => response.json())
        .catch(async err => {throw await err.json()})
  },

  delete({
           path = ''}) {
    return fetch(`${config.baseUrl  }/${  path}`, {
      method: "DELETE",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: this.headers,
      redirect: "follow",
      referrer: "no-referrer",
    })
        .then(handleErrors)
        .then(response => response.json())
        .catch(async err => {throw await err.json()})
  },

  post({
         path = '',
         payload
       }) {
    return fetch(`${config.baseUrl  }/${  path}`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: this.headers,
      redirect: "follow",
      referrer: "no-referrer",
      body: JSON.stringify(payload),
    })
        .then(handleErrors)
        .then(response => response.json())
        .catch(async err => {
          if(err.toString().includes('TypeError: Failed to fetch')) throw {message: 'Failed to fetch'}
          else throw await err.json()
        })
  }
}
